@use 'common/_shared.scss' as *;

.root {
  display: inline-block;
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
  padding-left: 32px;
  @include main-serif();
  font-size: rem(24px);
  line-height: 1;

  @include media('>=tablet') {
    font-size: rem(26px);
  }
}

.arrow {
  display: block;
  position: absolute;
  left: 8px;
  top: calc(50% - 4px);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 0 8px;
  border-color: $color-black-pearl transparent transparent transparent;
  pointer-events: none;
  transition: transform 0.2s;

  &.toggled {
    transform: rotate(180deg);
    border-color: $color-tallow transparent transparent transparent;
  }
}
