@use 'common/_shared.scss' as *;

$desc-text-width: 350px;

.card-body {
  @include media('>=tablet') {
    max-width: $desc-text-width;
    min-height: 400px;
  }

  @include media('>desktop') {
    min-height: 0;
  }
}

.card-sub-title,
.card-title {
  color: black;
}

.card-image {
  position: relative;
  max-width: 100%;
  margin: 30px 0;

  img {
    width: 100%;
    height: auto;
  }

  @include media('>=tablet') {
    height: 100%;
    position: absolute;
    right: -15px;
    z-index: -1;
    top: 0;
    margin: 0;
    padding-top: 80px;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  @include media('>desktop') {
    max-width: calc(100% - #{$desc-text-width});
    padding-top: 0;
  }
}

.image-desktop {
  @include media('<tablet') {
    display: none;
  }
}

.image-mobile {
  @include media('>=tablet') {
    display: none;
  }
}
