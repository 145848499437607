/* Judson */

/* vietnamese */
@font-face {
  font-family: 'Judson';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/judson/FeVTS0Fbvbc14VxhDYl4_bFJkWZe-s0.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Judson';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/judson/FeVTS0Fbvbc14VxhDYl5_bFJkWZe-s0.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Judson';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/judson/FeVTS0Fbvbc14VxhDYl3_bFJkWZe.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Judson';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/judson/FeVRS0Fbvbc14VxhB7l197Zrk1Zf.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Judson';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/judson/FeVRS0Fbvbc14VxhBrl197Zrk1Zf.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Judson';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/judson/FeVRS0Fbvbc14VxhCLl197Zrkw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Judson';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/judson/FeVSS0Fbvbc14Vxps5xg1ZRmuWxWwvQu.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Judson';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/judson/FeVSS0Fbvbc14Vxps5xg1JRmuWxWwvQu.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Judson';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/judson/FeVSS0Fbvbc14Vxps5xg2pRmuWxWwg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* ================ */

/* Noto Sans */

/* cyrillic-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/notosans/o-0OIpQlx3QUlC5A4PNr4ARPQ_mu72BiBLE.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/notosans/o-0OIpQlx3QUlC5A4PNr4ARGQ_mu72BiBLE.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/notosans/o-0OIpQlx3QUlC5A4PNr4ARDQ_mu72BiBLE.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC,
    U+A830-A839, U+A8E0-A8FF;
}
/* greek-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/notosans/o-0OIpQlx3QUlC5A4PNr4AROQ_mu72BiBLE.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/notosans/o-0OIpQlx3QUlC5A4PNr4ARBQ_mu72BiBLE.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/notosans/o-0OIpQlx3QUlC5A4PNr4ARNQ_mu72BiBLE.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/notosans/o-0OIpQlx3QUlC5A4PNr4ARMQ_mu72BiBLE.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/notosans/o-0OIpQlx3QUlC5A4PNr4ARCQ_mu72Bi.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/notosans/o-0TIpQlx3QUlC5A4PNr4AyxYOyOzW1IPriezag.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/notosans/o-0TIpQlx3QUlC5A4PNr4AyxYOyHzW1IPriezag.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/notosans/o-0TIpQlx3QUlC5A4PNr4AyxYOyCzW1IPriezag.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC,
    U+A830-A839, U+A8E0-A8FF;
}
/* greek-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/notosans/o-0TIpQlx3QUlC5A4PNr4AyxYOyPzW1IPriezag.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/notosans/o-0TIpQlx3QUlC5A4PNr4AyxYOyAzW1IPriezag.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/notosans/o-0TIpQlx3QUlC5A4PNr4AyxYOyMzW1IPriezag.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/notosans/o-0TIpQlx3QUlC5A4PNr4AyxYOyNzW1IPriezag.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/notosans/o-0TIpQlx3QUlC5A4PNr4AyxYOyDzW1IPrie.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/notosans/o-0TIpQlx3QUlC5A4PNr4Az5ZuyOzW1IPriezag.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/notosans/o-0TIpQlx3QUlC5A4PNr4Az5ZuyHzW1IPriezag.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/notosans/o-0TIpQlx3QUlC5A4PNr4Az5ZuyCzW1IPriezag.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC,
    U+A830-A839, U+A8E0-A8FF;
}
/* greek-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/notosans/o-0TIpQlx3QUlC5A4PNr4Az5ZuyPzW1IPriezag.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/notosans/o-0TIpQlx3QUlC5A4PNr4Az5ZuyAzW1IPriezag.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/notosans/o-0TIpQlx3QUlC5A4PNr4Az5ZuyMzW1IPriezag.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/notosans/o-0TIpQlx3QUlC5A4PNr4Az5ZuyNzW1IPriezag.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/notosans/o-0TIpQlx3QUlC5A4PNr4Az5ZuyDzW1IPrie.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjThZVadyBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjThZVYNyBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjThZVZdyBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC,
    U+A830-A839, U+A8E0-A8FF;
}
/* greek-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjThZVaNyBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjThZVZ9yBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjThZVa9yBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjThZVatyBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjThZVZNyBx2pqPA.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/notosans/o-0IIpQlx3QUlC5A4PNr6DRASf6M7VBj.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/notosans/o-0IIpQlx3QUlC5A4PNr4TRASf6M7VBj.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/notosans/o-0IIpQlx3QUlC5A4PNr5DRASf6M7VBj.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC,
    U+A830-A839, U+A8E0-A8FF;
}
/* greek-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/notosans/o-0IIpQlx3QUlC5A4PNr6TRASf6M7VBj.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/notosans/o-0IIpQlx3QUlC5A4PNr5jRASf6M7VBj.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/notosans/o-0IIpQlx3QUlC5A4PNr6jRASf6M7VBj.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/notosans/o-0IIpQlx3QUlC5A4PNr6zRASf6M7VBj.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/notosans/o-0IIpQlx3QUlC5A4PNr5TRASf6M7Q.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjFhdVadyBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjFhdVYNyBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjFhdVZdyBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC,
    U+A830-A839, U+A8E0-A8FF;
}
/* greek-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjFhdVaNyBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjFhdVZ9yBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjFhdVa9yBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjFhdVatyBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjFhdVZNyBx2pqPA.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjXhFVadyBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjXhFVYNyBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjXhFVZdyBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC,
    U+A830-A839, U+A8E0-A8FF;
}
/* greek-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjXhFVaNyBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjXhFVZ9yBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjXhFVa9yBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjXhFVatyBx2pqPIif.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/notosans/o-0NIpQlx3QUlC5A4PNjXhFVZNyBx2pqPA.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* ================ */
