@use 'common/_shared.scss' as *;

.root {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;

  @include media('>tablet') {
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
  }
}
