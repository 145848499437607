@use 'common/_shared.scss' as *;

.root {
  margin-top: 0;
  color: $color-black-pearl;
  font-family: inherit;
  font-weight: bold;
  font-size: rem(21px);
  line-height: rem(23px);
}

.title-link {
  text-align: left;
  text-decoration: none;
  color: $color-black-pearl;
}
