@use 'common/_shared.scss' as *;

.root {
  position: relative;
  @include main-sans-serif();
  margin-top: 32px;

  &.columns-2 {
    overflow: hidden;

    .radio-item {
      margin-top: 17px;

      &:nth-child(1) {
        margin-top: 0;
      }

      @include media('>=tablet') {
        max-width: calc(50% - 30px);
        width: 100%;
        float: left;

        &:nth-child(2) {
          margin-top: 0;
        }

        &:nth-child(2n + 1) {
          margin-right: 60px;
        }
      }
    }
  }
}

.form-radios {
  position: relative;
  margin-bottom: 15px;

  label {
    text-transform: uppercase;
    font-size: rem(10px);
    @include main-sans-serif($book);
  }
}

.radio-item {
  & + .radio-item {
    margin-top: 17px;
  }
}
