@use 'common/_shared.scss' as *;

.BlockTitle {
  margin: 0;
  text-align: left;
  text-transform: uppercase;
  @include main-sans-serif($medium);
  font-size: rem(13px);

  @include color-schema(footer-heading-color) using ($color) {
    color: $color;
  }

  @include theme-celeb {
    font-weight: 700;
  }
}
