@use 'common/_shared.scss' as *;

.root {
  position: relative;
  display: flex;
  height: 100%;
  width: $header-height;
  align-items: center;
  color: currentColor;
  text-decoration: none;
  letter-spacing: 0.1em;
  transition: background-color 0.15s;
  font-size: 10px;

  @include media('>=desktop') {
    width: auto;
    color: white;
  }
}

.user-icon-wrapper {
  position: relative;
}

.user-icon {
  fill: currentColor;
  width: 16px;
  height: 16px;

  @include media('>=desktop') {
    margin-right: 9px;
  }
}

.user-icon-bulb {
  position: absolute;
  left: 8px;
  top: 8px;
}

.user-menu-trigger {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 5px 30px 5px 20px;
  transition: background-color 0.15s;
  text-transform: uppercase;
  letter-spacing: rem(1px);
  color: $color-black-pearl;

  @include hover {
    background: rgba(#fff, 0.2);
  }

  &.is-active {
    background: white;
    color: black;

    &::after {
      border-bottom: 3px solid currentColor;
      border-top: none;
    }

    @include hover {
      background: white;
    }
  }

  @include media('>=desktop') {
    color: white;
    min-width: 170px;

    &::after {
      display: inline-block;
      vertical-align: middle;
      margin-left: 9px;
      @include triangle(bottom, 3px, $size-step: 1);
    }
  }
}

.user-menu-wrapper {
  position: fixed;
  transform-origin: right top;
  background: white;
  top: $header-height;
  right: 0;
  left: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  bottom: 0;
  border-top: 1px solid $color-pink-swan;
  font-weight: 700;
  font-size: rem(11px);
  text-align: right;
  text-transform: uppercase;
  color: black;

  @include media('>=desktop') {
    position: absolute;
    top: 0;
    bottom: auto;
    border: 1px solid $color-pink-swan;
    text-align-last: left;
    font-size: rem(10px);
    font-weight: 300;
  }
}

.user-menu-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.user-menu-items {
  height: 100%;
}

.user-menu-header {
  display: flex;
  align-items: center;
  min-height: 42px;
  min-width: 77px;
  padding: 5px 30px 5px 20px;
  border-bottom: 1px solid $color-pink-swan;
  cursor: pointer;
  text-align: left;

  &::after {
    display: inline-block;
    vertical-align: middle;
    margin-left: 9px;
    @include triangle(top, 3px, $size-step: 1);
  }
}

.user-menu-item {
  color: black;
  position: relative;
  z-index: 3;
  cursor: pointer;

  @include media('>=desktop') {
    &:first-child {
      a {
        padding-top: 15px;
      }
    }

    &:last-child {
      a {
        padding-bottom: 18px;

        &::before {
          bottom: 18px;
        }
      }
    }
  }
}

.with-item-bulb a {
  padding-right: 30px;
}

.menu-item-bulb {
  position: absolute;

  @include media('>=desktop') {
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
  }

  @include media('<desktop') {
    left: 11px;
    top: 20px;
  }
}

.user-logout-item {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 77px;
  margin-top: auto;
  border-top: 1px solid $color-pink-swan;
  cursor: pointer;

  button {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 42px;
    padding: 5px 30px 5px 20px;
    align-items: center;
    text-transform: uppercase;
    text-align: left;

    @include media('<desktop') {
      justify-content: flex-end;
      min-height: 60px;
      padding-right: 20px;
      font-size: rem(14px);
      color: white;
      background-color: $color-tallow;
    }
  }
}

.logout-icon {
  fill: currentColor;
  height: 1.6em;
  width: 1.6em;
  margin-right: 9px;

  @include media('<desktop') {
    height: 1.2em;
    width: 1.2em;
  }
}

.transition-enter {
  opacity: 0.01;
  transform: scale(0.5);
}

.transition-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 100ms ease-out;
}

.transition-done-enter {
  opacity: 1;

  .icon-close {
    display: block;
  }
}

.transition-exit {
  opacity: 1;
}

.transition-exit-active {
  opacity: 0.01;
  transform: scale(0.5);
  transition: all 100ms ease-out;
}
