@use 'common/_shared.scss' as *;

.root {
  padding-top: 8px;
  padding-bottom: 30px;

  .share-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .share-item {
    display: block;
    margin: 2px 13px;
    height: 20px;
    width: 20px;
    transition: color 0.2s ease;

    @include color-schema(footer-heading-color) using ($color) {
      color: $color;
      @include hover() {
        color: tint($color, 40%);
      }
    }
  }

  @include media('>=tablet') {
    grid-area: SocialShare;
    padding-top: 0;
  }

  @include media('>=desktop') {
    border-top: 1px solid $color-zambezi;
    @include theme-mice {
      border-top-color: $color-blue-whale;
    }
    padding-top: 20px;
    padding-bottom: 0;
  }
}
