@use 'sass:math';
@use '~include-media/dist/include-media' as *;

$em-base: 16px;
$main-line-height: 1.2;

$fallback-sans-serif: Arial, Helvetica, sans-serif;
$fallback-serif: 'Times New Roman', Times, serif;

$main-sans-serif: 'Noto Sans', $fallback-sans-serif;
$main-serif: 'Judson', $fallback-serif;
$featured-serif: 'Playfair Display', $fallback-serif;
$mice-secondary-sans-serif: 'Avenir', $fallback-sans-serif;

$light: 300;
$book: 400;
$medium: 500;
$bold: 700;
$black: 900;

@function strip-unit($value) {
  @return math.div($value, $value * 0 + 1);
}

@function em($pxval, $base: $em-base) {
  @if not math.is-unitless($pxval) {
    $pxval: strip-unit($pxval);
  }
  @if not math.is-unitless($base) {
    $base: strip-unit($base);
  }

  @return math.div($pxval, $base) * 1em;
}

@function rem($pxval) {
  @if not math.is-unitless($pxval) {
    $pxval: strip-unit($pxval);
  }

  $base: $em-base;
  @if not math.is-unitless($base) {
    $base: strip-unit($base);
  }

  @return math.div($pxval, $base) * 1rem;
}

@mixin main-sans-serif($weight: $book, $style: normal) {
  font-family: $main-sans-serif;
  font-style: $style;
  font-weight: $weight;
}

@mixin main-serif($weight: $book, $style: normal) {
  font-family: $main-serif;
  font-style: $style;
  font-weight: $weight;
}

@mixin featured-serif($weight: $bold, $style: normal) {
  font-family: $featured-serif;
  font-style: $style;
  font-weight: $weight;
}

// https://www.figma.com/design/ZeftQ0KaWXVHFn9Z2AVvyd/Design-system?node-id=915-3626&t=KNORBgMzDu4R8Gvr-1
// https://www.figma.com/design/R8nR2y6ET0JGZagmM34Hv8/CSS-typography-changes?node-id=0-1&node-type=canvas&t=d4vPI6ONwEJJHs96-0

@mixin font-display-medium() {
  font-size: rem(37px);
  line-height: 1.1;

  @include media('>=tablet') {
    font-size: rem(46px);
  }

  @include media('>=desktop') {
    font-size: rem(70px);
  }
}

@mixin font-display-big() {
  font-size: rem(54px);
  line-height: 1.1;

  @include media('>=desktop') {
    font-size: rem(96px);
  }
}

@mixin font-display-grand() {
  font-size: rem(54px);
  line-height: 1.1;

  @include media('>=desktop') {
    font-size: rem(148px);
  }
}

@mixin font-display-small() {
  font-size: rem(22px);
  line-height: 1.2;

  @include media('>=desktop') {
    font-size: rem(44px);
  }
}

@mixin font-overline-medium() {
  font-size: rem(22px);
  line-height: 1.3;

  @include media('>=desktop') {
    font-size: rem(25px);
  }
}

@mixin font-overline-big() {
  font-size: rem(22px);
  line-height: 1.3;

  @include media('>=desktop') {
    font-size: rem(25px);
  }
}

@mixin font-overline-small-sans-serif() {
  font-size: rem(12px);
  line-height: 1.3;

  @include main-sans-serif();
}

@mixin font-overline-small-featured-serif() {
  font-size: rem(14px);
  line-height: 1.3;

  @include featured-serif($black);
}
