@use 'common/_shared.scss' as *;

.root {
  margin-bottom: 30px;
  grid-area: Subscribe;

  @include media('>=tablet') {
    margin-bottom: 20px;
  }

  a {
    margin-bottom: 5px;
    padding: 5px 0;
    color: inherit;
    text-decoration: underline;
    transition: color 0.2s ease;

    @include color-schema(highlight-color) using ($color) {
      @include hover() {
        color: tint($color, 40%);
      }
    }

    @include media('>=tablet') {
      margin-right: 10px;
    }
  }
}

.description {
  @include media('>=desktop') {
    font-size: rem(12px);
  }
}
