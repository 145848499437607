@use 'common/_shared.scss' as *;

$brcolor: $color-narvik;

.root {
  position: relative;
  margin-bottom: 15px;
}

.book-params-list {
  position: relative;
  display: flex;
  justify-content: stretch;
  border-bottom: 1px solid $brcolor;

  :global {
    .DayPicker {
      margin: 0 auto;
    }
  }
}

.list-item {
  display: flex;
  flex-grow: 1;
  width: 67%;

  &:first-child {
    width: 33%;
  }
}

.list-item-inner {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  min-height: 120px;
  background-color: white;
  border-top: 1px solid $brcolor;
  border-bottom: 1px solid $brcolor;

  &::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: -9px;
    left: calc(50% - 9px);
    background: white;
    transform: rotate(45deg);
    border-top: 1px solid $brcolor;
    border-left: 1px solid $brcolor;
    transition: left 150ms ease-in;
  }
}

.first-active::before {
  left: calc(16.5% - 9px);
}

.second-active::before {
  left: calc(65% - 9px);
}

.day-time-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 80px;
  margin: 20px 60px;
}

.transition-enter {
  opacity: 0.01;
  transform: scale(0.5) translateY(-30%);
}

.transition-enter-active {
  opacity: 1;
  transform: scale(1) translateY(0%);
  transition: all 150ms ease-out;
}

.transition-done-enter {
  opacity: 1;

  .icon-close {
    display: block;
  }
}

.transition-exit {
  opacity: 1;
}

.transition-exit-active {
  opacity: 0.01;
  transform: scale(0.5) translateY(-30%);
  transition: all 150ms ease-out;
}
