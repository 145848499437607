@use 'common/_shared.scss' as *;

.root {
  margin-bottom: 3px;

  @include media('>=tablet') {
    grid-area: SideMenu;
    margin: 0 60px 0 30px;
  }

  @include media('>=desktop') {
    margin: 0 0 10px 0;
  }

  ul {
    padding-left: 0;
    list-style: none;
  }

  .side-menu-list {
    display: block;
    margin: 0;
    padding-left: 0;

    > .side-menu-list-item {
      display: block;
      flex-basis: 100%;
      margin-bottom: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include media('>=desktop') {
      > .side-menu-list-item:not(:first-child) {
        margin-top: 20px;
      }
    }
  }

  %list-head {
    display: block;
    text-align: left;
    margin-bottom: 20px;
    color: $color-tallow;
    text-transform: uppercase;
    @include main-sans-serif($medium);
    font-size: rem(14px);

    @include media('<desktop') {
      display: block;
      width: 100%;
      text-align: left;
      position: relative;
      padding-right: 20px;

      &::after {
        position: absolute;
        top: 0.45em;
        right: 10px;
        transition: transform 0.2s ease;
        @include triangle(right, 0.25em);
      }

      + ul {
        display: none;
        margin-bottom: 30px;
      }
    }

    @include media('>=tablet') {
      margin-bottom: 22px;
      min-height: rem(21px);
    }

    @include media('>=desktop') {
      margin-bottom: 7px;
      cursor: default;
    }
  }

  .side-menu-list-head {
    @extend %list-head;
  }

  .side-menu-list-head-active {
    @extend %list-head;

    &::after {
      transform: rotate(90deg);
    }

    @include media('<desktop') {
      + ul {
        display: block;
      }
    }
  }

  .side-menu-list-item {
    display: block;
    margin-bottom: 10px;

    a {
      margin-right: 15px;
      padding-right: 15px;
      color: $color-mist-grey;
      text-decoration: none;
      transition: color 0.2s ease;

      @include hover() {
        color: tint($color-tallow, 40%);
      }
    }

    @include media('>=tablet') {
      margin-bottom: 9px;
    }
  }
}
