@use 'common/_shared.scss' as *;

.sidebar-wrapper {
  margin-bottom: 30px;
}

.sidebar {
  margin: auto;
}

.book-offer {
  height: 220px;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.book-cta {
  width: 100%;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.book-message {
  color: $color-zambezi;
  font-size: rem(14px);
  margin: 0 -20px 20px;
}
