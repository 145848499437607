@use 'common/_shared.scss' as *;

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  min-height: 100vh;
  padding-top: $header-height-with-border;

  @include media('>=desktop') {
    padding-top: $header-height-desktop-with-border;
  }
}

.wrapper-root {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include media('>=tablet') {
    flex-direction: row;
  }
}

.content-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0 auto auto;

  @include media('>=tablet') {
    width: 420px;
    padding: 40px 0;
  }

  @include media('>=desktop') {
    width: 585px;
  }
}
