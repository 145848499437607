@use 'common/_shared.scss' as *;

.tag {
  border-radius: 4px;
  border: 1px solid $color-tallow;
  color: $color-tallow;
  text-transform: uppercase;
  padding: 6px 13px;
  font-weight: 500;
  display: inline-block;
  margin-right: 10px;
  font-size: 10px;
}
