@use 'common/_shared.scss' as *;

.root {
  position: relative;
}

.label {
  position: absolute;
  left: 0;
  top: 9px;
  pointer-events: none;
  text-transform: uppercase;
  font-size: rem(11px);
  @include main-sans-serif($bold);
  color: $color-grey;
  transition: 0.2s ease all;

  &.transformed {
    top: -4px;
    font-size: 10px;
  }
}

.textarea:focus ~ label {
  top: -4px;
  font-size: 10px;
}

.textarea {
  display: block;
  width: 100%;
  margin: 15px 0 20px;
  border-width: 0 0 1px 0;
  border-color: transparent;
  border-radius: 0;
  padding: 8px 0;
  font-size: rem(11px);
  @include main-sans-serif();
  line-height: 2.6;
  text-transform: uppercase;
  background-color: transparent;
  resize: none;
  min-height: 120px;
  background-image: repeating-linear-gradient(
    180deg,
    #fff,
    #fff 28px,
    $color-narvik 29px
  );
  overflow: auto;

  @include placeholder($color-grey);
}

.message-placeholder {
  position: absolute;
  padding: 8px 0;
  top: 0;
  left: 0;
  font-size: rem(11px);
  font-weight: 700;
  text-transform: uppercase;
  pointer-events: none;
}

.message-placeholder {
  color: $color-grey;
  transition: 0.15s ease all;

  &.required::after {
    content: '*';
    display: inline;
    color: $color-tallow;
  }

  &.transformed {
    top: -4px;
    font-size: rem(10px);
  }
}
