@use 'common/_shared.scss' as *;

.form-bottom-item {
  display: inline-block;

  &:first-child {
    padding-right: 5px;
  }

  &:last-child {
    padding-left: 5px;
  }
}

.form-button-link {
  display: inline-block;
  font-size: rem(14px);
  text-decoration: underline;
  padding: 5px;
}

.icon-back {
  width: 12px;
  height: 11px;
  margin-right: 5px;
  margin-top: 3px;
  cursor: pointer;
}

.form-button-link-top-spacer {
  margin-top: 25px;
}
