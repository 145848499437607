@use 'common/_shared.scss' as *;

%Stars {
  display: inline-block;
  vertical-align: top;
  width: rem(13px);
  height: rem(13px);
  @include main-sans-serif();
}

.root {
  display: inline-flex;
  vertical-align: top;
  padding-top: rem(8px);
  color: $color-tallow;
}

.star {
  @extend %Stars;

  @include theme-mice {
    color: $color-blue;
  }

  & + & {
    margin-left: 7px;
  }
}

.restaurant-stars {
  padding-top: 0;
  padding-left: 5px;

  .star {
    width: 12px;
    height: 12px;
    margin-left: 5px;

    &:first-child {
      margin-left: 2px;
    }
  }

  @include theme-mice {
    color: $color-blue;
  }
}
