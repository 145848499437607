@use 'common/_shared.scss' as *;

.root {
  @include main-sans-serif();
  font-size: rem(12px);
  padding: 20px 48px 15px 48px;
}

.list-item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 13px;

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  text-transform: uppercase;
  font-size: rem(10px);
  color: $color-dove-grey;
  width: 100%;
  text-align: center;
}

.price {
  font-size: rem(18px);
  padding: 0 0 0 10px;
  flex-grow: 1;
  text-align: right;
}

.old-price {
  font-size: rem(15px);
  padding-left: 10px;
  text-align: right;
  text-decoration: line-through;
}

.points {
  font-size: rem(14px);
  color: $color-tallow;
  font-weight: 500;
  margin-left: auto;
}
