@use 'common/_shared.scss' as *;

.book-header {
  text-align: center;
  margin-top: 27px;
  padding-bottom: 25px;
  width: 100%;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  border-bottom: 1px solid $color-narvik;
}
