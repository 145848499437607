@use 'common/_shared.scss' as *;

.root {
  position: relative;
  padding-top: 20px;

  @include media('>desktop') {
    padding-top: 50px;
  }
}

.panel {
  width: 100%;
}

.form {
  position: relative;
  width: 100%;
}

.form-wrapper {
  width: 495px;
  background: white;
  max-width: 100%;

  * + h3.title {
    margin-top: 40px;
  }
}

.sub-title {
  padding: 0;
  margin: 0;
  @include main-serif();
  font-size: rem(14px);
}

.switcher {
  position: relative;
  display: flex;
  justify-content: stretch;
  width: 100%;
  margin: 20px 0;
}

.checkbox-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  padding: 10px 20px 10px 87px;
  background-color: $color-white-smoke;
  font-size: rem(11px);
  color: $color-zambezi;

  a {
    text-transform: none;
    color: $color-tallow;
  }
}

.field-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: $color-whisper;
  width: 67px;
}

.form-switch {
  margin: 0;
}

.image-checkbox-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.image-checkbox {
  width: calc(33% - 10px);
  margin-right: 10px;
  margin-bottom: 20px;
}

.image-label {
  position: relative;
  display: block;
  line-height: 0;
  margin-bottom: 10px;
  cursor: pointer;

  &.checked::before {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    border: 5px solid $color-tallow;
  }
}

.checkbox-label {
  width: 100%;
  text-align: center;
  text-transform: none;
  @include main-serif($medium);
  font-size: rem(15px);
}

.image {
  width: 100%;
  height: auto;
  pointer-events: none; // ie11 fix
}

.formit-submit-container {
  margin-top: 40px;
}

.button {
  position: relative;
  width: 100%;
  padding: rem(18px);

  .loading {
    color: $color-mist-grey;
  }

  &:first-child {
    margin-right: 20px;
  }

  @include media('>=desktop') {
    &:first-child {
      width: 20%;
      min-width: 120px;
    }

    &:last-child {
      width: 40%;
      min-width: 160px;
    }
  }
}

.button-label {
  font-size: rem(12px);
  font-weight: $book;
}

.loader {
  position: absolute;
  top: 50%;
  left: 30px;
  margin-top: -0.5rem;
}

.language-select {
  width: 329px;
  max-width: 100%;
}

.contact-channel {
  margin-top: 20px;

  ol {
    list-style: none;
    counter-reset: contact-channel-items-counter;

    & li {
      counter-increment: contact-channel-items-counter;
      display: flex;
      align-items: center;

      & + li {
        margin-top: 20px;
      }

      &::before {
        content: counter(contact-channel-items-counter) '. ';
        display: inline-block;
        vertical-align: middle;
        color: black;
        position: relative;
        @include main-serif();
        font-size: rem(26px);
        padding-right: 16px;
      }
    }
  }
}

.checkbox-wrapper {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  max-width: 293px;
  width: 100%;
}

.contact-channel-checkbox {
  padding: 15px 15px 15px 17px;
  border: 1px solid $color-whisper;
  @include main-sans-serif($medium);
  font-size: rem(12px);
  margin: 0;
  display: flex;
  max-width: 293px;
  width: 100%;
}

.contact-channel-checkbox-label {
  display: inline-flex;
  width: auto;
}

.contact-channel-checkbox-inactive {
  opacity: 0.5;
  pointer-events: none;
}

.checkbox-message {
  font-size: rem(10px);
  display: block;
  padding-left: rem(36px);
  padding-top: 5px;
  color: $color-grey;

  span {
    color: $color-tallow;
  }
}

.drag-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  right: 0;
  cursor: move;
  padding: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drag-icon {
  width: 9px;
  height: 17px;
}
