@use 'common/_shared.scss' as *;

.root {
  margin: 25px 0 30px;
  color: $color-zambezi;
}

.content-wrapper {
  @include media('>=desktop') {
    margin-right: 340px;
    max-width: 672px;
  }
}

.intro-wrapper {
  margin-bottom: 30px;
}

.services-title {
  margin-bottom: 40px;
  @include main-sans-serif();
  font-size: rem(20px);

  strong {
    font-weight: $book;
  }
}

.service-list-wrapper {
  margin-bottom: 20px;

  @include media('>=tablet') {
    margin-bottom: 40px;
  }
}

.links-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  align-self: center;
  flex-wrap: wrap;
  padding: 0;
  margin: -10px -20px 20px;

  // desktop first ('<tablet' not '>=tablet') because of bug with justify-content in the IE11 (Bug #283130)
  @include media('<tablet') {
    justify-content: center;
  }
}

.link-item {
  padding: 10px 20px 10px;

  a,
  button {
    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    color: $color-tallow;
    font-weight: $medium;
    font-size: rem(12px);
    -webkit-tap-highlight-color: transparent;
    transition: color 0.2s ease;

    @include hover() {
      color: shade($color-tallow, 15%);
    }
  }
}

.conditions {
  position: relative;
  padding: 20px 0;
  background-color: $color-snow;
}

.conditions-transition-enter {
  opacity: 0.01;
  height: auto;
  transform: scale(0.9) translateY(50%);
}

.conditions-transition-enter-active {
  opacity: 1;
  height: auto;
  transform: scale(1) translateY(0%);
  transition: all 300ms ease-out;
}

.conditions-transition-done-enter {
  opacity: 1;
  height: auto;
}

.conditions-transition-exit {
  opacity: 1;
  height: auto;
  transform: scale(1) translateY(0%);
}

.conditions-transition-exit-active {
  opacity: 0.01;
  height: auto;
  transform: scale(0.9) translateY(50%);
  transition: all 300ms ease-out;
}

.conditions-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.conditions-title {
  font-size: 15px;
}

.conditions-close {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: rem(10px);
  font-weight: 500;
  color: $color-tallow;

  .icon {
    height: 10px;
    width: 10px;
    margin-right: 8px;
  }
}

.conditions-content {
  font-size: rem(12px);
  line-height: 1.75;

  p {
    margin-bottom: 20px;
  }
}
