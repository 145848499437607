@use 'common/_shared.scss' as *;

$border-color: $color-white-smoke;

.item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 95px;
  width: 100%;
  border-right: 1px solid $border-color;
  padding: 8px 10px 5px;
  text-align: center;
  white-space: normal;
}

.top,
.bottom {
  display: flex;
  align-items: center;
  height: 1.1rem;
  font-size: rem(9px);
  white-space: nowrap;
}

.middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.item-label {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: rem(9px);
  color: $color-dove-grey;
}

.item-value-num {
  text-transform: uppercase;
  @include main-sans-serif($light);
  font-size: rem(30px);
  line-height: 1;
  color: $color-black-pearl;
  transition: color 120ms ease-in;

  &.active {
    color: $color-tallow;
  }
}
