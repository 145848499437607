@use 'common/_shared.scss' as *;

.loader {
  display: inline-block;
  margin: 50px auto;
  border-radius: 50%;
  background: #999;
  background: linear-gradient(to right, #999 10%, rgba(255, 255, 255, 0) 42%);
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);
}

.loader::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
  content: '';
  border-radius: 100% 0 0 0;
  background: #999;
}

.loader::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 75%;
  height: 75%;
  margin: auto;
  border-radius: 50%;
  content: '';
  background: #fff;
}

@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
