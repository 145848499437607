@use 'common/_shared.scss' as *;

$brcolor: $color-narvik;
$block-width: 325px;

.root {
  position: relative;
  width: $block-width;
  border: 1px solid $brcolor;
  background-color: white;

  @include media('<tablet') {
    max-width: $block-width;
    width: 100%;
  }
}

.close {
  flex-shrink: 0;
  height: 60px;
  width: 60px;
  border-bottom: 1px solid $brcolor;
  border-left: 1px solid $brcolor;
}

.close-btn {
  height: 100%;
  padding: 20px;
}
