@use 'common/_shared.scss' as *;

.root {
  margin: 60px 30px 20px;
}

.content {
  margin-bottom: 10px;
}

.image {
  margin-bottom: 18px;

  .circle {
    width: 56px;
    height: 56px;
    margin: 0 auto;
    border: 1px solid $color-tallow;
    border-radius: 100%;

    @include media('>=tablet') {
      width: 86px;
      height: 86px;
      border: 2px solid $color-tallow;
    }
  }

  .email-icon {
    display: block;
    width: 22px;
    margin: 0 auto;
    color: $color-tallow;

    @include media('>=tablet') {
      width: 34px;
    }
  }
}

.confirmation-message {
  text-align: center;
  margin-bottom: 15px;
  @include main-sans-serif();
  color: $color-black-pearl;
  font-size: 24px;
  line-height: 1.25;
}

.description {
  font-size: rem(14px);
  line-height: 1.5;
  text-align: center;
}
