@use 'common/_shared.scss' as *;

.inline-fields {
  @include media('>=desktop') {
    display: flex;

    &:last-child {
      margin-bottom: -15px;
    }
  }
}
