@use 'common/_shared.scss' as *;

$top-padding: 50px;

.branding {
  position: relative;

  @include color-schema(highlight-color) using ($color) {
    color: $color;
  }

  @include media('>=tablet') {
    grid-area: f-branding;
  }

  @include media('>=desktop') {
  }
}

.branding-logo {
  display: block;
  position: absolute;
  width: 60px;
  height: 60px;
  left: 50%;
  top: -$top-padding;
  transform: translate(-50%, -50%);

  @include media('>=desktop') {
    width: 94px;
    height: 94px;
  }
}

.rhomb {
  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    left: calc(50% - 35px);
    top: -84px;
    border-style: solid;
    border-width: 0 35px 35px 35px;
  }

  @include media('>=desktop') {
    &::before {
      left: calc(50% - 75px);
      top: -106px;
      border-width: 0 75px 75px 75px;
    }
  }

  @include color-schema(footer-bgc) using ($bgc) {
    &::before {
      border-color: transparent transparent $bgc transparent;
    }
  }
}

.round {
  &::before {
    content: '';
    position: absolute;
    display: block;
    left: 50%;
    top: -$top-padding;
    transform: translate(-50%, -50%);
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: $color-black-pearl;
  }

  @include media('>=desktop') {
    &::before {
      width: 120px;
      height: 120px;
    }
  }

  @include color-schema(footer-bgc) using ($bgc) {
    &::before {
      background-color: $bgc;
    }
  }

  .branding-logo {
    width: 45px;
    height: 45px;

    @include media('>=desktop') {
      width: 70px;
      height: 70px;
    }
  }
}
