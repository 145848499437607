@use 'common/_shared.scss' as *;

$header-mobile-height: 55px;

.root {
  position: relative;
}

%hero-slider-layout {
  width: 100%;
  height: calc(100vw / 0.8);

  @include media('>530px') {
    height: 480px;
  }

  @include media('>=tablet') {
    height: 330px;
  }

  @include media('<desktop') {
    max-height: calc(100vh - #{$header-mobile-height});
  }

  @include media('>=desktop') {
    height: 750px;
    max-height: 100vh;
  }
}

.hero-slider-bottom {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  @extend %hero-slider-layout;
}

.hero-slider-bottom-use-desktop {
  @include media('<desktop') {
    position: relative;
    top: auto;
    left: auto;
    height: auto;
  }
}

.hero-slide-image {
  position: relative;
  overflow: hidden;
  @extend %hero-slider-layout;

  img {
    vertical-align: top;
    object-fit: cover;
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    width: auto;

    @include media('>530px') {
      @include object-fit-polyfill();
    }
  }

  &::after {
    content: '';
    background-color: rgba(black, 0.2);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
}

.hero-slide-video {
  position: relative;
  min-width: 100%;
  overflow: hidden;
  @extend %hero-slider-layout;
}

.video-image {
  display: none;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;

  &.is-visible {
    display: block;
  }
}

.hero-slider-inner {
  position: absolute;
  top: 0;
  left: 0;
  @extend %hero-slider-layout;
  pointer-events: none; // Fix for Slider swipe
}

.hero-slider {
  position: relative;
  padding-top: 0;
  @extend %hero-slider-layout;

  :global(.slick-list) {
    height: 100%;
    width: 100%;
  }

  :global(.slick-track) {
    // @todo: remove it after successful slider update. Last stable: v0.16.0. Checked up to v0.24.0.
    // Prevent moving of slides by drag event https://github.com/akiran/react-slick/issues/643
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    height: 100%;
  }

  :global(.slick-active) {
    z-index: 1;
  }
}

.hero-slider-dots {
  position: absolute;
  margin: 0;
  padding: 0;
  bottom: 0;
  width: 100%;
  list-style: none;
  text-align: center;

  li {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 5px 17px;
    padding: 0;
    cursor: pointer;
    z-index: 1;

    &:only-child {
      display: none;
    }
  }

  button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 16px;
    height: 16px;
    padding: 3px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      box-sizing: border-box;
      border: 2px solid #fff;
      border-radius: 5px;
    }
  }

  :global(.slick-active button::before) {
    background-color: #fff;
  }

  @include media('>=tablet') {
    li {
      margin: 0 2px 12px;
    }
  }

  @include media('>=desktop') {
    li {
      width: 20px;
      height: 20px;
      margin: 0 2px 20px;
    }

    button {
      width: 20px;
      height: 20px;

      &::before {
        top: 3px;
        left: 3px;
        width: 12px;
        height: 12px;
        border-radius: 6px;
      }
    }
  }
}
