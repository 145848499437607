@use 'common/_shared.scss' as *;

$offset: 50px;

.title {
  margin-bottom: 30px;
  @include main-serif();
  font-size: rem(36px);
  line-height: rem(38px);
  text-align: center;

  @include media('<desktop') {
    .hightlighted-text {
      display: block;
    }
  }

  @include media('>=desktop') {
    text-align: left;
  }
}

.hightlighted-text {
  @include break-word-custom();
  @include hyphens();
}

.advantages-block {
  margin-top: 60px;
}

.intro-section {
  margin: 0 0 20px;
  padding: 0;

  @include media('>=tablet') {
    margin: 0 0 30px;
  }
}
