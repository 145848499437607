@use 'common/_shared.scss' as *;
@use 'common/_wysiwyg.scss' as *;

.root {
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  z-index: 100;

  &::before,
  &::after {
    content: '';
    display: block;
    position: relative;
    width: 100%;
    height: 1px;
  }
}

.modal {
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  background-color: white;
  border-radius: 3px;
  padding: 26px 32px;
  text-align: center;

  @include media('>=tablet') {
    padding: 50px;
  }

  @include media('>=desktop') {
    max-width: 678px;
  }
}

.modal-text {
  margin-bottom: 26px;

  @include wysiwyg-myacc();

  ul,
  ol {
    text-align: left;
  }

  @include media('>=tablet') {
    margin-bottom: 40px;
  }
}

.confirm-button {
  margin-right: 40px;
  padding: 10px;
  font-size: rem(16px);

  &:last-child {
    margin-right: 0;
  }

  &.negative {
    font-weight: $bold;
  }
}
