@use 'common/_shared.scss' as *;

%Cell {
  padding: 10px;
  vertical-align: middle;
  border: 1px solid $color-merino;
}

.table-header {
  @extend %Cell;
  position: relative;
  text-align: center;
  font-weight: $bold;
}

.hide-button {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 20px;
  width: 20px;
  top: 0;
  right: 0;
  padding: 4px;
}

.icon-eye {
  opacity: 0.3;
  height: 100%;

  &.checked {
    opacity: 0.75;
    height: 75%;
  }
}
