@use 'common/_shared.scss' as *;

.root {
  padding: 13px 30px;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  overflow: hidden;
  transition: background-color 0.15s;
  color: $color-white;
  background-color: $color-tallow;
  text-align: center;
  @include main-sans-serif($bold);

  &[disabled] {
    pointer-events: none;
    opacity: 0.8;
  }
}

.link {
  background-color: $color-tallow;

  @include hover() {
    background-color: tint($color-tallow, 40%);
  }
}

.label {
  display: block;
  line-height: 1;
}

.phone {
  &:not(.no-label) {
    font-weight: 400;
    font-size: rem(13px);
  }
}
