@use 'common/_shared.scss' as *;

$container-width: 1440px;
$container-width-large: 1580px;
$container-width-mid: 1280px;
$container-width-short: 1140px;
$container-desktop-padding: 40px;
$container-mobile-padding: 20px;

%root {
  margin: 0 auto;
  padding: 0 $container-mobile-padding;

  &.anchored {
    position: relative;
  }

  .container-anchor {
    position: absolute;
    top: -65px;

    @include media('>=desktop') {
      top: -100px;
    }
  }
}

.container-1580 {
  @extend %root;
  max-width: $container-width-large;

  @include media('>=tablet') {
    padding: 0 $container-desktop-padding;
  }
}

.container-1440 {
  @extend %root;
  max-width: $container-width;

  @include media('>=tablet') {
    padding: 0 $container-desktop-padding;
  }
}

.container-1280 {
  @extend %root;
  max-width: $container-width-mid + ($container-desktop-padding * 2);

  @include media('>=tablet') {
    padding: 0 $container-desktop-padding;
  }
}

.container-1140 {
  @extend %root;
  max-width: $container-width-short + ($container-desktop-padding * 2);

  @include media('>=tablet') {
    padding: 0 $container-desktop-padding;
  }
}

.container-1024 {
  @extend %root;
  max-width: 1024px;

  @include media('>=desktop') {
    padding: 0 $container-desktop-padding;
  }
}

.container-900 {
  @extend %root;
  max-width: 900px;

  @include media('>=desktop') {
    padding: 0 $container-desktop-padding;
  }
}

.container-760 {
  @extend %root;
  max-width: 760px;

  @include media('>=desktop') {
    padding: 0 $container-desktop-padding;
  }
}
