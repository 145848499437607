@use 'common/_shared.scss' as *;

.root {
  position: relative;
  break-inside: avoid;
}

.root:last-child {
  border-bottom: 0 solid transparent;
}

.checkbox {
  display: none;
}

.label {
  display: inline-flex;
  vertical-align: top;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
  font-size: rem(11px);
  @include main-sans-serif();

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.icon {
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  flex-grow: 0;
}

.box-wrapper {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  border: 1px solid $color-tallow;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.checked {
    background-color: $color-tallow;
    color: white;
  }
}

.text {
  font-size: 10px;
  text-transform: uppercase;
  white-space: nowrap;
}
