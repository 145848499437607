@use 'common/_shared.scss' as *;

$top-progressBar-padding: 20px;
$bottom-progressBar-padding: 30px;

.root {
  position: relative;
  background-color: $color-snow-light;
  padding: 20px (66px + 25px) 30px; // width plus part for Point description block width
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 110px + $top-progressBar-padding + $bottom-progressBar-padding;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    box-shadow: inset 0 19px 12px -15px $box-shadow-color;
  }

  @include media('<tablet') {
    padding: 0 20px 20px;
    min-height: 1px;
    text-align: center;
    background-color: white;
    color: $color-nobel;

    &::before {
      display: none;
    }
  }

  @include media('<desktop') {
    padding: 20px 10% 30px;
  }
}

.progress-bar-container {
  margin-top: 10px;
  width: 100%;
  max-width: 100%;

  @include media('>=tablet') {
    margin-top: 54px;
  }
}

.progress-bar-line-container {
  position: relative;
  width: 668px;
  max-width: 100%;
}

.progress-bar-line {
  position: relative;
  background-color: $color-white-smoke-darker;
  width: 100%;
  height: 8px;
  margin-bottom: -4px;
}

.progress-bar-line-filled {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: $color-winter-hazel;
  border-radius: 3px;
}

.progress-bar-points-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.progress-bar-point-container {
  position: absolute;
  width: 100px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.progress-bar-point {
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: $color-white-smoke-darker;

  &.active {
    background-color: $color-winter-hazel;
  }
}

.progress-bar-point-text {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 8px 0;
}

.progress-bar-point-top {
  bottom: 22px;
  padding-bottom: 8px;
}

.progress-bar-point-bottom {
  top: 22px;
  padding-top: 8px;
}

.point-1 {
  left: 0;
}

.point-2 {
  left: 25%;
}

.colored-status {
  @include main-sans-serif($medium);

  &::first-letter {
    text-transform: uppercase;
  }
}

.points-value {
  @include main-sans-serif($bold);
  color: black;
}
