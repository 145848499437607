@use 'common/_shared.scss' as *;

.root {
  position: relative;
}

.multi-fields {
  @include media('>=desktop') {
    display: flex;

    &:last-child {
      margin-bottom: -15px;
    }
  }
}
