@use 'common/_shared.scss' as *;

$header-breakpoint: 1270px;
$logo-width: 180px;
$nav-items-padding-big: 30px;
$nav-items-padding-small: 18px;
$top-bar-desktop-height: 42px;
$desktop-menu-height: 54px;

$color-header-border-on-dark: rgba(#fff, 0.3);
$color-header-border-on-light: $color-pink-swan; // #b2b2b2 = rgba(#000, 0.3)
$color-header-border-on-light-lighter: rgba(black, 0.1);
$color-heder-hover-on-dark: rgba(#fff, 0.2);

.root {
  display: flex;
  height: 100%;

  @include media('>=desktop') {
    position: relative;
  }
}

.lang-menu-trigger {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  transition: background-color 0.15s;
  text-transform: uppercase;
  color: currentColor;
  font-size: rem(10px);
  font-weight: $book;
  letter-spacing: rem(1px);

  @include hover {
    background: $color-heder-hover-on-dark;
  }

  &.is-active {
    background: white;
    color: black;

    @include hover {
      background: white;
    }

    &::after {
      border-bottom: 3px solid currentColor;
      border-top: none;
    }
  }

  &::after {
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px;
    margin-top: -1px;
    @include triangle(bottom, 4px, $size-step: 1);

    @include media('>=desktop') {
      margin-left: 9px;
      @include triangle(bottom, 3px, $size-step: 1);
    }
  }

  @include media('>=desktop') {
    padding: 0 20px;
  }
}

.lang-menu-close {
  width: 40px;
  height: 40px;
  background-color: #e3e3e3;
  border-radius: 100%;
  position: relative;
  margin: 30px auto;
  color: $color-black-pearl;

  .line {
    position: absolute;
    display: block;
    width: 14px;
    height: 1px;
    background: transparent;
    margin-top: -1px;
    top: 50%;
    left: 50%;
    margin-left: -7px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: currentColor;
      left: 0;
      transition: transform 0.15s;
    }

    &::before {
      top: 0;
      transform: rotate(45deg);
    }

    &::after {
      bottom: 0;
      transform: rotate(-45deg);
    }
  }

  @include media('>=desktop') {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0;

    .line {
      @include triangle(top, 3px, $size-step: 1);

      &::before,
      &::after {
        display: none;
      }
    }
  }
}

.lang-menu-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: $color-white-smoke;
  overflow: auto;
  border-bottom: 1px solid $color-header-border-on-light;

  @include media('<desktop') {
    position: fixed;
    top: $header-height-with-border;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @include media('>=desktop') {
    position: absolute;
    right: 0;
    top: 0;
    border: 1px solid $color-header-border-on-light;
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

.lang-menu-item {
  .lang-menu-item-button {
    display: block;
    position: relative;
    width: 100%;
    padding: 20px;
    text-transform: uppercase;
    font-size: rem(11px);
    color: black;
    @include main-sans-serif();
    font-weight: 700;
    text-decoration: none;
    letter-spacing: rem(1.6px);

    &::before {
      display: none;
      content: '';
      position: absolute;
      bottom: 14px;
      height: 1px;
      width: 0;
      background: currentColor;
      transition: width 0.15s;
      will-change: width;
    }

    @include media('>=desktop') {
      padding: 11px 60px 11px 27px;
      text-align: left;
      font-size: rem(10px);
      @include main-sans-serif($book);

      &::before {
        display: block;
      }

      &:first-child {
        padding-top: 15px;
      }

      &:last-child {
        padding-bottom: 15px;
      }
    }

    @include hover {
      &:before {
        width: 14px;
      }
    }
  }

  &.is-active {
    @include media('>=desktop') {
      .lang-menu-item-button::before {
        display: block;
        background: currentColor;
        left: 27px;
        width: 14px;
        bottom: 14px;
        transition: width 0.15s;
        will-change: width;
      }
    }
  }
}

.transition-enter {
  opacity: 0.01;
  transform: scale(0.75) translateY(-13vh);
}

.transition-enter-active {
  opacity: 1;
  transform: scale(1) translateY(0);
  transition: all 100ms ease-out;
}

.transition-done-enter {
  opacity: 1;
}

.transition-exit {
  opacity: 1;
}

.transition-exit-active {
  opacity: 0.01;
  transform: scale(0.75) translateY(-13vh);
  transition: all 100ms ease-out;
}
