@use 'common/_shared.scss' as *;

$offset-small: 20px;
$border-color: $color-narvik;

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.full-width {
  width: calc(100% + 122px);
}

.form {
  max-width: 100%;
}

.submit-button {
  position: relative;
  width: 100%;
  padding-left: 35px;
  padding-right: 35px;

  .loading {
    color: $color-mist-grey;
  }

  &:hover .loader {
    background: linear-gradient(to right, white 10%, rgba($color-yuma, 0) 42%);

    &::after {
      background-color: $color-yuma;
    }
  }
}

.loader {
  position: absolute;
  top: calc(50% - 2px);
  left: 15px;
  margin-top: -0.5rem;
  background: $color-pink-swan;
  background: linear-gradient(
    to right,
    white 10%,
    rgba($color-pink-swan, 0) 42%
  );

  &::before {
    background: white;
  }

  &::after {
    background: $color-pink-swan;
    transition: background-color 0.2s;
  }
}

.captcha {
  margin: 20px auto 0;
}
