@use 'sass:math';

@use 'common/_shared.scss' as *;

.root {
  position: absolute;
  z-index: 1;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  color: white;
}

.hero-info-container {
  position: relative;
  padding: 60px 20px 20px;

  .title,
  .subtitle {
    margin: 0;
    padding-right: 5%;
  }

  .title {
    @include font-display-medium();

    @include media('>=tablet') {
      margin-bottom: 5px;
    }
  }

  .subtitle {
    margin-bottom: 5px;
    @include main-serif();
    @include font-overline-medium();
  }

  @include theme-sbm {
    &::after {
      content: '';
      position: absolute;
      bottom: -3px;
      left: 20px;
      width: 25px;
      height: 3px;
      background-color: #fff;
    }
  }

  &.mice-page-head {
    @include media('<tablet') {
      padding: 0 20px 60px;
    }
  }

  @include media('>=tablet') {
    padding: 50px 40px 30px;

    &::after {
      left: 40px;
    }

    &.hero-webcam {
      &::after {
        bottom: 28px;
      }
    }

    .title,
    .subtitle {
      padding-right: 5%;
    }
  }

  @include media('>=desktop') {
    margin: 0 auto;
    padding: 20px 340px 100px 40px;

    @include theme-celeb {
      padding-right: 40px;
    }

    .subtitle {
      @include theme-sbm {
        padding-right: 52%;
      }
    }
  }
}
