@use 'common/_shared.scss' as *;

.root {
  position: relative;
}

.message-wrapper {
  margin: 0 0 20px;
}

.message-error {
  color: $color-fire-brick;
}

.message-ok {
  color: $color-limeade;
}

.buttons-wrapper {
  text-align: center;
}

.captcha {
  margin: 20px auto 0;
}
