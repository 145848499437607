@use 'common/_shared.scss' as *;

$brcolor: $color-narvik;

.root {
  position: relative;
}

.book-property {
  border-bottom: 1px solid $brcolor;
}

.book-header {
  display: flex;

  .close {
    flex-shrink: 0;
    height: 60px;
    width: 60px;
    border-bottom: 1px solid $brcolor;
    border-left: 1px solid $brcolor;
  }

  .close-btn {
    height: 100%;
    padding: 20px;
  }
}

.tags-wrap {
  text-align: center;
  padding: 15px 10px;
  border-bottom: 1px solid $brcolor;

  &:empty {
    display: none;
  }
}

.book-header-label {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  border-bottom: 1px solid $brcolor;
  color: $color-zambezi;
  font-size: rem(11px);
  text-transform: uppercase;
}

.book-cta-wrapper {
  width: 100%;
}

.book-offer {
  padding: 15px 40px 30px;
  text-align: center;
}

.best-price {
  margin-bottom: 20px;
  text-align: center;
}

.book-cta {
  width: 100%;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  font-weight: 500;
}

.book-message {
  color: $color-zambezi;
  font-size: rem(14px);
  margin: 0 -20px 20px;
}

.book-params-list {
  position: relative;
  display: flex;
  justify-content: stretch;
  width: 100%;
  border-bottom: 1px solid $brcolor;

  .list-item {
    display: flex;
    flex-grow: 1;
    width: 33%;

    &:first-child {
      width: 67%;
    }
  }

  .list-item-inner {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    min-height: 242px;
    background-color: white;
    border-top: 1px solid $brcolor;
    border-bottom: 1px solid $brcolor;

    &::before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: -9px;
      left: calc(50% - 9px);
      background: white;
      transform: rotate(45deg);
      border-top: 1px solid $brcolor;
      border-left: 1px solid $brcolor;
      transition: left 150ms ease-in;
    }
  }

  .first-active::before {
    left: calc(16.5% - 9px);
  }

  .second-active::before {
    left: calc(50% - 9px);
  }

  .third-active::before {
    left: calc(83.5% - 9px);
  }

  :global {
    .DayPicker {
      margin: 0 auto;
    }
  }
}
