@use 'common/_shared.scss' as *;

.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  align-items: flex-end;

  &.long-style.odd {
    flex-direction: row-reverse;
  }

  &:not(.long-style) {
    justify-content: flex-end;
  }

  @include media('>=desktop') {
    &.long-style:not(.odd) {
      padding-left: 10px;
    }

    &.long-style.odd {
      padding-right: 10px;
    }
  }
}

.column-2-bottom-col {
  display: flex;
  align-items: center;
  max-width: 50%;
  vertical-align: middle;
  text-align: center;
  gap: 10px;
  flex-grow: 2;

  &.stretch {
    max-width: 100%;
    flex-wrap: wrap;
  }

  @include media('<desktop') {
    width: 100%;
  }

  &.pull-right {
    margin-left: auto;
  }
}

.column-2-bottom-col-text {
  padding: 15px;
  font-size: rem(11.26px);
  justify-content: center;

  span {
    font-size: rem(18.26px);
    font-weight: $bold;
  }
}
