@use 'sass:color';
@use 'common/_shared.scss' as *;
//Styles on lines 6-7, 13-19, 129-131 are applied because of "react-dates": "^18.3.1" classNames assignment issues.
.root {
  :global {
    .DayPickerNavigation_button {
      position: absolute;
      top: 18px;
      color: $color-black-pearl;
      width: 25px;
      height: 25px;
      padding: 8px;

      &:first-child {
        left: 22px;
      }

      &:last-child {
        right: 22px;
      }
    }

    .CalendarMonth_caption {
      font-size: rem(14px);
      color: $color-tallow;

      strong {
        font-weight: normal;
      }
    }

    .DayPicker_weekHeader {
      top: 56px;
      color: $color-tallow;
    }

    .DayPicker_weekHeader_ul {
      font-size: rem(11px);
    }

    .CalendarDay {
      vertical-align: middle;
      position: relative;
      font-size: rem(11px);

      span {
        display: flex; // flex is needed for the ie11. Bug #290354.
        align-items: center;
        justify-content: center;
        height: 100%;
        position: relative;
        z-index: 1;
      }

      &::before {
        content: '';
        left: 15%;
        top: 15%;
        position: absolute;
        width: 70%;
        height: 70%;
        border-radius: 50%;
        transition: background-color 0.15s ease;
      }
    }

    .CalendarDay__blocked_out_of_range,
    .CalendarDay__blocked_out_of_range:active,
    .CalendarDay__blocked_out_of_range:hover {
      border: none;
    }

    .CalendarDay__default {
      border: none;
    }

    .CalendarDay__default:hover {
      border: none;
      background-color: transparent;
      color: white;

      &::before {
        background-color: color.adjust($color-tallow, $lightness: 10%);
      }
    }

    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      border: none;
      background-color: transparent;

      &::before {
        background-color: $color-tallow;
      }
    }

    .CalendarDay__hovered_span,
    .CalendarDay__hovered_span:hover {
      background-color: transparent;
      color: white;
      border: none;

      &::before {
        background-color: color.adjust($color-tallow, $lightness: 15%);
      }
    }

    .CalendarDay__selected_span {
      background-color: transparent;

      &::before {
        background-color: color.adjust($color-tallow, $lightness: 10%);
      }
    }

    .CalendarDay__selected_span:active,
    .CalendarDay__selected_span:hover {
      background-color: transparent;

      &::before {
        background-color: color.adjust($color-tallow, $lightness: 5%);
      }
    }
  }
}

.prev-disabled {
  :global {
    .DayPickerNavigation_button:first-child {
      display: none;
    }
  }
}

.next-disabled {
  :global {
    .DayPickerNavigation_button:last-child {
      display: none;
    }
  }
}

.any-day-button {
  text-transform: uppercase;
  font-size: rem(11px);
  float: right;
  margin-top: -10px;
  margin-right: 20px;
  padding: 10px;
}
