@use 'common/_shared.scss' as *;

.container-fix {
  display: inline-block;
}

.container {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  overflow: hidden;
}

.image-sizer {
  display: block;
}

.img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  vertical-align: top;
}
