@use 'common/_shared.scss' as *;

.root {
  border-radius: $card-border-radius;
  padding: $mobile-card-padding-v $mobile-card-padding-h;
  padding-bottom: $mobile-card-padding-v;
  min-height: 345px;
  position: relative;
  color: white;
  text-align: center;
  min-width: 0;
  text-decoration: none;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include media('>=tablet') {
    text-align: left;
    padding: $card-padding-v $card-padding-h;
    padding-bottom: $card-padding-v;
  }

  &.stretch {
    grid-column: 1/-1;
  }

  h2 {
    font-size: rem(23px);
    font-family: inherit;
    font-weight: 600;

    @include media('>=tablet') {
      font-size: rem(28px);
    }
  }

  h3 {
    font-weight: 500;
    font-size: rem(12px);
    font-family: inherit;
    margin-bottom: 10px;

    @include media('>=tablet') {
      font-size: rem(16px);
    }
  }
}

.text-wrapper {
  position: relative;
  z-index: 2;
  height: 100%;
  padding-bottom: $button-height;
  @include main-sans-serif();
}

.link {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: $card-padding-v;
  line-height: $button-height;
  padding: 0 25px;
  min-width: max-content;
  max-width: 100%;
  z-index: 3;
  text-transform: uppercase;

  @include media('>=tablet') {
    left: $card-padding-h;
    transform: translateX(0);
    padding: 0 25px;
  }

  &.reverse {
    background: #aba177;
    color: white;

    &:hover {
      background: white;
      color: black;
    }
  }
}

.bg-wrapper {
  border-radius: $card-border-radius;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
  top: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
