@use 'common/_shared.scss' as *;

.root-container {
  &:last-child {
    display: flex;
    justify-content: flex-end;

    .root {
      transform: translate(50%, 0);
    }
  }
}

.root {
  position: relative;
  width: 120px;
  max-width: 100%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -11px;
}

.progress-bar-point {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: $color-white-smoke-darker;

  &.active {
    background-color: $color-winter-hazel;

    .icon-star {
      color: $color-granite-green;
    }
  }
}

.icon-star {
  color: $color-gainsboro;
  width: 10px;
  height: 10px;
}

.progress-bar-point-text {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 8px 0;
  color: $color-nobel;
  @include main-sans-serif();
  font-size: rem(10px);
  line-height: rem(15px);

  &::first-letter {
    text-transform: uppercase;
  }
}

.progress-bar-point-top {
  padding-bottom: 8px;
  position: absolute;
  top: -46px;
  left: 0;
}

.colored-status {
  @include main-sans-serif($bold);

  &::first-letter {
    text-transform: uppercase;
  }

  @include media('>=desktop') {
    font-size: rem(12px);
  }
}

.points-value {
  @include main-sans-serif($bold);
  font-size: rem(14px);
  color: black;
}

.progress-bar-point-bottom {
  padding: 8px 8px 0;
  left: 0;
}
