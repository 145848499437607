@use 'common/_shared.scss' as *;

.root {
  display: grid;
  gap: 20px;

  & > * {
    min-width: 0;
    min-height: 0;
  }

  @include media('>tablet') {
    gap: 30px;
  }
}

.col-3 {
  grid-template-columns: repeat(1, 1fr);

  @include media('>tablet') {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media('>desktop') {
    grid-template-columns: repeat(3, 1fr);
  }
}

.col-2 {
  grid-template-columns: repeat(1, 1fr);

  @include media('>tablet') {
    grid-template-columns: repeat(2, 1fr);
  }
}
