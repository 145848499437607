@use 'common/_shared.scss' as *;

.root {
  color: $color-tallow;
  font-size: rem(10px);
  @include main-sans-serif($medium);
}

.icon {
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 2px;
}
