@use 'common/_shared.scss' as *;
@use 'common/_wysiwyg.scss' as *;

.form {
  @include media('>=desktop') {
    padding: 0 50px 42px 112px;
  }
}

.switcher {
  display: flex;
}

.field-wrap {
  position: relative;
  display: flex;
  align-items: center;
}

.birthday-select-wrapper {
  max-width: 481px;
}

.nationalities {
  @include media('>=tablet') {
    max-width: 336px;
  }

  @include media('>=desktop') {
    max-width: 316px;
  }
}

.country-of-residence {
  max-width: 242px;

  @include media('>=tablet') {
    max-width: 336px;
  }

  @include media('>=desktop') {
    max-width: 314px;
  }
}

.select-field-wrap {
  margin: 0;
  width: 100%;
  max-width: 195px;

  @include media('>=tablet') {
    max-width: 246px;
  }

  label {
    top: 7px;
  }

  :global {
    .field-error-global {
      bottom: -2px;
    }
  }
}

.modifiers {
  position: relative;
  display: inline-block;
  margin-left: 18px;
  margin-top: 2px;
  min-width: 52px;
}

.form-buttons {
  margin-top: 40px;
}

.radios-wrapper {
  margin-bottom: 20px;
}

.select-item {
  @include media('<=tablet') {
    min-width: 68px;

    & + .select-item:nth-child(1) {
      min-width: 70px;
    }

    & + .select-item:nth-child(3) {
      min-width: 64px;
    }

    & + .select-item {
      margin-left: 10px;
    }
  }
}

.unmutable-description {
  @include main-sans-serif();
  @include wysiwyg-myacc();

  margin-bottom: 20px;
  font-size: rem(11px);
}

.unmutable-submited-description {
  @include main-sans-serif();
  @include wysiwyg-myacc();

  margin-bottom: 20px;
  font-size: rem(11px);
  color: $color-tallow;
}

.button-request {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: rem(12px) rem(34px);

  .loading {
    color: $color-mist-grey;
  }

  @include media('>=tablet') {
    width: 60%;
    min-width: 160px;
  }
}

.button-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: rem(12px);
  font-weight: $book;
}
