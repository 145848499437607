@use 'common/_shared.scss' as *;

.root {
  display: flex;
  position: relative;
  width: 100%;
  max-width: $auth-sidebar-width;
  margin: auto;
  padding: 30px 0;

  @include media('>=tablet') {
    padding: 0 30px;
  }
}

.nav-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-bottom: 2px solid $color-narvik;
  color: $color-narvik;
  font-size: rem(18px);
  font-weight: bold;
  text-transform: uppercase;
  transition: color 0.2s, border-bottom-color 0.2s;

  @include hover() {
    color: $color-black-pearl;
    border-bottom-color: $color-tallow;
  }

  &.disabled {
    color: $color-black-pearl;
    border-bottom-color: $color-tallow;
  }
}
