@use 'sass:color';
@use 'common/_shared.scss' as *;

.root {
  padding-top: 20px;

  @include media('>desktop') {
    padding-top: 50px;
  }
}

.title,
.subtitle,
.form-subtitle {
  @include main-serif();
}

.title {
  margin-bottom: 20px;
  padding-left: 34px;
  @include main-serif();
  font-size: rem(26px);

  @include media('>=tablet') {
    padding-left: 60px;
  }

  @include media('>=desktop') {
    padding-left: 0;
  }
}

.description {
  font-size: rem(14px);
  line-height: rem(24px);

  h3,
  p,
  ul,
  ol {
    margin: 10px 0;
  }

  p:first-child {
    margin-top: 0;
  }

  h3 {
    @include main-sans-serif();
    font-size: rem(14px);
    line-height: 1;
  }

  p {
    font-size: rem(14px);
  }

  a {
    display: inline-block;
    text-decoration: none;
    font-size: rem(14px);
    font-weight: $bold;
    color: $color-tallow;
    transition: color 0.2s;

    @include hover() {
      color: color.adjust($color-tallow, $lightness: 15%);
    }
  }

  em {
    font-style: italic;
  }

  ul,
  ol {
    padding: 0 40px;
    text-align: left;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }
}

.description-margin {
  margin-bottom: 20px;
}

.radios-wrapper {
  margin-bottom: 0;
}

.select-item {
  @include media('<=tablet') {
    min-width: 68px;

    & + .select-item:nth-child(1) {
      min-width: 70px;
    }

    & + .select-item:nth-child(3) {
      min-width: 64px;
    }

    & + .select-item {
      margin-left: 0;
    }
  }
}

.subtitle {
  padding: 0 30px 0;

  @include media('>=desktop') {
    padding: 0 50px 0;
    font-size: rem(18px);
  }
}

.form-wrap {
  position: relative;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.05);
  background: white;

  @include media('>=desktop') {
    padding: 42px 50px 42px 112px;
  }
}

.back-button {
  top: 24px;
  left: 20px;

  @include media('>=tablet') {
    top: 21px;
  }

  @include media('>=desktop') {
    top: 43px;
    left: 50px;
  }
}

.fields {
  width: 100%;
}

.country-of-residence {
  max-width: 242px;

  @include media('>tablet') {
    max-width: 350px;
  }

  @include media('>=desktop') {
    max-width: 334px;
  }
}

.form-checkbox {
  margin-bottom: 20px;

  @include media('>=desktop') {
    margin-bottom: 50px;
    padding: 0 52px;
  }
}

.form-checkbox-text {
  white-space: inherit;
  text-transform: none;
}

.button-save {
  display: block;
  position: relative;
  width: 100%;
  padding: 16px 42px;
  background: $color-tallow;
  color: white;
  transition: background-color 0.2s;

  @include media('>=desktop') {
    width: auto;
    min-width: 200px;
    margin: 0 auto;
  }

  &.loading {
    .loader {
      background: linear-gradient(
        to right,
        white 10%,
        rgba($color-tallow, 0) 42%
      );

      &::before {
        background: white;
      }

      &::after {
        background: $color-pink-swan;
      }
    }
  }

  &:hover .loader {
    background: linear-gradient(to right, white 10%, rgba($color-yuma, 0) 42%);

    &::after {
      background-color: $color-yuma;
    }
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 16px;
  margin-top: -0.5rem;
}

.button-label {
  font-size: rem(14px);
  font-weight: $book;
}

.exclusive-advantages-block {
  margin-top: 50px;
}
