@use 'common/_shared.scss' as *;

.root {
  position: relative;
  background-color: $color-white-smoke;
  color: $color-grey;
  padding: 16px 34px 8px;
  height: 44px;

  @include media('>=tablet') {
    margin: -8px 0 10px 20px;
  }
}
