@use 'common/_shared.scss' as *;

.field-wrapper {
  &:first-child .title {
    margin-top: 0;
  }
}

.field {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.description {
  width: calc(100% - 70px);

  @include media('>=tablet') {
    max-width: 410px;
  }
}

.title {
  margin-top: 0;
  margin-bottom: 10px;
  max-width: calc(100% - 40px);
}

.text {
  margin-top: 0;
  margin-bottom: 10px;
  @include main-sans-serif();
  font-size: rem(12px);
  color: $color-dove-grey;

  @include media('>=tablet') {
    padding-right: 20px;
  }
}

.text-highlighted {
  font-size: rem(12px);
  color: $color-grey;
}

.text-margin {
  margin-right: 10px;
}

.form-switch {
  position: absolute;
  top: 3px;
  right: 0;
  margin: 0;

  @include media('>=tablet') {
    position: static;
  }
}

.info-panel {
  margin-bottom: 20px;

  @include media('>desktop') {
    margin-bottom: 30px;
  }
}
