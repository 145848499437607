@use 'common/_shared.scss' as *;

.root {
  position: relative;
  height: 100%;
  padding-top: 20px;

  @include media('>desktop') {
    padding-top: 50px;
  }
}

.content-wrapper {
  position: relative;
  padding: 30px 20px 30px;
  background: white;
  @include white-box-shadow();

  @include media('>=desktop') {
    padding: 42px 50px;
  }
}

.page-title {
  pointer-events: none;
  padding: 3px 0 10px 34px;

  @include media('>=tablet') {
    padding: 3px 0 10px 60px;
  }

  @include media('>=desktop') {
    padding: 3px 50px 10px 46px;
  }
}

.section-title {
  margin-bottom: 30px;
  font-size: rem(24px);
  font-weight: $bold;
}

.title {
  position: relative;
  margin-bottom: 30px;
  padding-right: 20px;
  @include main-serif();
  font-size: rem(24px);
  line-height: 1;

  @include media('>=tablet') {
    font-size: rem(26px);
  }
}

.delete-user-container {
  padding-top: 0;
  padding-bottom: 0;
}
