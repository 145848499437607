@use 'common/_shared.scss' as *;

@use 'font-face.scss';

@use '~reset-css/sass/reset';
@use 'common/_normalize.scss';
@use 'common/_helpers.scss';
@use 'common/_base.scss';

html,
body {
  height: 100%;
}

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  background-color: $color-snow;
}
