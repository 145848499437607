@use 'common/_shared.scss' as *;

.root {
  position: relative;
  background-color: $color-white-smoke-darker;
  padding: 10px 20px;
  font-size: rem(14px);
  text-align: center;
  overflow: hidden;
}

.container {
  width: 100%;
  padding: 0;
  margin: 0;

  :global {
    .slick-slider {
      width: 100%;
      margin: 0 auto;
    }

    .slick-list {
      padding: 0;
      margin: 0;
    }

    .slick-slide {
      display: none;
      float: left;
      height: 100%;
      min-height: 1px;
    }

    .slick-initialized .slick-slide {
      display: block;
    }

    .slick-fade .slick-track {
      transition: opacity 0.3s ease;
    }

    .slick-fade .slick-slide {
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    .slick-fade .slick-slide.slick-active {
      opacity: 1;
    }
  }
}
