@use 'common/_shared.scss' as *;

.root {
  position: relative;
  margin: 15px 0 20px;
}

.phone-input-wrapper {
  border-width: 0 0 1px 0 !important;
  border-color: $color-narvik !important;
  background: none !important;
  padding-left: 40px !important;
  height: 26px;
  margin-bottom: 15px;

  @include theme-sbm {
    top: 32px;
  }

  :global {
    .selected-flag {
      top: 6px !important;
      left: -8px !important;
    }

    .country-list {
      z-index: 1;
    }
  }
}

.phone-input {
  padding: 15px 0 0;
  @include main-sans-serif($bold);
  font-size: rem(12px);

  &.error {
    color: red;
  }

  &:-ms-input-placeholder {
    color: $color-suva-grey;
  }
}

.label {
  position: absolute;
  left: 0;
  top: -5px;
  text-transform: uppercase;
  font-size: rem(10px);
  @include main-sans-serif($bold);
  color: $color-grey;
  z-index: 1;
}
