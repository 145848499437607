@use 'common/_shared.scss' as *;

$maxHeight: 55px;

.root {
  position: relative;
  display: flex;
  vertical-align: top;
}

.arrow-wrappper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  flex: 1;
  max-width: 40px;

  .open &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $color-white-smoke;
  }
}

.arrow-button {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-bottom: 30px;
}

.arrow-icon {
  position: sticky;
  display: inline-block;
  vertical-align: top;
  width: 40px;
  top: 0;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -6px;
    top: 12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    transition: transform 0.2s ease;
    border-color: $color-dove-grey transparent transparent transparent;
  }

  .open &::after {
    transform: rotate(180deg);
    border-color: $color-tallow transparent transparent transparent;
  }
}

.content-wrapper {
  position: relative;
  flex: 1;
  max-height: $maxHeight;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  padding-left: 5px;

  .open & {
    height: auto;
    max-height: 100%;
    overflow: auto;
    display: inline-block;
  }
}

.transparent-button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: $maxHeight;
  width: 100%;
}

.content-wrapper-inside {
  position: relative;
}
