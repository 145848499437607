@use 'common/_shared.scss' as *;
@use 'common/components/Button/Button.module.scss' as *;

// Note: please don't use .root class here because uf Button import
.promo-banner {
  position: relative;
  padding: 10px 20px;
  background-color: $color-tallow;

  @include media('>=tablet') {
    padding: 10px 20px 5px;
  }
}

.content-wrapper {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  max-width: 820px;
}

.title {
  margin-bottom: 10px;
  font-size: rem(20px);
  text-align: center;
  @include main-sans-serif();
  color: white;
}

.button {
  @extend %Button;
  transition: background-color 0.2s;
  border: 1px solid $color-white;
  background: inherit;
  color: $color-white;
  display: block;
  margin: 0 auto 10px;

  &[disabled] {
    background-color: $color-pink-swan;

    &.inverted {
      color: $color-pink-swan;
      border: 1px solid $color-pink-swan;
    }
  }

  @include hover-with-theme('.hover-wrapper') {
    color: $color-black;
    border-color: $color-white;
    background-color: $color-white;
  }
}
