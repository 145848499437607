@use 'common/_shared.scss' as *;

.root {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px 15px;
  font-size: rem(12px);
  color: $color-black-pearl;
}

.icon {
  width: 100%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  fill: currentColor;

  svg {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
}
