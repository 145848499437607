@use 'common/_shared.scss' as *;

$offset-small: 20px;
$offset: 30px;

.root {
  position: relative;
}

.full-width {
  width: calc(100% + 100px);

  @include media('>=tablet') {
    width: calc(100% + 122px);
  }
}

.form {
  display: flex; // fix IE11 height change on input focus
  flex-direction: column;
  max-width: 100%;
}

.form-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
}

.form-button-link {
  display: inline-block;
  font-size: rem(14px);
  text-decoration: underline;
  padding: 5px;
  color: $color-black-pearl;
}

.form-button-link-top-spacer {
  margin-top: 25px;
}

.form-checkbox {
  margin: 0;
  font-size: rem(10px);
  color: $color-tallow;
}

.form-checkbox-text {
  text-transform: none;
  white-space: normal;
}

.submit-button {
  position: relative;
  width: 100%;
  padding-left: 35px;
  padding-right: 35px;

  &.loading {
    .loader::after {
      background: $color-mist-grey;
    }
  }

  &:hover .loader {
    background: linear-gradient(to right, white 10%, rgba($color-yuma, 0) 42%);

    &::after {
      background-color: $color-yuma;
    }
  }
}

.auth-subtitle {
  margin-bottom: 30px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 30px;
  margin-top: -0.5rem;
  background: $color-tallow;
  background: linear-gradient(to right, white 10%, rgba($color-tallow, 0) 42%);

  &::before {
    background: white;
  }

  &::after {
    background: $color-tallow;
    transition: background-color 0.2s;
  }
}

.captcha {
  margin: 20px auto 0;
}
