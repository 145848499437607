// IMPORTANT NOTE: This file not for any kind of styles!
// It should contain only SCSS staff that will produce 0 bytes in final css file.
// This shared module is included to ALL SCSS chunks to re-use variables, mixins, etc.
@use 'sass:math';
@use 'sass:string';
@use 'sass:list';
@use 'sass:map';
@use 'sass:meta';
@use 'sass:color';

@use '~include-media/dist/include-media' as *;
@forward '~include-media/dist/include-media';

@use '~common/_fonts.scss' as *;
@forward '~common/_fonts.scss';

// Colors
// note: please use http://www.htmlcsscolor.com/hex/000000 to define approx color name
$color-tallow: #b0a06c;
$color-yuma: #cbb97d;
$color-double-spanish-white: #cdc19e;
$color-black-pearl: #12171e;
$color-midnight-express: #1e2339;
$color-zambezi: #5c5c5c;
$color-matterhorn: #545454;
$color-grey: #777777;
$color-dove-grey: #7c7a73;
$color-suva-grey: #8e8a89;
$color-ash: #b9b5a7;
$color-mist-grey: #b9b9b4;
$color-silver: #cdcdcd;
$color-merino: #dcd9d4;
$color-narvik: #e6e5e2;
$color-whisper: #ebebeb;
$color-alabaster: #f2f1ed;
$color-white-smoke: #f6f6f6;
$color-white-smoke-darker: #f3f3f3;
$color-snow: #fbfbfb;
$color-snow-light: #fcfcfc;
$color-fire-brick: #b32725;
$color-limeade: #5cab21;
$color-zircon: #ebecec;
$color-pink-swan: #b4b4b4;
$color-cornflower-blue: #558ae3;
$color-gainsboro: #e3e3e3;
$color-dark-gray: #a9a9a9;
$lightning-yellow: #f0a732;
$color-geraldine: #e87b79;
$color-silver-tree: #69c787;
$color-white: #ffffff;
$color-black: #000;
$color-pattens-blue: #e3eaed;
$color-alice-blue: #f2f5f6;

$color-nobel: #969393;
$color-winter-hazel: #d5c381;
$color-granite-green: #98895e;

// MICE
$color-blue: #457483;
$color-dark-blue: #002639;
$color-light-blue: #b5dfde;
$color-teal: #027787;
$color-azure: #ecf0f1;
$color-prussian-blue: #004162;
$color-blue-whale: #0f3345;
$desktop-margin: 40px;
$mobile-margin: 20px;

$intro-title-desc: 32px;
$intro-title-mob: 28px;
$intro-text: 15px;

// Celebration
$color-nero: #1f1f1f;
$color-dim-gray: #6d6d6d;
$color-pippin: #faded2;
$color-wind-sand: #f5f5f5;

//MyAcc

$color-light-brown: #aba577;
$color-main-silver-card: #adadad;
$color-main-gold-card: #ccae66;
$color-main-platinum-card: #555352;
$color-main-membre-card: #a07554;
$color-burnt-crimson: #58151c;
$color-carissma: #ea868f;
$color-blossom: #f1aeb5;
$color-pale-pink: #f8d7da;
$color-dark-green: #0a3622;
$color-vista-blue: #a3cfbb;
$color-white-ice: #d1e7dd;

$color-auth-white-background: rgba(255, 255, 255, 0.95);
$color-shadow-membre-card: rgba($color-main-membre-card, 0.7);
$color-shadow-silver-card: rgba($color-main-silver-card, 0.7);
$color-shadow-gold-card: rgba($color-main-gold-card, 0.7);
$color-shadow-platinum-card: rgba($color-main-platinum-card, 0.7);

$box-shadow-color: rgba(37, 110, 144, 0.07);

$auth-sidebar-width: 500px;

$header-height: 54px;
$header-height-desktop: 42px;
$header-height-with-border: $header-height + 1px;
$header-height-desktop-with-border: $header-height-desktop + 1px;

// Form block theme
$block-theme-input-background: white;
$block-theme-input-border: $color-narvik;
$block-theme-input-padding: 20px;
$block-theme-input-label-active-indent: 3px;

// MyAcc cards and widgets
$card-padding-v: 32px;
$card-padding-h: 48px;
$mobile-card-padding-v: 32px;
$mobile-card-padding-h: 16px;
$card-border-radius: 16px;
$button-height: 48px;

@mixin white-box-shadow() {
  box-shadow: 0 0 25px 0 $box-shadow-color;
}

// Themes color schema
// each schema corresponds to the information recorded in body className
// For example: <body class="theme-gold" /> use gold color scheme.
// To set proper color for current scheme, use the following mixin:
// @include color-schema(color-name) using($color-value) {styles...}
$color-schemas: (
  'sbm': (
    main-color: $color-tallow,
    second-color: $color-white-smoke,
    attribute-label-color: $color-black-pearl,
    text-color: $color-black,
    colored-card-text: $color-black-pearl,
    highlight-color: $color-tallow,
    footer-bgc: $color-black-pearl,
    footer-heading-color: $color-tallow,
    footer-sec-menu-color: $color-mist-grey,
    brochure-bg: $color-white-smoke,
    brochure-color: $color-black,
    form-label-color: $color-grey,
    form-border-color: $color-narvik,
    form-legend-color: $color-tallow,
    second-label-color: $color-grey,
    second-border-color: $color-narvik,
    star-color: $color-tallow,
    review-bg: $color-white,
    review-bg-highlighted: $color-alabaster,
    review-text-color: $color-zambezi,
    newsletter-bg: $color-black,
    newsletter-highlighted: $color-tallow,
    main-white-btn-hover-bg: $color-tallow,
    main-white-btn-text: $color-tallow,
    main-white-btn-text-hover: $color-white,
    main-black-bg: $color-black-pearl,
    main-black-hover-bg: $color-grey,
    btn-main-color: $color-tallow,
    ten-reason-bg: $color-tallow,
    ten-reason-text: $color-white,
  ),
  'mice': (
    main-color: $color-dark-blue,
    second-color: $color-light-blue,
    attribute-label-color: $color-dark-blue,
    text-color: $color-dark-blue,
    colored-card-text: $color-dark-blue,
    highlight-color: $color-blue,
    footer-bgc: $color-dark-blue,
    footer-heading-color: $color-light-blue,
    footer-sec-menu-color: $color-blue,
    brochure-bg: $color-teal,
    brochure-color: $color-white,
    form-label-color: $color-dark-blue,
    form-border-color: $color-narvik,
    form-legend-color: $color-dark-blue,
    second-form-label-color: $color-light-blue,
    second-form-border-color: $color-black,
    star-color: $color-dark-blue,
    review-bg: $color-pattens-blue,
    review-bg-highlighted: $color-alice-blue,
    review-text-color: $color-dark-blue,
    newsletter-bg: $color-dark-blue,
    newsletter-highlighted: $color-light-blue,
    main-white-btn-hover-bg: $color-light-blue,
    main-white-btn-text: $color-white,
    main-white-btn-text-hover: $color-white,
    main-black-bg: $color-dark-blue,
    main-black-hover-bg: $color-prussian-blue,
    btn-main-color: $color-dark-blue,
    ten-reason-bg: $color-light-blue,
    ten-reason-text: $color-blue,
  ),
  'celeb': (
    main-color: $color-tallow,
    second-color: $color-pippin,
    attribute-label-color: $color-nero,
    text-color: $color-nero,
    colored-card-text: $color-black-pearl,
    highlight-color: $color-tallow,
    footer-bgc: $color-nero,
    footer-heading-color: $color-tallow,
    footer-sec-menu-color: $color-mist-grey,
    brochure-bg: $color-pippin,
    brochure-color: $color-nero,
    form-label-color: $color-nero,
    form-border-color: $color-narvik,
    second-form-label-color: $color-white,
    second-form-border-color: $color-nero,
    form-legend-color: $color-nero,
    star-color: $color-nero,
    review-bg: $color-white,
    review-bg-highlighted: $color-alabaster,
    review-text-color: $color-nero,
    newsletter-bg: $color-nero,
    newsletter-highlighted: $color-tallow,
    main-whiete-btn-hover-bg: $color-tallow,
    main-white-btn-text: $color-white,
    main-white-btn-text-hover: $color-white,
    main-black-bg: $color-black-pearl,
    main-black-hover-bg: $color-grey,
    btn-main-color: $color-tallow,
    ten-reason-bg: $color-tallow,
    ten-reason-text: $color-white,
  ),
);

@mixin clearfix {
  &::after {
    clear: both;
    content: '';
    display: block;
  }
}

@function _is-color($color) {
  @return (meta.type-of($color) == color) or ($color == 'currentColor');
}

/// shade - mixes a color with black.
/// @argument {color} $color
/// @argument {number (percentage)} $percent
///   The amount of black to be mixed in.
@function shade($color, $percent) {
  @if not _is-color($color) {
    @error "`#{$color}` is not a valid color for the `$color` argument in " +
      "the `shade` mixin.";
  } @else {
    @return color.mix(#000, $color, $percent);
  }
}

/// tint - mixes a color with white.
/// @argument {color} $color
/// @argument {number (percentage)} $percent
///   The amount of white to be mixed in.
@function tint($color, $percent) {
  @if not _is-color($color) {
    @error "`#{$color}` is not a valid color for the `$color` argument in " +
      "the `tint` mixin.";
  } @else {
    @return color.mix(#fff, $color, $percent);
  }
}

@mixin color-schema($info...) {
  @each $name, $schema in $color-schemas {
    $resp: ();

    @each $name in $info {
      $resp: list.append($resp, map.get($schema, $name));
    }

    @at-root {
      body[class*='theme-#{$name}'] &,
      &[class*='theme-#{$name}'] {
        @content ($resp...);
      }
    }
  }
}

@mixin theme-sbm() {
  @at-root {
    body[class*='theme-sbm'] &,
    &[class*='theme-sbm'] {
      @content;
    }
  }
}

@mixin theme-mice() {
  @at-root {
    body[class*='theme-mice'] &,
    &[class*='theme-mice'] {
      @content;
    }
  }
}

@mixin theme-celeb() {
  @at-root {
    body[class*='theme-celeb'] &,
    &[class*='theme-celeb'] {
      @content;
    }
  }
}

//Placeholder mixin
@mixin placeholder($color: black) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
}

//Hyphens mixin
@mixin hyphens($mode: auto) {
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: $mode;
  -ms-hyphens: $mode;
  hyphens: $mode;
}

// Hover
@mixin hover() {
  // Global of css modules scope
  :global(.no-touchevents) &:hover,
  [data-focus-source='key'] &:focus {
    @content;
  }
}

// Hover with different values for different themes
@mixin hover-with-theme($parent, $info...) {
  @if list.length($info) > 0 {
    @each $name, $schema in $color-schemas {
      $resp: ();

      @each $name in $info {
        $resp: list.append($resp, map.get($schema, $name));
      }

      @at-root {
        :global(.no-touchevents) body[class*='theme-#{$name}'] &:hover,
        [data-focus-source='key'] body[class*='theme-#{$name}'] &:focus,
        :global(.no-touchevents)
          body[class*='theme-#{$name}']
          #{$parent}:hover
          &,
        [data-focus-source='key']
          body[class*='theme-#{$name}']
          #{$parent}:focus
          & {
          @content ($resp...);
        }
      }
    }
  } @else {
    @at-root {
      :global(.no-touchevents) &:hover,
      [data-focus-source='key'] &:focus,
      :global(.no-touchevents) #{$parent}:hover &,
      [data-focus-source='key'] #{$parent}:focus & {
        @content;
      }
    }
  }
}

// Focus
@mixin focus() {
  [data-focus-source='key'] &:focus {
    @content;
  }
}

// Convert Photoshop Letter Tracking to letter-spacing css value:
@mixin letter-tracking($value) {
  letter-spacing: $value * 0.001 * 1em;
}

// Responsive breakpoints. "Include media" settings.
// 930px - approx available browser height for FHD 1920x1080
$breakpoints: (
  tablet: 768px,
  desktop: 1280px,
  large-desktop: 1440px,
  hfhd: 930px,
);

@mixin define-breakpoint($name) {
  &:after {
    content: $name;
    display: none;
  }
}

@mixin native-focus {
  outline-width: 2px;
  outline-style: solid;
  outline-color: Highlight;

  @media (-webkit-min-device-pixel-ratio: 0) {
    outline-width: 5px;
    outline-style: auto;
    outline-color: -webkit-focus-ring-color;
  }
}

// Fancy stuff
/// Shorthand mixin for offset positioning
/// @param {String} $position - Either `relative`, `absolute` or `fixed`
/// @param {Length} $top [null] - Top offset
/// @param {Length} $right [null] - Right offset
/// @param {Length} $bottom [null] - Bottom offset
/// @param {Length} $left [null] - Left offset
@mixin position(
  $position,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
/// Returns the opposite direction of each direction in a list
/// @author Hugo Giraudel
/// @param {List} $directions - List of initial directions
/// @return {List} - List of opposite directions
@function opposite-direction($directions) {
  $opposite-directions: ();
  $direction-map: (
    'top': 'bottom',
    'right': 'left',
    'bottom': 'top',
    'left': 'right',
    'center': 'center',
    'ltr': 'rtl',
    'rtl': 'ltr',
  );

  @each $direction in $directions {
    $direction: string.to-lower-case($direction);

    @if map.has-key($direction-map, $direction) {
      $opposite-directions: string.unquote(map.get($direction-map, $direction));
    } @else {
      @warn 'No opposite direction can be found for `#{$direction}`. Direction omitted.';
    }
  }

  @return $opposite-directions;
}

/// Triangle helper mixin
/// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
/// @param {Color} $color [currentcolor] - Triangle color
/// @param {Length} $size [1em] - Triangle size
@mixin triangle(
  $direction: top,
  $size: 0.3em,
  $color: currentcolor,
  $size-step: 1.5
) {
  @if not list.index(top right bottom left, $direction) {
    @error 'Direction must be either `top`, `right`, `bottom` or `left`.';
  }

  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-#{opposite-direction($direction)}: ($size * $size-step) solid $color;

  $perpendicular-borders: $size solid transparent;

  @if $direction == top or $direction == bottom {
    border-left: $perpendicular-borders;
    border-right: $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top: $perpendicular-borders;
  }
}

@mixin object-fit-polyfill() {
  max-width: none;
  max-height: none;
  position: absolute;
  top: 50%;
  left: -100%;
  right: -100%;
  min-height: 100%;
  min-width: calc(100% + 1px);
  width: auto;
  height: auto;
  display: inline-block;
  margin: 0 auto;
  transform: translateY(-50%);
}

// Additional block before the footer. Example described in Bug #287843
// It is necessary to have grey bg if the last element before footer is grey.
@mixin greyBackgroundBeforeFooter() {
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    height: 50px;
    width: 100%;
    background-color: inherit;
  }

  @include media('>=tablet') {
    &::after {
      height: 55px;
    }
  }

  @include media('>=desktop') {
    &::after {
      height: 75px;
    }
  }
}

@mixin break-word-custom() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

/// Repeat function with space separator
/// @param {$c} - any value
/// @param {$n} - count of iterations
@function rpt($c, $n) {
  $list: $c;
  @for $i from 1 through ($n - 1) {
    $list: list.append($list, $c, space);
  }
  @return $list;
}

@mixin snake() {
  display: block;
  border: 3px solid $color-tallow;
  border-radius: 50%;
  border-color: rgba($color-tallow, 0.7) transparent transparent transparent;
  height: 40px;
  width: 40px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  animation-timing-function: linear;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;

  :global {
    animation-name: spinner-snake;
  }
}

@keyframes shadow-highlight {
  0% {
    box-shadow: 0px 0px 20px 0 rgba(0, 0, 0, 0.02);
  }

  50% {
    box-shadow: 0px 0px 20px 0 rgba(0, 0, 0, 0.15);
  }

  100% {
    box-shadow: 0px 0px 20px 0 rgba(0, 0, 0, 0.01);
  }
}
