@use 'common/_shared.scss' as *;
@use 'common/_helpers.scss' as *;

.root {
  position: relative;
}

.address-first {
  margin-bottom: 10px;
}

.address-second {
  margin-top: 10px;
}

.sub-field {
  width: 100%;

  &:not(:last-child) {
    margin-right: 20px;
  }
}
