@use 'common/_shared.scss' as *;

$border-color: $color-narvik;

.root {
  width: 100%;
  height: 100%;
  padding: 20px;
  background: $color-auth-white-background;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.root-content {
  width: 100%;
  max-width: $auth-sidebar-width;
  margin: auto;
}

.top-block {
  padding: 10px 30px 30px;
}
