@use 'common/_shared.scss' as *;

$offset: 30px;

.form {
  position: relative;
  padding: 60px $offset;
}

.form-content {
  position: relative;
  font-size: rem(14px);
}

.radios-wrapper {
  margin-bottom: 20px;
}

.switcher {
  display: flex;
}

.select-item {
  @include media('<=tablet') {
    & + .select-item:nth-child(1) {
      min-width: 70px;
    }

    & + .select-item:nth-child(3) {
      min-width: 64px;
    }

    & + .select-item {
      margin-left: 2%;
    }
  }
}

.input {
  text-transform: none;
}

.checkbox-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  padding: 10px 20px 10px 87px;
  background-color: $color-white-smoke;
  font-size: rem(11px);
  color: $color-zambezi;

  a {
    text-transform: none;
    color: $color-tallow;
  }
}

.field-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: $color-whisper;
  width: 67px;
}

.form-switch {
  margin: 0;
}

.text {
  margin-bottom: 20px;
  font-size: rem(14px);

  a {
    text-decoration: underline;
    color: inherit;

    @include hover() {
      text-decoration: none;
    }
  }
}
