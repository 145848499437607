@use 'common/_shared.scss' as *;

.item-logo {
  height: 42px;
  margin-bottom: 8px;
  line-height: 1;
  color: $color-tallow;

  img {
    display: block;
    max-height: 42px;
    max-width: 100px;
    margin: 0 auto;
  }
}

.item-logo-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.text-logo {
  display: block;
  white-space: nowrap;
}

.big-text-logo {
  font-size: rem(42px);
}

.mid-text-logo {
  font-size: rem(18px);
}

.small-text-logo {
  font-size: rem(12px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.bg-image {
  height: 42px;
  max-width: 100px;
  margin: 0 auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.item-logo-stars {
  display: inline-block;
  max-width: 55px;
  font-size: 12px;
  letter-spacing: 5px;
  word-break: break-all;
}

.item-label {
  @include main-sans-serif($medium);
  font-size: rem(11px);
  line-height: 1.35;
  letter-spacing: rem(0.8px);
  @include color-schema(attribute-label-color) using ($color) {
    color: $color;
  }
}

@include media('>=tablet') {
  .item-logo {
    height: 36px;
    font-size: rem(36px);

    img {
      max-height: 36px;
    }

    .bg-image {
      height: 36px;
    }
  }

  .item-label {
    font-size: rem(9px);
  }
}
