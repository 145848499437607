@use 'common/_shared.scss' as *;

.root {
  position: absolute;
  top: calc(50% - 6px);
  right: 0;
}

.icon-eye {
  height: rem(12px);
  width: rem(12px);
}

.is-error {
  color: $color-fire-brick;
}

.is-text {
  color: $color-tallow;
}
