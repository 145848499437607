@use 'common/_shared.scss' as *;

.root {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
}

.button {
  width: 16px;
  height: 16px;
  margin-right: 20px;
  transition: color 0.15s ease;

  &:last-child {
    margin-right: 0;
  }

  @include hover() {
    color: $color-tallow;
  }
}
