@use 'common/_shared.scss' as *;

.share-button-wrapper {
  display: flex;
  justify-content: center;

  @include media('>=tablet') {
    position: absolute;
    top: 25px;
    right: 40px;
  }

  @include media('>=desktop') {
    top: 70px;
  }
}
