@use 'common/_shared.scss' as *;

.root {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-top: 20px;

  a {
    font-size: rem(14px);
    text-decoration: none;
    color: $color-tallow;
    padding-right: 7px;

    @include hover() {
      text-decoration: underline;
    }
  }
}
