@use 'common/_shared.scss' as *;

.root {
  position: relative;
  overflow: hidden;
  font-size: rem(12px);
  line-height: 2.5;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  @include media('>=tablet') {
    font-size: rem(13px);
    line-height: 2.25;
    column-count: 2;
  }

  @include theme-mice {
    .list-item {
      font-size: rem(14px);
      line-height: 1.6;

      @include media('>=tablet') {
        margin-bottom: 12px;
      }

      @include media('<=tablet') {
        padding-bottom: 15px;

        &:last-child {
          padding-bottom: 0;
        }
      }

      &::before {
        width: 10px;
        height: 10px;
        top: 6px;
        left: 16px;
        border-radius: 50%;

        @include media('<=tablet') {
          left: 10px;
        }
      }
    }
  }
}

.services-list {
  &:not(.expanded) li:nth-child(n + 9) {
    position: absolute;
    margin-bottom: 10px;
    height: 0;
    width: 0;
    visibility: hidden;
    opacity: 0;
  }

  &.expanded li:nth-child(n + 9) {
    margin-bottom: 0;
    opacity: 1;
    transition: margin-bottom 350ms ease, opacity 350ms ease;
  }
}

.list-item {
  position: relative;
  padding-left: 30px;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;

  &::before {
    position: absolute;
    top: 13px;
    left: 2px;
    width: 6px;
    height: 6px;
    content: '';
    border-radius: 3px;
  }

  @include color-schema(star-color) using ($color) {
    &:before {
      background-color: $color;
    }
  }

  @include media('>=tablet') {
    padding-left: 35px;
    padding-right: 25px;
  }
}

.list-item-file {
  @include color-schema(highlight-color) using ($color) {
    color: $color;
  }

  @include hover() {
    text-decoration: none;
  }
}
