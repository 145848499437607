@use 'common/_shared.scss' as *;

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.root {
  animation: appear 0.3s ease;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: black;
  z-index: 1000;
}

.modal-content {
  max-height: 100vh;
  position: relative;
  z-index: 1001;

  @include media('>=tablet') {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.modal-transition-enter {
  opacity: 0.01;

  @include media('<tablet') {
    transform: scale(0.9) translateY(50%);
  }
}

.modal-transition-enter-active {
  opacity: 1;
  transition: all 200ms ease-out;

  @include media('<tablet') {
    transform: scale(1) translateY(0%);
  }
}

.modal-transition-done-enter {
  opacity: 1;
}

.modal-transition-exit {
  opacity: 1;

  @include media('<tablet') {
    transform: scale(1) translateY(0%);
  }
}

.modal-transition-exit-active {
  opacity: 0.01;
  transition: all 200ms ease-out;

  @include media('<tablet') {
    transform: scale(0.9) translateY(50%);
  }
}
