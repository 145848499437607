@use 'common/_shared.scss' as *;

.picture-link {
  position: initial;
}

.push-left-col {
  position: relative;
  height: 100%;

  @include hover {
    :global {
      .slick-arrow {
        display: block;
        opacity: 1;
      }
    }
  }

  :global {
    .slick-track {
      display: flex;
      flex-wrap: nowrap;
    }

    .slick-arrow {
      display: none;
      position: absolute;
      z-index: 1;
      top: 50%;
      width: 38px;
      height: 38px;
      padding: 5px;
      opacity: 0;
      color: white;
      cursor: pointer;
      transition: color 0.15s linear, opacity 0.15s linear;

      &:before {
        display: none;
      }

      svg {
        filter: drop-shadow(0 0 1px rgba($color-tallow, 0.5));
      }

      @include hover {
        color: $color-tallow;

        svg {
          filter: drop-shadow(0 0 1px rgba(white, 0.5));
        }
      }
    }

    .slick-prev {
      left: 15px;
      transform: translateY(-50%) rotate(180deg);
    }

    .slick-next {
      right: 15px;
      transform: translateY(-50%);
    }
  }
}

.long-style {
  @include media('<desktop') {
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  @include media('>=desktop') {
    overflow: hidden;

    &:not(.slider-container) img {
      @include object-fit-polyfill();
    }

    &.slider-container {
      img {
        max-width: calc(((1440px - 80px) * 2 / 3) + 9px);
        width: calc(((100vw - 80px) * 2 / 3) + 9px);
        height: 100%;
        object-fit: cover;
      }

      :global {
        .slick-slide > div,
        .slick-slider,
        .slick-track,
        .slick-list {
          height: 100%;
        }
      }
    }

    .img-longstyle-container {
      height: 100%;
    }
  }
}

.short-style {
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.slider-dots {
  position: absolute;
  margin: 0;
  padding: 0;
  bottom: 0;
  width: 100%;
  list-style: none;
  text-align: center;

  li {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 5px 17px;
    padding: 0;
    cursor: pointer;
    z-index: 1;

    &:only-child {
      display: none;
    }
  }

  button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 16px;
    height: 16px;
    padding: 3px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      box-sizing: border-box;
      border: 2px solid #fff;
      border-radius: 5px;
    }
  }

  :global(.slick-active button::before) {
    background-color: #fff;
  }

  @include media('>=tablet') {
    li {
      margin: 0 2px 12px;
    }
  }

  @include media('>=desktop') {
    li {
      width: 20px;
      height: 20px;
      margin: 0 2px 5px;
    }

    button {
      width: 20px;
      height: 20px;

      &::before {
        top: 3px;
        left: 3px;
        width: 12px;
        height: 12px;
        border-radius: 6px;
      }
    }
  }
}
