@use 'common/_shared.scss' as *;

.text-wrap {
  position: relative;
  overflow: hidden;
  line-height: inherit;

  &.is-expanded {
    max-height: none;
  }

  &::after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20px;
    content: '';
    background: linear-gradient(
      to bottom,
      rgba(white, 0.3) 0%,
      rgba(white, 1) 100%
    );
    transition: background 0.3s ease;
    pointer-events: none;
  }

  &.is-expanded::after {
    background: linear-gradient(
      to bottom,
      rgba(white, 0) 0%,
      rgba(white, 0) 100%
    );
  }

  &.light-gray {
    &::after {
      background: linear-gradient(
        to bottom,
        rgba($color-snow, 0.3) 0%,
        rgba($color-snow, 1) 100%
      );
    }

    &.is-expanded::after {
      background: linear-gradient(
        to bottom,
        rgba($color-snow, 0) 0%,
        rgba($color-snow, 0) 100%
      );
    }

    .toggle {
      background-color: $color-snow;
    }
  }

  &.black-pearl {
    &::after {
      background: linear-gradient(
        to bottom,
        rgba($color-black-pearl, 0.3) 0%,
        rgba($color-black-pearl, 1) 100%
      );
    }

    &.is-expanded::after {
      background: linear-gradient(
        to bottom,
        rgba($color-black-pearl, 0) 0%,
        rgba($color-black-pearl, 0) 100%
      );
    }

    .toggle {
      background-color: $color-black-pearl;
    }
  }
}

.text-wrap-animated {
  position: relative;
  overflow: hidden;
  line-height: inherit;
  transition: height 0.3s ease;
}

.toggle {
  position: absolute;
  bottom: 2px;
  right: 1px; // Fix outline cutting.
  padding-left: 10px;
  text-transform: uppercase;
  @include main-sans-serif($medium);
  font-size: rem(12px);
  background-color: white;
  z-index: 1;

  @include color-schema(highlight-color) using ($color) {
    color: $color;
  }

  .is-expanded & {
    position: relative;
    bottom: 0;
    padding-left: 0;
  }
}

.read-more-close-icon {
  display: inline-block;
  height: 16px;
  width: 10px;
  margin-right: 5px;
  vertical-align: middle;

  @include media('>=tablet') {
    height: 19px;
  }

  @include media('>=desktop') {
    height: 16px;
  }
}

.btn-text {
  display: inline-block;
  vertical-align: middle;
}
