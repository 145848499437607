@use 'common/_shared.scss' as *;

.root {
  max-width: 760px;
  margin: 20px 30px 10px;
}

.fieldset {
  margin-bottom: 20px;

  @include media('>=tablet') {
    margin-bottom: 45px;
  }
}

.general-fields {
  @include media('>=tablet') {
    margin-bottom: 10px;
  }
}

.section-title {
  margin-bottom: 15px;
  color: $color-tallow;
  font-size: rem(14px);
  text-transform: uppercase;
  @include main-sans-serif();

  &.required::after {
    content: '*';
    display: inline;
  }

  @include media('>=tablet') {
    margin-bottom: 20px;
  }
}

.inline-radios {
  display: flex;
  justify-content: space-between;
  margin: 5px 0 10px;
}

.real-phone-input {
  display: none;
}

.phone-input-wrapper {
  height: 48px;
  z-index: 1;
  border-color: $color-narvik;
}

.phone-input {
  padding-top: 10px;
}

.phone-label {
  display: block;
  margin-bottom: 5px;
  text-align: left;
  text-transform: uppercase;
  font-size: rem(10px);
  font-weight: $bold;
  color: $color-grey;
}

.comments {
  margin-top: 10px;
  line-height: 2.5;
}

.newsletter-cta {
  position: relative;

  .loading {
    color: $color-mist-grey;
  }
}

.newsletter-submit {
  width: 100%;
  padding: 17px 30px;
}

.loader {
  position: absolute;
  top: 50%;
  right: calc(50% - 0.5rem);
  margin-top: -0.5rem;
}

.requirements {
  margin: 10px 20px;
  text-align: center;
  font-size: rem(11px);
  color: $color-suva-grey;
}

.captcha-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 263px;
  margin: 20px auto;

  @include media('<tablet') {
    width: 258px;
  }

  & > div {
    transform: scale(0.87);
  }
}
