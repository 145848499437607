@use 'common/_shared.scss' as *;

.root {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin: 0 -10px 20px;
  padding: 0 10px 10px;

  @include media('>=tablet') {
    flex-wrap: nowrap;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.fields-wrapper {
  position: relative;
  flex-basis: 100%;

  @include media('>=tablet') {
    display: flex;
    width: calc(100% - 95px);
  }
}

.main-field {
  flex-basis: 55%;

  @include media('>=tablet') {
    margin-right: 10%;
  }
}

.secondary-field {
  flex-basis: 35%;
  min-width: 120px;
}

.cta {
  display: flex;
  flex-shrink: 0;

  @include media('>=tablet') {
    width: 80px;
    margin-left: auto;
    padding: 24px 0 16px 20px;
  }

  @include media('>=desktop') {
    width: 95px;
    padding: 24px 20px 20px 20px;
  }
}
