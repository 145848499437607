@use 'common/_shared.scss' as *;

$color-header-border-on-light: rgba(black, 0.3);
$color-header-border-on-light-lighter: rgba(black, 0.1);
$color-header-border-on-dark: rgba(white, 0.3);

.root {
  position: relative;
  display: flex;
  height: 100%;
  width: $header-height;
  align-items: center;
  color: currentColor;
  text-decoration: none;
  letter-spacing: 0.1em;
  transition: background-color 0.15s;

  @include media('>=desktop') {
    width: auto;
    margin-right: auto;
  }
}

.mobile-menu-wrapper {
  background: white;

  @include media('<desktop') {
    position: fixed;
    left: 0;
    top: $header-height-with-border;
    right: 0;
    bottom: 0;
  }

  @include media('>=desktop') {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.burger {
  width: $header-height;
  border-right: 1px solid $color-header-border-on-light;

  @include media('>=desktop') {
    padding-top: 5px;
    padding-bottom: 4px;
    color: white;
    border-right: 1px solid $color-header-border-on-dark;
  }
}

.universe-menu-trigger {
  position: relative;
  display: block;
  z-index: 9;
  width: 100%;
  height: $header-height-desktop;
  padding: 0;
  transition: background-color 0.15s;
  text-transform: uppercase;
  color: white;
  font-size: rem(10px);
  font-weight: $book;
  letter-spacing: rem(1px);
  border-right: 1px solid $color-header-border-on-dark;

  @include hover {
    background: $color-header-border-on-dark;
  }

  &.is-active {
    background: white;
    color: black;

    @include hover {
      background: white;
    }

    &::after {
      border-bottom: 3px solid currentColor;
      border-top: none;
    }
  }

  &::after {
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px;
    margin-top: -1px;
    @include triangle(bottom, 4px, $size-step: 1);

    @include media('>=desktop') {
      margin-left: 9px;
      @include triangle(bottom, 3px, $size-step: 1);
    }
  }

  @include media('>=desktop') {
    padding: 0 20px;

    &.inversed {
      min-width: 194px;
      padding: 0 30px;
      text-align: left;
      color: black;

      &::after {
        @include triangle(bottom, 0, $size-step: 1);
        @include triangle(top, 4px, $size-step: 1);
      }
    }
  }
}

.menu-item {
  display: block;
  position: relative;
  width: 100%;
  padding: 20px;
  font-weight: 700;
  font-size: rem(11px);
  text-align: left;
  text-transform: uppercase;
  color: #000000;
  text-decoration: none;
  @include main-sans-serif($book);
  letter-spacing: rem(1.1px);

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    height: 1px;
    background-color: $color-header-border-on-light-lighter;
  }

  &.is-last {
    &::before {
      display: none;
    }
  }

  &.mobile-menu-item-logo {
    display: flex;
    height: auto;
    padding: 20px;
    justify-content: center;

    img {
      margin: 0 auto;
    }
  }

  @include media('>=desktop') {
    display: block;
    padding: 15px 30px;
    font-size: 12px;
    text-transform: none;
    color: #000;
    transition: color 0.25s ease;
    text-align: left;
    letter-spacing: rem(1.6px);

    &::before {
      display: none;
    }

    > span {
      position: relative;
      display: inline-block;
      transition: transform 0.25s ease;
      pointer-events: none;

      &::before {
        content: '';
        display: inline-block;
        width: 0;
        position: absolute;
        right: calc(100% + 10px);
        top: calc(100% - 4px);
        height: 1px;
        background: currentColor;
        transition: width 0.25s ease;
        will-change: width;
      }
    }

    @include hover {
      color: $color-tallow;

      > span {
        transform: translateX(25px);

        &::before {
          width: 15px;
        }
      }
    }

    &:first-child {
      padding-top: 30px;
    }

    &:last-child {
      padding-bottom: 30px;
    }
  }
}

.loading {
  animation: loading 1s steps(5, end) infinite;
}

.transition-enter {
  opacity: 0.01;
  transform: scale(0.75) translateY(-13vh);
}

.transition-enter-active {
  opacity: 1;
  transform: scale(1) translateY(0);
  transition: all 100ms ease-out;
}

.transition-done-enter {
  opacity: 1;
}

.transition-exit {
  opacity: 1;
}

.transition-exit-active {
  opacity: 0.01;
  transform: scale(0.75) translateY(-13vh);
  transition: all 100ms ease-out;
}

@keyframes loading {
  0% {
    color: rgb(0, 0, 0);
  }
  25% {
    color: rgb(60, 60, 60);
  }
  50% {
    color: rgb(120, 120, 120);
  }
  75% {
    color: rgb(60, 60, 60);
  }
  100% {
    color: rgb(0, 0, 0);
  }
}
