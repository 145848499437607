@use 'common/_shared.scss' as *;

.root {
  position: relative;
  margin-bottom: 50px;

  @include media('>=desktop') {
    padding-left: 50px;
  }
}

.icon {
  position: absolute;
  left: 0;
  top: 7px;
  width: 20px;
  height: 20px;
}
