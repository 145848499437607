@use 'common/_shared.scss' as *;

.root {
  margin-bottom: 3px;

  @include media('>=tablet') {
    display: flex;
    grid-area: Telephone;
    justify-content: space-around;
    flex-wrap: nowrap;
    margin: 0 60px 0 30px;
  }

  @include media('>=desktop') {
    margin: 20px 0 10px 0;
    border-top: 1px solid $color-zambezi;

    @include theme-mice {
      border-top-color: $color-blue-whale;
    }
  }

  .telephone-item {
    display: block;
    margin-bottom: 20px;
    width: 100%;

    a {
      margin-right: 15px;
      padding-right: 15px;
      text-decoration: none;
      transition: color 0.2s ease;
      color: $color-mist-grey;

      @include color-schema(highlight-color) using ($color) {
        @include hover() {
          color: tint($color, 40%);
        }
      }
    }

    @include media('>=tablet') {
      margin-top: 15px;
      margin-bottom: 9px;
      max-width: 25%;
    }
  }

  .telephone-label {
    display: block;
    text-align: left;
    margin-bottom: 5px;
    text-transform: uppercase;
    @include main-sans-serif($medium);
    font-size: rem(14px);

    @include color-schema(highlight-color) using ($color) {
      color: $color;
    }

    @include media('<desktop') {
      width: 100%;
      text-align: left;
      position: relative;
      padding-right: 20px;
    }

    @include media('>=tablet') {
      min-height: rem(21px);
    }

    @include media('>=desktop') {
      margin-bottom: 7px;
    }
  }
}
