@use 'common/_shared.scss' as *;

.root {
  position: relative;
  margin: 0 50px 15px 0;

  &:last-child {
    margin-bottom: 0;
  }
}

%Label {
  font-size: rem(8px);
  line-height: rem(12px);
  text-transform: uppercase;
  color: $color-grey;
  display: inline;
}

.label {
  margin-right: 10px;
  @extend %Label;
}

.label-simple {
  @extend %Label;
}

.value {
  font-size: rem(12px);
  display: inline;
  @include break-word-custom();
  @include hyphens();
}
