@use 'common/_shared.scss' as *;

$item-border-radius: 30px;

.root {
  display: flex;
  padding: 10px 20px;
  margin: 20px -20px 5px;
  overflow: auto;

  @include media('>tablet') {
    margin: 20px -40px 5px;
    padding: 10px 40px;
  }

  @include media('>desktop') {
    overflow: visible;
    flex-wrap: wrap;
  }
}

.item {
  cursor: pointer;
  color: black;
  margin-right: 15px;
  margin-bottom: 5px;

  @include media('>desktop') {
    margin-bottom: 15px;
  }

  &:last-child {
    margin-right: 0;
  }

  input {
    display: none;

    [data-focus-source='key'] &:focus + .item-description {
      outline: -webkit-focus-ring-color auto 5px;
    }

    [data-focus-source='key'] &:-moz-focusring + .item-description {
      outline: 1px dotted;
    }

    &:checked + .item-description,
    &:focus + .item-description {
      background-color: $color-tallow;
      color: white;

      .count {
        color: $color-tallow;
      }
    }
  }
}

.item-description {
  display: inline-block;
  padding: 5px 8px 5px 27px;
  font-weight: 500;
  font-size: rem(15px);
  line-height: 30px;
  white-space: nowrap;
  border-radius: $item-border-radius;
  background-color: $color-white-smoke-darker;
  transition: background-color 0.15s, color 0.15s;

  &:hover {
    background-color: $color-tallow;
    color: white;

    .count {
      color: $color-tallow;
    }
  }
}

.count {
  background: white;
  border-radius: $item-border-radius;
  margin-left: 20px;
  display: inline-block;
  padding: 0 12px;
  min-width: 30px;
  text-align: center;
}
