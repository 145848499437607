@use 'common/_shared.scss' as *;

.root {
  @include media('>=tablet') {
    grid-template-areas:
      'f-branding     f-branding'
      'MainMenu       MainMenu'
      'SecMenu        SecMenu';
  }

  @include media('>=desktop') {
    grid-template-columns: rpt(minmax(172px, 270px), 4);
    grid-template-areas:
      'f-branding     f-branding     f-branding     f-branding'
      'MainMenu       MainMenu       MainMenu       MainMenu'
      'SecMenu        SecMenu        SecMenu        SecMenu';
  }
}

.sec-menu {
  text-align: center;

  li {
    @include media('<tablet') {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;

      a {
        margin-right: 10px;
      }

      &::after {
        content: '|';
        margin-right: 10px;
        display: inline;
      }

      &:last-child::after {
        display: none;
      }
    }
  }
}
