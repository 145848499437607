@use 'common/_shared.scss' as *;

.root {
  position: relative;
  @include main-sans-serif();
  margin-top: 32px;

  &.columns-2 {
    column-count: 1;
    column-gap: 0;

    @include media('>=tablet') {
      column-count: 2;
      column-gap: 60px;
    }
  }
}

.checkbox-group-parent {
  & + .checkbox-group-parent {
    margin-top: 17px;
  }
}

.sub-options-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 20px;
  margin: -5px -10px 17px;
}

.sub-option {
  margin-top: 15px;
  margin-bottom: 0;
  padding: 0 10px;
}
