@use 'common/_shared.scss' as *;

$shadow-shift: 6px;

.root {
  overflow: hidden;
  background-color: $color-geraldine;
  color: #fff;
  z-index: 1;
  margin-bottom: 20px;
  will-change: transform;
  transform-origin: top;

  & + .root {
    margin-top: -10px;
  }

  &.success {
    background-color: $color-silver-tree;
  }
}

.inner {
  padding: 13px 48px;
  font-size: rem(12px);
  position: relative;
  @include main-sans-serif($bold);

  @include media('>=tablet') {
    padding: 13px 70px;
  }
}

.icon {
  width: rem(19px);
  height: rem(19px);
  left: 16px;
  top: 10px;
  position: absolute;

  @include media('>=tablet') {
    left: 40px;
  }
}
