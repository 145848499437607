@use 'common/_shared.scss' as *;

.root {
  position: relative;
  margin-bottom: 5px;
}

.checkbox {
  display: none;
}

.label {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
  font-size: rem(11px);
  @include main-sans-serif();
}

.icon {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 10px;
  color: $color-suva-grey;

  &.checked {
    color: $color-tallow;
  }
}

.text {
  font-size: 10px;
  text-transform: uppercase;
  white-space: nowrap;
}
