@use 'common/_shared.scss' as *;

$counter-width: 29px;
$counter-width-long: 33px;

.form {
  position: relative;
  width: 100%;
}

.form-wrapper {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  background: white;

  @include media('>desktop') {
    margin-bottom: 30px;
  }
}

.language-select {
  width: 329px;
  max-width: 100%;
}

.contact-channel {
  margin-top: 20px;

  ol {
    list-style: none;
    counter-reset: contact-channel-items-counter;

    & li {
      counter-increment: contact-channel-items-counter;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      & + li {
        margin-top: 20px;
      }

      &::before {
        content: counter(contact-channel-items-counter) '. ';
        display: inline-block;
        vertical-align: middle;
        color: black;
        position: relative;
        @include main-sans-serif();
        font-size: rem(14px);
        width: $counter-width;

        @include media('>=tablet') {
          font-size: rem(16px);
          width: $counter-width-long;
        }
      }
    }
  }
}

.sub-description {
  padding: 0;
  margin: 5px 0 0 0;
  @include main-sans-serif();
  font-size: rem(12px);
}

.info-panel {
  margin-bottom: 20px;

  @include media('>desktop') {
    margin-bottom: 30px;
  }
}
