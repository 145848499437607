@use 'common/_shared.scss' as *;
@use 'common/_wysiwyg.scss' as *;

.root {
  position: relative;
  height: 100%;
  padding-top: 20px;

  @include media('>desktop') {
    padding-top: 50px;
  }
}

.content-wrapper {
  position: relative;
  width: 100%;
  padding: 30px 20px 30px;
  background: white;
  @include white-box-shadow();
}

.title {
  pointer-events: none;
  padding: 3px 0 10px 34px;

  @include media('>=tablet') {
    padding: 3px 0 10px 60px;
  }

  @include media('>=desktop') {
    padding: 15px 50px 10px 112px;
  }
}

.birth-date-error {
  bottom: 8px;
}

.form {
  @include media('>=desktop') {
    padding: 0 50px 42px 112px;
  }
}

.switcher {
  display: flex;
}

.field-wrap {
  position: relative;
  display: flex;
  align-items: center;
}

.birthday-select-wrapper {
  max-width: 481px;
}

.nationalities {
  @include media('>=tablet') {
    max-width: 336px;
  }

  @include media('>=desktop') {
    max-width: 316px;
  }
}

.country-of-residence {
  max-width: 242px;

  @include media('>=tablet') {
    max-width: 336px;
  }

  @include media('>=desktop') {
    max-width: 314px;
  }
}

.select-field-wrap {
  margin: 0;
  width: 100%;
  max-width: 195px;

  @include media('>=tablet') {
    max-width: 246px;
  }

  label {
    top: 7px;
  }

  :global {
    .field-error-global {
      bottom: -2px;
    }
  }
}

.modifiers {
  position: relative;
  display: inline-block;
  margin-left: 18px;
  margin-top: 2px;
  min-width: 52px;
}

.form-buttons {
  margin-top: 40px;
}

.radios-wrapper {
  margin-bottom: 20px;
}

.select-item {
  @include media('<=tablet') {
    min-width: 68px;

    & + .select-item:nth-child(1) {
      min-width: 70px;
    }

    & + .select-item:nth-child(3) {
      min-width: 64px;
    }

    & + .select-item {
      margin-left: 10px;
    }
  }
}

.unmutable-description {
  @include main-sans-serif();
  @include wysiwyg-myacc();

  margin-bottom: 20px;
  color: $color-fire-brick;
  font-size: rem(12px);
}
