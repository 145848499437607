@use 'common/_shared.scss' as *;

.root {
  position: relative;

  select::-ms-expand {
    display: none;
  }
}

.date-wrapper {
  display: flex;
  align-items: center;
  margin-right: -10px;
}

.date-label {
  display: block;
  margin-top: 15px;
  margin-bottom: 2px;
  text-transform: uppercase;
  font-size: rem(11px);
  @include main-sans-serif($bold);
  color: $color-grey;
}

.select {
  & + .select {
    margin-left: 5%;
  }
}

.select-day-item {
  width: auto;
  min-width: 75px;
}

.select-month-item {
  width: auto;
  min-width: 100px;
}

.select-year-item {
  width: auto;
  min-width: 80px;
}

.error-message {
  bottom: -2px;
}

.reset {
  height: rem(10px);
  width: rem(10px);
  margin-bottom: 10px;
}

.reset-disabled {
  color: $color-dark-gray;
}

.icon-close {
  display: block;
}

.block-theme {
  margin-bottom: 20px;

  .date-wrapper {
    margin-right: 0;
    justify-content: space-between;
    gap: 5%;
  }

  .select {
    width: 100%;

    & + .select {
      margin-left: auto;
    }
  }

  .error-message {
    bottom: -22px;
  }
}

.label-error {
  color: $color-carissma;
}
