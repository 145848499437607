@use 'common/_shared.scss' as *;

.root {
  position: relative;
  margin-bottom: 40px;
  padding-top: 20px;

  @include media('>desktop') {
    padding-top: 65px;
  }
}

.wishlist-wrapper {
  @include main-sans-serif();
}

.title {
  @include main-serif(700);
  font-size: 28px;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;

  @include media('>desktop') {
    font-size: 36px;
  }

  &:after {
    content: '';
    height: 2px;
    width: 65px;
    background-color: $color-black;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.show-more-wrap {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}
