@use 'common/_shared.scss' as *;

$line-height: calc(20 / 12);
$lines: 4;
$lines-short-text: 3;

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  font-weight: $book;
  line-height: calc(20 / 12);

  .short-style & {
    flex: 1 1 auto;
  }

  @include media('<desktop') {
    padding: 15px 15px 20px;
    flex: 1 1 auto;

    .long-style & {
      flex: 1 0 auto;
    }
  }
}

.description-trim {
  position: relative;
  overflow: hidden;
  max-height: ($lines - 1) * $line-height * 1em;
  line-height: $line-height;

  @include media('<desktop') {
    max-height: ($lines-short-text - 1) * $line-height * 1em;
  }

  &.text-short {
    max-height: ($lines-short-text - 1) * $line-height * 1em;
  }

  // See #290564
  // To show ellipsis only if more then 4 lines.
  &::after {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 2em;
    content: '…';
    text-align: right;
    font-weight: inherit;
    line-height: $line-height;
    background: linear-gradient(
      to right,
      rgba($color-snow, 0),
      rgba($color-snow, 1) 50%
    );
  }

  .inner-wrap {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-height: $lines * $line-height * 1em;
    background: $color-snow;

    // See #290564
    // For the ellipsis.
    &::after {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 2em;
      height: $line-height * 1em;
      content: '';
      font-weight: inherit;
      line-height: $line-height;
      background: linear-gradient(
        to right,
        rgba($color-snow, 0),
        rgba($color-snow, 1) 50%
      );
      z-index: 2;
    }

    a {
      text-decoration: none;
      color: $color-tallow;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.description {
  margin-top: 45px;
  text-align: justify;
  @include break-word-custom();

  .short-style & {
    margin-top: 10px;
  }

  @include media('<desktop') {
    margin-top: 10px;
  }
}

.bottom-text {
  margin-top: 10px;
  text-transform: uppercase;
  font-weight: $book;

  @include media('<desktop') {
    text-align: center;
  }
}
