@use 'common/_shared.scss' as *;

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 2;
  padding: 15px;
  font-weight: $medium;

  &.rounded {
    width: auto;
    min-width: auto;
    padding: 13px 40px;
    margin: 0 auto;
  }

  &:disabled {
    border: 1px solid $color-pink-swan !important;
    background-color: $color-pink-swan !important;
    color: white !important;
  }
}
