@use 'common/_shared.scss' as *;

.root {
  position: relative;
  height: 100%;
  padding-top: 20px;

  @include media('>desktop') {
    padding-top: 50px;
  }
}

.title {
  pointer-events: none;
  padding: 3px 0 10px 34px;

  @include media('>=tablet') {
    padding: 3px 0 10px 50px;
  }

  @include media('>=desktop') {
    padding: 3px 46px 0 46px;
  }
}
