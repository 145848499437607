@use 'common/_shared.scss' as *;

.root {
  position: relative;
  height: 100%;
  padding-top: 20px;

  @include media('>desktop') {
    padding-top: 50px;
  }
}

.content-wrapper {
  position: relative;
  width: 100%;
  padding: 30px 20px 30px;
  background: white;
  @include white-box-shadow();
}

.title {
  pointer-events: none;
  padding: 3px 0 10px 34px;

  @include media('>=tablet') {
    padding: 3px 0 10px 60px;
  }

  @include media('>=desktop') {
    padding: 15px 50px 10px 112px;
  }
}

.form {
  @include media('>=desktop') {
    padding: 0 50px 42px 112px;
  }
}

.switcher {
  display: flex;
}

.radios-wrapper {
  margin-bottom: 20px;
}

.field-wrap {
  position: relative;
  display: flex;
  align-items: center;
}

.modifiers {
  position: relative;
  display: inline-block;
  margin-left: 18px;
  margin-top: 2px;
}

.select-item {
  @include media('<=tablet') {
    min-width: 68px;

    & + .select-item:nth-child(1) {
      min-width: 70px;
    }

    & + .select-item:nth-child(3) {
      min-width: 64px;
    }

    & + .select-item {
      margin-left: 0;
    }
  }
}

.form-buttons {
  margin-top: 40px;
}

.label {
  text-transform: uppercase;
  font-size: rem(10px);
  @include main-sans-serif($bold);
  color: $color-grey;
}

.phone-input-container {
  .phone-input-wrapper {
    height: 30px;
    margin-bottom: 15px;
    background: transparent;
    border-color: transparent transparent #e6e5e2 transparent;
  }

  .phone-input {
    &.error {
      color: red;
    }
  }
}
