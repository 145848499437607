@use 'common/_shared.scss' as *;

.root {
  position: relative;
}

.icon {
  color: $color-grey;
  width: 16px;
  height: 16px;
}
