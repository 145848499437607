@use 'common/_shared.scss' as *;

$brcolor: $color-narvik;

.root {
  position: relative;
  min-height: 160px;
  text-align: center;

  .book-cost {
    color: $color-zambezi;
    margin-bottom: 35px;
    font-size: rem(11px);

    span {
      @include main-sans-serif($medium);
      font-size: rem(23px);
    }
  }
}

.parent-title {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid $brcolor;

  &:first-child {
    margin-top: 10px;
  }
}

.tag-list {
  width: 100%;
  display: block;
  padding: 15px;
  border-bottom: 1px solid $brcolor;
  text-align: center;
}

.price-list-title {
  text-align: left;
}

.book-params-list {
  position: relative;
  display: flex;
  justify-content: stretch;
  border-bottom: 1px solid $brcolor;

  .list-item {
    display: flex;
    flex-grow: 1;
    width: 67%;

    &:first-child {
      width: 33%;
    }
  }

  .list-item-inner {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    min-height: 120px;
    background-color: white;
    border-top: 1px solid $brcolor;
    border-bottom: 1px solid $brcolor;

    &::before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: -9px;
      left: calc(50% - 9px);
      background: white;
      transform: rotate(45deg);
      border-top: 1px solid $brcolor;
      border-left: 1px solid $brcolor;
      transition: left 150ms ease-in;
    }
  }

  .first-active::before {
    left: calc(16.5% - 9px);
  }

  .second-active::before {
    left: calc(65% - 9px);
  }

  .day-time-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 80px;
    margin: 20px 60px;
  }

  :global {
    .DayPicker {
      margin: 0 auto;
    }
  }
}

.book-cta {
  margin-bottom: 10px;
  font-size: 17px;
  padding: 0 30px 20px;
}

.book-cta-btn {
  display: block;
  width: 100%;
  padding: 19px 32px;
  line-height: 1;
  border-radius: 5px;
  font-weight: 500;
}
