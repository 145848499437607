@use 'common/_shared.scss' as *;

.logo-wrapper {
  width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: top;
  color: white;
  fill: currentColor;

  svg {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
}

.lg {
  max-width: 180px;
}

.md {
  max-width: 120px;
}

.black {
  color: black;
}
