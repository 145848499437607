@use 'common/_shared.scss' as *;

.root {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 36px;
  height: 36px;
  padding: 5px;
  z-index: 1;

  @include hover {
    .icon {
      path {
        fill: $color-tallow;
      }
    }
  }
}

.icon {
  path {
    transition: fill 0.2s;
    fill: rgba(black, 0);
    stroke: white;
  }

  &.active {
    path {
      fill: $color-tallow;
    }
  }
}
