@use 'common/_shared.scss' as *;

.root {
  position: relative;
  display: flex;
  justify-content: justify;
  margin: 10px 0;
  padding: 10px;
  color: $color-suva-grey;
  font-size: rem(11px);
  line-height: 1.35;

  a {
    text-decoration: none;
    color: $color-tallow;
  }
}

.dark-mode {
  padding: 30px 50px;
  background-color: $color-white-smoke;
  color: $color-zambezi;

  .icon {
    color: $color-suva-grey;
  }
}

.success-mode {
  padding: 30px 50px;
  background-color: $color-white-ice;
  border: 1px solid $color-vista-blue;
  color: $color-dark-green;
  font-size: rem(14px);
  line-height: 1.75;

  .icon {
    color: inherit;
  }
}

.error-mode {
  padding: 30px 50px;
  background-color: $color-pale-pink;
  border: 1px solid $color-blossom;
  color: $color-burnt-crimson;
  font-size: rem(14px);
  line-height: 1.75;

  .icon {
    color: inherit;
  }
}

.with-icon {
  padding: 10px;
}

.icon {
  display: block;
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  margin-right: 20px;
  color: $color-silver;
}
