@use 'common/_shared.scss' as *;

$custom-table-breakpoint: 1140px;

.root {
  width: 100%;
}

.advantages-table {
  display: block;
  width: 100%;
  @include white-box-shadow();
}

%Advantages-cell {
  padding: 25px;
  vertical-align: middle;
  letter-spacing: rem(0.5px);
}

.advantages-cell {
  @extend %Advantages-cell;
  width: 18%;
  border-right: 1px solid $color-narvik;
  text-align: center;
  font-size: rem(12px);
  font-weight: $medium;
  color: $color-zambezi;

  &:last-child {
    border-right: none;
  }

  @include media('<#{$custom-table-breakpoint}') {
    padding: 15px;
  }
}

.advantages-first-cell {
  @extend %Advantages-cell;
  width: 28%;
  border-right: none;
  border-collapse: separate;
  @include white-box-shadow();
  font-size: rem(14px);
  background: white;
}

.advantages-row {
  border-top: 1px solid $color-narvik;
}

.advantages-sub-header {
  background: $color-white-smoke;
}

.advantages-sub-header-cell {
  padding: 14px 25px;
  vertical-align: middle;
  font-size: rem(12px);
  letter-spacing: rem(0.5px);
  color: $color-tallow;
  @include white-box-shadow();
}

.icon-check {
  width: rem(22px);
  height: rem(22px);
}

.root-content {
  position: relative;
  display: flex;
  flex-direction: row;
}

.transition-enter {
  opacity: 0.01;
  transform: translateY(-0.2%);
}

.transition-enter-active {
  opacity: 0.5;
  transform: translateY(0%);
  transition: all 200ms ease-in-out;
}

.transition-done-enter {
  opacity: 1;
}

.transition-exit {
  opacity: 0.5;
}

.transition-exit-active {
  opacity: 0.01;
  transform: translateY(-0.2%);
  transition: all 200ms ease-in-out;
}
