@use 'common/_shared.scss' as *;

$brcolor: $color-narvik;

.root {
  border-top: 1px solid $brcolor;
  padding: 35px 12px 30px;
  text-align: center;

  @include media('<tablet') {
    margin-top: auto;
  }
}

.title {
  margin-bottom: 15px;
  font-size: rem(15px);
  font-weight: 500;
  text-transform: uppercase;
  color: $color-tallow;
}

.phone-value {
  font-size: rem(15px);
  text-decoration: underline;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}
