@use 'common/_shared.scss' as *;

.root {
  position: relative;
  height: 100%;
}

.sticky-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  pointer-events: none;
}

.ie-sticky-content {
  height: 100%;
}

.sticky-top-offset {
  height: 500px;
}

.universe-sticky-block-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 105px; // header offset
}

.universe-sticky-block {
  pointer-events: all;
}

.sticky-content {
  position: sticky;
  top: 70px;
}
