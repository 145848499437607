@use 'common/_shared.scss' as *;

.wrapper {
  position: relative;
  display: flex;
  justify-content: stretch;
  width: 100%;
  margin: 0 0 30px;
}

.label {
  margin-bottom: 5px;
  display: inline-block;
  pointer-events: none;
  text-transform: uppercase;
  font-size: rem(10px);
  @include main-sans-serif($bold);
  color: $color-grey;
}

.error {
  position: absolute;
  left: 0;
  bottom: -3px;
  font-size: rem(12px);
  color: $color-carissma;
  line-height: 10px;
  height: 20px;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.label-error {
  color: $color-carissma;
}
