@use 'common/_shared.scss' as *;

.root {
  position: relative;
  margin: 15px 0 20px;
  font-size: rem(11px);
}

.input {
  position: relative;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid $color-narvik;
  border-radius: 0;
  padding: 15px 0 0;
  font-size: rem(12px);
  height: rem(31px);
  @include main-sans-serif($bold);
  background-color: transparent;

  &:-webkit-autofill {
    :global {
      animation-name: onAutoFillStart;
    }
  }
}

input.input::-webkit-credentials-auto-fill-button {
  position: absolute;
  top: 10px;
  right: 15px;
}

input.input::-ms-reveal,
input.input::-ms-clear {
  display: none;
}

.label {
  position: absolute;
  left: 0;
  top: 9px;
  pointer-events: none;
  text-transform: uppercase;
  font-size: rem(11px);
  @include main-sans-serif($bold);
  color: $color-grey;
  transition: 0.2s ease all;

  &.transformed {
    top: -4px;
    font-size: 10px;
  }
}

.input:focus ~ label {
  top: -4px;
  font-size: 10px;
}

.block-theme {
  background: $block-theme-input-background;
  margin: 0 0 20px;

  .input {
    border: 1px solid $block-theme-input-border;
    border-radius: 10px;
    padding: $block-theme-input-padding;
    height: auto;
    font-size: rem(14px);
  }

  .button-show {
    display: none;
  }

  .input ~ .label {
    left: $block-theme-input-padding;
    top: 50%;
    transform: translateY(-50%);
  }

  .input:focus ~ label,
  .label.transformed {
    top: $block-theme-input-label-active-indent;
    left: $block-theme-input-label-active-indent;
    transform: translateY(0);
  }

  &.error-wrapper {
    border-color: $color-carissma;

    .label {
      color: $color-carissma;
    }
  }
}
