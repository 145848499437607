@use 'common/_shared.scss' as *;

.root {
  margin-top: 30px;
}

.user-log {
  display: block;
  text-align: center;
  font-size: rem(12px);
}

.table-header {
  padding: 10px;
  vertical-align: middle;
  border: 1px solid $color-merino;
  font-weight: $bold;
}

.row {
  transition: background-color 0.2s;

  @include hover() {
    background: $color-whisper;
  }
}

.cell {
  padding: 10px;
  vertical-align: middle;
  border: 1px solid $color-merino;
}

.transition-enter {
  opacity: 0.01;
  height: 5vh;
}

.transition-enter-active {
  opacity: 0.5;
  height: 25vh;
  transition: all 200ms ease-in-out;
}

.transition-done-enter {
  opacity: 1;
  height: 50vh;
}

.transition-exit {
  opacity: 0.5;
  height: 25vh;
}

.transition-exit-active {
  opacity: 0.01;
  height: 0;
  transition: all 200ms ease-in-out;
}
