@use 'common/_shared.scss' as *;

$brcolor: $color-narvik;

%arrow-top {
  content: '';
  width: 16px;
  height: 16px;
  background-color: white;
  position: absolute;
  top: -10px;
  transform: rotate(45deg);
  box-sizing: content-box;
  border-style: solid;
  border-color: $brcolor;
  border-width: 1px 0 0 1px;
}

.book-params {
  position: relative;
  z-index: 1;
}

.book-params-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  border-bottom: 1px solid $brcolor;

  .list-item {
    display: flex;
    width: 100%;

    &:first-child {
      border-bottom: 1px solid $brcolor;
    }

    &:nth-child(2) {
      flex-wrap: wrap;
    }

    &:last-child {
      flex-direction: column;
    }
  }

  .list-item-inner {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    min-height: 242px;
    background-color: white;
    border-top: 1px solid $brcolor;
    border-bottom: 1px solid $brcolor;

    &::before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: -9px;
      left: calc(50% - 9px);
      background: white;
      transform: rotate(45deg);
      border-top: 1px solid $brcolor;
      border-left: 1px solid $brcolor;
      transition: left 150ms ease-in;
    }
  }

  .first-active::before {
    left: calc(16.5% - 9px);
  }

  .second-active::before {
    left: calc(50% - 9px);
  }

  .third-active::before {
    left: calc(83.5% - 9px);
  }

  :global {
    .DayPicker {
      margin: 0 auto;
    }
  }

  .list-item-inner-dates {
    min-width: 280px;
    position: absolute;
    background: white;
    border-radius: 4px;
    box-shadow: 0 2px 15px 0 $color-black-pearl;
    top: 90px;

    &::before {
      @extend %arrow-top;
    }

    @include media('<desktop') {
      left: 0;
      height: 320px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-top: 1px solid $brcolor;
      z-index: 1;

      &::before {
        transition: left 0.5s;
        left: calc(50% - 10px);
      }
    }

    @include media('>=desktop') {
      animation: appear 0.25s ease;
      left: 0;
      width: 231%;
      transform: translateX(-50%);
      border: 1px solid $brcolor;
      z-index: 1;

      &::before {
        left: calc(72% - 10px);
      }
    }
  }
}

.filter-button {
  border-right: 0;
}

.calendar {
  margin-bottom: 20px;

  @include media('<desktop') {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 0;
    border-radius: 4px;
  }
}

.calendar-decor {
  position: relative;

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    width: 45%;
    max-width: 352px;
    height: 1px;
    background-color: $color-tallow;
    transform: rotate(0);
  }

  &::before {
    left: 20px;
  }

  &::after {
    right: 20px;
  }
}

.calendar-notification {
  padding: 10px 10px 0;
  text-align: center;
  color: $color-fire-brick;
  font-size: rem(11px);

  @include media('<desktop') {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    padding: 5px 20px 3px;
    background-color: white;
  }
}

.calendar-loader {
  position: absolute;
  top: 20px;
  left: 50%;
  height: 30px;
  width: 30px;
  transform: translateX(-50%);
  z-index: 1;

  @include media('<desktop') {
    top: 3px;
    height: 20px;
    width: 20px;
  }
}

.calendar-decor-inner {
  position: relative;
  margin-bottom: 20px;
  left: 50%;
  width: 9px;
  height: 9px;
  background-color: $color-tallow;
  transform: rotate(45deg) translateX(-50%);
}

.calendar-selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 61px;
  padding: 0 20px 20px;
  text-transform: none;
  text-align: right;
}

.calendar-instructions {
  @include media('<desktop') {
    font-size: rem(12px);
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 10px 15px;
  }
}

.checkout-only-icon {
  color: $color-fire-brick;
  font-size: rem(20px);
  line-height: rem(11px);
  text-decoration: none;
  position: relative;
  top: 5px;
}

.dropdown-label {
  width: 100%;
  padding: 20px 20px 0;
  text-align: center;
  text-transform: uppercase;
  font-size: rem(9px);
  color: $color-dove-grey;
}

.book-cost {
  color: $color-zambezi;
  margin-bottom: 35px;
  font-size: rem(11px);

  span {
    @include main-sans-serif($medium);
    font-size: rem(23px);
  }
}

.book-noresults {
  display: block;
  margin-bottom: 15px;
  color: $color-zambezi;
  font-size: rem(14px);
}

.book-cta {
  position: relative;
  min-height: 150px;
  padding: 30px 25px;
  margin-bottom: 10px;
  text-align: center;
  font-size: rem(17px);

  a,
  button {
    width: 100%;
    margin-top: 20px;
    padding: 13px 32px;
    line-height: 1;
  }

  small {
    display: block;
    font-size: rem(11px);
    font-weight: 400;
  }
}
