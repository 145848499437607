@use 'common/_shared.scss' as *;

.modal-share-container {
  background-color: white;
  border-radius: 10px;

  @include media('<tablet') {
    margin-left: 20px;
    margin-right: 20px;
  }

  @include media('>=tablet') {
    width: 700px;
  }
}

.modal-share-header {
  position: relative;
  padding: 40px 30px 0;

  @include media('>=tablet') {
    padding: 60px 30px 0;
  }

  .title {
    margin-bottom: 30px;
    @include main-serif();
    font-size: rem(24px);
    line-height: rem(26px);
    text-align: center;

    @include media('>=desktop') {
      text-align: left;
      font-size: rem(32px);
      line-height: rem(38px);
    }
  }

  .button-close {
    position: absolute;
    right: 0;
    top: 10px;
    padding: 8px;
    height: 32px;
    width: 33px;

    @include media('>=desktop') {
      right: -20px;
      top: 20px;
      padding: 4px;
      height: 22px;
      width: 22px;
    }
  }
}

.modal-share-grid {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  flex-wrap: wrap;
  padding: 0 10px 60px;

  @include media('>=tablet') {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 0 30px 60px;
  }
}

.modal-share-grid-item {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 50%;
  padding: 15px 30px;
  border: 1px solid $color-silver;
  border-radius: 10px;
  transition: background-color 0.2s ease, color 0.2s ease;
  font-size: rem(14px);

  @include hover() {
    background-color: $color-tallow;
    color: white;

    .social-icon path {
      fill: white;
    }

    .check-icon {
      border-color: white;

      path {
        fill: white;
      }
    }
  }

  @include media('>=tablet') {
    padding: 20px 30px;
    font-size: rem(16px);
  }

  .social-icon {
    display: inline-block;
    width: 28px;
    height: 28px;
    margin-right: 20px;
    border-radius: 15%;

    @include media('>=tablet') {
      width: 36px;
      height: 36px;
      margin-right: 30px;
    }

    path {
      fill: $color-tallow;
      transition: fill 0.2s ease;
    }
  }

  .social-label {
    word-break: break-word;
  }

  .check-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 18px;
    height: 18px;
    padding: 3px 4px;
    border: 1px dashed $color-limeade;
    border-radius: 100%;

    path {
      fill: $color-limeade;
    }
  }
}

.modal-share-footer {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 10px 10px;

  .button-unpublish {
    padding: 7px;
    font-size: 12px;
  }
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background: rgba(white, 0.65);
  border-radius: 10px;
  overflow: hidden;

  @include media('<tablet') {
    left: 20px;
    right: 20px;
  }
}
