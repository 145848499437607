@use 'common/_shared.scss' as *;

$custom-table-breakpoint: 1140px;
$box-shadow-color: rgba(37, 110, 144, 0.07);

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-right: 38px;

  &::before {
    display: block;
    position: absolute;
    top: 14px;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    z-index: 0;
    background-color: white;
    box-shadow: 0 0 25px 0 $box-shadow-color;
  }

  @include media('<#{$custom-table-breakpoint}') {
    padding-right: 0;
  }
}

.header-widen {
  padding-right: 0;
}

.header-compact {
  padding: 15px;
  background-color: white;
  box-shadow: 0 0 25px 0 $box-shadow-color;
}

.description {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex: 0 1 auto;
  width: 28%;
  margin-top: 14px;
  padding: 25px;
  vertical-align: middle;
  text-transform: uppercase;
  font-size: rem(10px);
  transition: all 0.3s ease;

  @include media('<=desktop') {
    width: 100%;
  }
}

.description-compact {
  margin: 0 auto;
}

.triangle-icon-down {
  display: inline-block;
  margin-left: 5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: rem(4px) rem(2px) 0 rem(2px);
  border-color: black transparent transparent transparent;
  transition: all 0.3s ease;
}

.triangle-icon-up {
  transform: rotate(180deg);
}

.card-list {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .card-item-wrapper {
    & + .card-item-wrapper {
      @include media('<#{$custom-table-breakpoint}') {
        margin-left: 24px;
      }
    }
  }
}

.card-list-widen {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex: 1 0 auto;
  justify-content: space-around;
  transition: all 0.3s ease;

  .card-item-wrapper {
    & + .card-item-wrapper {
      @include media('<#{$custom-table-breakpoint}') {
        margin-left: 0;
      }
    }
  }
}

.card-item-wrapper {
  position: relative;
  display: inline-block;
  text-align: center;

  & + .card-item-wrapper {
    margin-left: 24px;
  }
}

.card-item {
  vertical-align: top;
  display: inline-block;
  border-radius: 2px;
  box-shadow: 4px -1px 7px;
  overflow: hidden;

  img {
    display: block;
  }
}

.card-status {
  padding: 13px 25px;
  font-size: rem(14px);
  font-weight: $medium;
  letter-spacing: rem(0.5px);
}

.card-status-member {
  color: $color-main-membre-card;
}

.card-status-silver {
  color: $color-main-silver-card;
}

.card-status-gold {
  color: $color-main-gold-card;
}

.card-status-platinum {
  color: $color-main-platinum-card;
}

.button-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  transition: all 0.3s ease;
  margin: 0 -5px 0;
}

.button-switch-wrapper {
  padding: 20px 5px 0;
  width: 50%;
  text-align: center;

  @include media('>=tablet') {
    width: 25%;
  }
}

.button-switch {
  border-bottom: 2px solid transparent;
  font-size: rem(14px);

  &.active {
    border-bottom: 2px solid black;
    font-weight: $bold;
  }
}
