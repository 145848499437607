@use 'common/_shared.scss' as *;

// Base styles
*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  position: relative;
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}

body {
  // Let's stick to the design:
  -webkit-font-smoothing: antialiased;
  font-size: 100%;
  line-height: $main-line-height;
  background: white;
  min-width: 320px;
  height: 100%;
  position: relative;
  @include main-sans-serif();

  // Define breakpoints for JS use
  @include define-breakpoint('desktop');

  @include media('<desktop') {
    @include define-breakpoint('tablet');
  }

  @include media('<tablet') {
    @include define-breakpoint('mobile');
  }

  @include color-schema(text-color) using ($color) {
    color: $color;
  }
}

button {
  @include main-sans-serif();
}

h1 {
  @include theme-mice {
    font-weight: 700;
  }
}

h1,
h2,
h3,
h4,
h5 {
  @include main-serif();
  @include theme-mice {
    font-family: $mice-secondary-sans-serif;
  }
}
