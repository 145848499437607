@use 'common/_shared.scss' as *;

.root {
  border-top: 1px solid $color-zambezi;
  font-size: rem(9px);
  @include color-schema(footer-sec-menu-color) using ($color) {
    color: $color;
  }

  @include theme-mice {
    border-top-color: $color-blue-whale;
  }

  @include media('>=tablet') {
    grid-area: SecMenu;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 8px 0;
    text-align: center;
  }

  @include media('>=desktop') {
    margin-top: 20px;
  }

  a,
  button {
    margin-bottom: 5px;
    padding: 5px 0;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.2s ease;
    color: inherit;

    @include color-schema(highlight-color) using ($color) {
      @include hover() {
        color: tint($color, 40%);
      }
    }
  }

  .sec-menu-list {
    padding: 5px 0 0;
    list-style: none;

    @include media('>=tablet') {
      line-height: 2;
    }

    @include media('>=desktop') {
      margin-top: 0;
      padding-top: 0;
    }
  }
}

.sec-menu-list-item {
  display: block;
  margin-top: 7px;
  margin-bottom: 15px;
  color: inherit;

  &:last-child a,
  &:last-child button {
    margin-right: 0;
    padding-right: 0;
  }

  @include media('>=tablet') {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;

    &::after {
      content: '|';
      margin-right: 10px;
      margin-left: 10px;
      display: inline;
    }

    &:last-child::after {
      display: none;
    }
  }
}
