@use 'common/_shared' as *;

.root {
  position: relative;
  display: inline-block;
  padding-top: 1px;
  margin-right: 10px;
}

.nav-link {
  display: inline-block;
  margin-left: auto;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1.5;
  color: $color-dove-grey;
  border: 1px solid transparent;
  transition: color 0.1s ease, border-color 0.1s ease;

  @include hover() {
    text-decoration: none;
    color: $color-tallow;
    border: 1px solid $color-tallow;
  }
}

.icon {
  width: 24px;
  height: 24px;
}
