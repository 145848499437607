@use 'common/_shared.scss' as *;

$border-color: $color-white-smoke;

.item {
  background: #fff;
  white-space: normal;
  text-align: center;
  padding: 10px;
  min-width: 73px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media('<desktop') {
    min-height: 58px;
    justify-content: center;
  }

  @include media('>=desktop') {
    justify-content: space-between;
  }

  &.arrow {
    &:after {
      right: 15px;
    }
  }
}

%arrow {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -1px;
    @include triangle(bottom, 3px, $size-step: 1);
  }
}

.arrow-down {
  @extend %arrow;
}

.arrow-up {
  @extend %arrow;

  &:after {
    border-bottom: 3px solid currentColor;
    border-top: none;
  }
}

.top,
.bottom {
  width: 100%;
  font-size: rem(9px);
  height: 1.2rem;
  display: flex;
  align-items: center;
}

.middle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  opacity: 0.6;
  transition: opacity 0.15s ease;

  &.arrow {
    padding-right: 15px;
  }
}

.is-selected {
  opacity: 1;
}

.item-label {
  text-transform: uppercase;
  font-size: rem(10px);
  color: $color-dove-grey;
  width: 100%;
  text-align: center;
}

%item-value {
  color: $color-black-pearl;
  text-transform: uppercase;
}

.item-value-num {
  @extend %item-value;
  font-size: rem(26px);
}

.item-value-text {
  @extend %item-value;
  font-size: rem(10px);
  font-weight: $medium;
  width: 100%;
}

.item-value-icon {
  @extend %item-value;
  width: 25px;
  height: 25px;
}

.item-dates {
  border-right: 0;
}

@include media('<desktop') {
  .item-persons {
    border-left: 1px solid $border-color;
    border-top: 3px solid $color-white;
  }
  .item-dates + .item-dates {
    border-left: 1px solid $border-color;
  }

  .item-dates,
  .item-persons {
    .item-value-num {
      transition: color 0.15s;
    }

    &.item-active {
      .item-value-num {
        color: $color-tallow;
      }
    }
  }
}
