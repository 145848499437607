@use 'common/_shared.scss' as *;

.root {
  position: relative;
}

.message-wrapper {
  margin: 0 0 20px;
}

.content-wrapper {
  margin-top: 30px;
}

.content-text {
  @include break-word-custom();
  @include hyphens();
  white-space: pre-wrap;
}
