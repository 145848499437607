@use 'common/_shared.scss' as *;

.root {
  position: absolute;
  top: 34px;
  left: 20px;
  color: black;
  transition: color 0.2s;

  @include hover() {
    color: $color-tallow;
  }

  @include media('>=tablet') {
    top: 30px;
    left: 20px;
  }

  @include media('>=desktop') {
    top: 42px;
    left: 50px;
  }
}

.icon-arrow-back {
  width: 24px;
  height: 24px;

  @include media('>=tablet') {
    width: 32px;
    height: 32px;
  }
}
