@use 'sass:math';
@use 'sass:color';
@use 'common/_shared.scss' as *;

$card-image-container-width: 152px + 40px + 40px;
$padding-common: 27px;
$padding-common-mobile: 20px;

.root {
  margin-top: 60px;
  margin-bottom: 35px;
}

.inner {
  @include white-box-shadow();
}

.loyalty-card-info-block {
  position: relative;
  background-color: white;
  padding: $padding-common-mobile;

  @include media('>=tablet') {
    padding: $padding-common $padding-common 57px 0;
  }
}

.card-image-container {
  padding: 0 40px;
  position: relative;

  @include media('<tablet') {
    width: 100%;
    text-align: center;
  }

  @include media('>=tablet') {
    position: absolute;
    left: 0;
  }
}

.card-image {
  display: inline-block;
  position: relative;
  box-shadow: 0 15px 13px -7px;
  top: -(30px + $padding-common-mobile);

  @include media('>=tablet') {
    top: -(30px + $padding-common);
  }

  img {
    display: block;
  }
}

.description {
  @include main-serif();
  font-size: rem(26px);

  @include media('<tablet') {
    padding: 0;
    text-align: center;

    & + .description {
      padding-top: 20px;
    }
  }

  @include media('>=tablet') {
    font-size: rem(36px);
    padding-left: $card-image-container-width;
  }
}

.description-top {
  @include media('<tablet') {
    margin-top: -$padding-common-mobile;
    text-align: center;
  }
}

.description-bottom {
  @include media('<tablet') {
    width: 100%;
    min-width: 100%;
  }
}
.colored-title {
  display: inline-block;
  text-transform: capitalize;
}

.enrollment-date,
.mymc-id {
  display: flex;
  align-items: baseline;
  margin-top: 15px;
  margin-bottom: 20px;

  @include media('<tablet') {
    justify-content: center;
  }
}

.enrollment-date-label,
.mymc-id-label {
  color: $color-nobel;
  font-size: rem(10px);
  padding-right: 13px;
  display: inline-block;
  @include main-sans-serif();

  @include media('>=tablet') {
    min-width: 90px;
  }
}

.enrollment-date-value,
.mymc-id-value {
  display: inline-block;
  font-size: rem(14px);
  @include main-sans-serif($bold);
}

.points-wrapper {
  margin-top: -20px;
  margin-left: -42px;
}

.points-box,
.points-expiration-box {
  display: inline-block;
  background-color: $color-white-smoke;
  padding: 10px 10px 8px 10px;
  min-width: 152px;
  min-height: 62px;
  margin-top: 20px;
  margin-left: 42px;
  vertical-align: top;
  border-radius: 4px;
  text-align: center;
}

.points-box-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: rem(12px);
  line-height: rem(14px);
  padding-bottom: 12px;
}

.points-box-title-with-icon {
  padding-bottom: 7px;
}

.points-expiration-box {
  min-width: min(calc(100% - 40px), calc(2 * 152px + 42px));
  max-width: calc(2 * 152px + 42px);
  padding: 20px 40px;
  text-align: left;
  line-height: 1.2;
  font-size: rem(14px);

  .reward-points,
  .expiring-points {
    font-weight: 700;
    font-size: rem(22px);
  }
  .reward-points,
  .expiring-points {
    color: $color-tallow;
  }
  .expiring-date {
    display: inline-block;
    font-weight: 700;
  }
}

.points-value {
  color: $color-winter-hazel;
  @include main-sans-serif($medium);
  font-size: rem(24px);
  line-height: rem(15px);
}

.points-icon {
  height: 19px;
  width: 19px;
  margin-left: 8px;
}

.text {
  padding: 35px 30px 0;
  font-size: rem(10px);
  color: $color-nobel;

  h3,
  p,
  ul,
  ol {
    margin: 0.5em 0;
  }

  h3 {
    @include main-sans-serif();
    font-size: rem(11px);
    line-height: math.div(26, 29);
  }

  p {
    font-size: rem(10px);
    line-height: 1.5;
  }

  a {
    text-decoration: none;
    font-size: rem(10px);
    color: $color-tallow;
    transition: color 0.2s;

    @include hover() {
      color: color.adjust($color-tallow, $lightness: 15%);
    }
  }

  em {
    font-style: italic;
  }

  ul,
  ol {
    padding: 0 40px;
    text-align: left;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }
}
