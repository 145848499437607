@use 'common/_shared.scss' as *;

.root {
  padding: 11px;
  width: 100%;
  color: $color-black-pearl;
}

.is-open {
  .line-container {
    width: 18px;
  }

  .line {
    background: transparent;

    &::before {
      top: 0;
      transform: rotate(45deg);
    }

    &::after {
      bottom: 0;
      transform: rotate(-45deg);
    }
  }
}

.line-container {
  width: 16px;
  height: 16px;
  position: relative;
  color: currentColor;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 6px;
}

.line {
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  margin-top: -1px;
  top: 50%;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: currentColor;
    left: 0;
    transition: transform 0.15s;
  }

  &:before {
    top: 5px;
  }

  &:after {
    bottom: 5px;
  }
}

.text {
  text-transform: uppercase;
  font-size: rem(8px);
  font-weight: $book;
  position: relative;
}
