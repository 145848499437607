@use 'common/_shared.scss' as *;
@use 'common/_wysiwyg.scss' as *;

.root {
  position: relative;
}

.message-wrapper {
  margin: 0 0 20px;

  & + .message-wrapper {
    margin-top: 30px;
  }

  @include wysiwyg-myacc();
}

.content-wrapper {
  margin-top: 30px;
  border-bottom: 2px solid $color-narvik;
}

.content-text {
  @include break-word-custom();
  @include hyphens();
  white-space: pre-wrap;
}

.buttons-wrapper {
  display: block;
  text-align: center;
  width: 100%;
  margin: 30px 0;
}

.confirmation-message-wrapper {
  text-align: center;
  margin: 10px 0;
}

.confirmation-buttons-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -20px;
}

.confirmation-button-item {
  padding: 20px 20px 0;
}

.confirmation-text-wrapper {
  display: flex;
  margin: 10px 0;
  justify-content: center;
}

.confirmation-text {
  font-size: rem(16px);
  color: $color-grey;
  max-width: 100%;
  width: 500px;
}
