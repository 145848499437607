@use 'common/_shared.scss' as *;

.root {
  text-decoration: none;
  color: inherit;
}

.description-room-push {
  margin-top: 30px;
  font-size: rem(12px);
  font-weight: $book;
  color: $color-zambezi;
  text-align: justify;
  line-height: calc(20 / 12);
  max-height: rem(79px);
  overflow: hidden;
  @include break-word-custom();

  @include media('<desktop') {
    margin-top: 10px;
  }
}

.booking-button {
  border: 1px solid $color-tallow;
  transition: background-color 0.2s, border-color 0.2s;

  @include hover() {
    border: 1px solid $color-yuma;
  }
}

.cta-wrapper {
  &.no-price.no-booking {
    min-width: 50%;

    @include media('>=tablet') {
      max-width: max-content;
    }
  }
}
