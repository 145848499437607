@use 'sass:color';
@use 'common/_shared.scss' as *;

.root {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column wrap;
  margin: 20px -10px 0;
  font-size: rem(14px);

  @include media('>=tablet') {
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.button-wrapper {
  display: flex;
  width: 100%;

  @include media('>=tablet') {
    &::before {
      content: '';
      display: block;
      width: 100%;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }
}

.delete-button {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 8px 20px;
  font-size: rem(12px);
  font-weight: $bold;
  color: $color-tallow;
  text-align: left;
  transition: color 0.2s;

  @include hover() {
    color: color.adjust($color-tallow, $lightness: 15%);
  }
}

.icon-data {
  display: inline-block;
  width: 20px;
  height: 20px;
}

.icon-wrapper {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
  margin-left: 2px;
  margin-right: 8px;
  width: 22px;
}

.cta-text {
  padding-top: 4px;
}
