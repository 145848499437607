@use 'common/_shared.scss' as *;

$counter-width: 29px;
$counter-width-long: 33px;

.checkbox-wrapper {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  @include media('<tablet') {
    width: calc(100% - #{$counter-width});
  }

  @include media('>=tablet', '<desktop') {
    width: calc(100% - #{$counter-width-long});
  }

  @include media('<desktop') {
    max-width: 293px;
    flex: 1 1;
  }

  @include media('>=desktop') {
    width: 293px;
  }
}

.contact-channel-checkbox {
  padding: 15px 15px 15px 17px;
  border: 1px solid $color-whisper;
  @include main-sans-serif($medium);
  font-size: rem(12px);
  margin: 0;
  display: flex;
  width: 100%;
}

.contact-channel-checkbox-label {
  display: inline-flex;
  width: auto;
}

.contact-channel-checkbox-inactive {
  opacity: 0.5;
  pointer-events: none;
}

.drag-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  right: 0;
  cursor: move;
  padding: 0 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drag-icon {
  width: 9px;
  height: 17px;
}

.checkbox-message {
  font-size: rem(10px);
  display: block;
  color: $color-grey;
  width: 100%;
  padding-top: 5px;
  padding-left: $counter-width;

  @include media('>=tablet') {
    padding-left: $counter-width-long;
  }
}

.checkbox-message-link {
  color: $color-tallow;
}
