@use 'common/_shared.scss' as *;

.root {
  text-transform: none;
}

.highlighted {
  font-size: rem(18px);
  color: $color-tallow;

  @include media('<desktop') {
    font-size: rem(16px);
  }
}

.price {
  font-size: rem(20px);
  color: $color-matterhorn;
}

.total {
  font-size: rem(14px);
}

.total-stay-wrapper {
  display: flex;
  flex-direction: column;
}

.best-price-phrase {
  font-size: rem(12px);
  font-weight: $medium;
  text-transform: uppercase;
  @include letter-tracking(50);
}
