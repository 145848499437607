@use 'common/_shared.scss' as *;

.root {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: auto;
  padding: 0 15px;
  text-align: right;

  &.empty {
    padding: 0 0 10px 0;
  }

  @include media('<desktop') {
    &.empty {
      min-height: rem(32px);
    }

    padding: 10px;
  }

  &.short-style {
    margin-bottom: 0;

    &.empty {
      min-height: rem(52px);
    }

    @include media('<desktop') {
      &.empty {
        min-height: rem(32px);
      }
    }
  }
}

.top-col {
  flex: 1 1 auto;
  text-align: left;
  @include main-sans-serif();
  font-size: rem(16px);
  color: $color-tallow;
  margin-top: 5px;

  & + .top-col {
    padding-left: 10px;
  }
}

.top-col-2 {
  text-align: right;
  text-transform: none;
}
