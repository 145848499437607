@use 'common/_shared.scss' as *;

.form {
  position: relative;
  width: 100%;
  max-width: $auth-sidebar-width;
  margin: auto;
  padding: 0 20px;

  @include media('>=tablet') {
    padding: 0 30px;
  }
}

.form-content {
  position: relative;
  font-size: rem(14px);
}

.input {
  text-transform: none;
}

.select-item {
  @include media('<=tablet') {
    min-width: 80px;

    & + .select-item:nth-child(1) {
      min-width: 70px;
    }

    & + .select-item:nth-child(3) {
      min-width: 64px;
    }

    & + .select-item {
      margin-left: 0;
    }
  }
}

.checkbox-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  padding: 10px 20px 10px 87px;
  background-color: $color-white-smoke;
  font-size: rem(11px);
  color: $color-zambezi;

  a {
    text-transform: none;
    color: $color-tallow;
  }
}

.field-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: $color-whisper;
  width: 67px;
}

.form-switch {
  margin: 0;
}

.text {
  text-align: left;
  font-size: rem(12px);

  a {
    text-decoration: underline;
    color: inherit;

    @include hover() {
      text-decoration: none;
    }
  }
}

.captcha {
  margin: 20px auto;
}
