@use 'common/_shared.scss' as *;

.root {
  text-align: center;
  margin-bottom: 15px;
}

.from-text {
  display: block;
  color: $color-grey;
  margin-bottom: 7px;
}

.price-wrap {
  display: inline-flex;
  align-items: center;
  grid-gap: 7px;
}

.price {
  font-weight: bold;
  font-size: rem(25px);
}

.old-price {
  text-decoration: line-through;
  display: block;
}

.after-text {
  display: block;
}

.points-info {
  font-size: rem(16px);
  color: $color-tallow;
  font-weight: 500;
  display: block;
  margin-top: 7px;
}
