@use 'common/_shared.scss' as *;

.root {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  padding: 20px 20px 0;
  color: $color-black-pearl;
}

.persons-selector-item {
  margin: 30px 20px;
  text-transform: uppercase;
  text-align: center;

  @include media('>=desktop') {
    margin: 30px 70px 30px 20px;
    text-align: right;
  }

  &.centered {
    margin: 30px 20px;
    text-align: center;
  }
}

.select {
  width: 50%;
  position: relative;
  font-size: rem(12px);
  min-height: rem(31px);

  :global(.select__control) {
    width: 100%;
    min-height: 32px;
    border: 1px solid $color-narvik;
    border-radius: 0;
    background-color: transparent;
    @include main-sans-serif($bold);
    text-transform: uppercase;
    color: $color-grey;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;

    &:hover {
      border-color: $color-narvik;
    }
  }

  :global(.select__input) {
    color: transparent;
  }

  :global(.select__menu) {
    top: 0;
    left: 0;
    margin: 0;
    box-shadow: 0 2px 15px 0 rgba(64, 64, 64, 0.2);
    border-radius: 3px;
    overflow: hidden;
  }

  :global(.select__menu-list) {
    padding: 0;
  }

  :global(.select__option) {
    text-transform: uppercase;
    font-size: rem(10px);
  }

  :global(.select__option--is-selected) {
    background-color: $color-tallow;
  }

  :global(.select__option--is-focused) {
    background-color: $color-tallow;
    color: $color-white;
  }

  :global(.select__value-container) {
    cursor: pointer;
  }

  :global(.select__single-value) {
    font-size: rem(10px);
    font-weight: normal;
  }

  :global(.select__indicator-separator) {
    display: none;
  }

  :global(.select__dropdown-indicator) {
    color: $color-dove-grey;
  }
}

.children-age-wrapper {
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.children-age-item {
  width: 50%;
}
