@use 'common/_shared.scss' as *;

.root {
  font-size: rem(9px);
  text-align: center;
  color: rgba($color-mist-grey, 0.5);

  @include theme-mice {
    color: $color-blue;
  }

  @include media('>=tablet') {
    grid-area: AdditionalInfo;
  }
}
