@use 'common/_shared.scss' as *;

// Local variables:
$color-hightligted: $color-tallow;

// Helper styles

.is-highlighted {
  @include color-schema(highlight-color) using ($color) {
    color: $color;
  }
}

.screen-reader {
  position: absolute;
  display: block;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.is-hidden {
  display: none !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.fw-book {
  font-weight: $book !important;
}

.fw-medium {
  font-weight: $medium !important;
}

.fw-bold {
  font-weight: $bold !important;
}

.fw-black {
  font-weight: $black !important;
}

.image-loader-container {
  position: relative;
}

.image-loader {
  @include snake();
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -20px;
}

.clearfix {
  &::after {
    content: '';
    display: block;
    width: 100%;
    clear: both;
  }
}

.block {
  display: block;
}

.shadow-highlighted {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  animation: shadow-highlight 2s 0.3s;
}
