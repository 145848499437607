// Normalize styles site specific
button,
input[type='submit'] {
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
  font: inherit;
  line-height: inherit;
  background: none;
  overflow: visible;
  cursor: pointer;

  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  &[disabled] {
    cursor: default;
  }
}

input[type='text']::-ms-clear {
  display: none;
}

select,
input[type='text'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='tel'] {
  background-color: inherit;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active,
  &:-internal-autofill-previewed,
  &:-internal-autofill-selected {
    transition: background-color 5000s ease-in-out 0s,
      color 5000s ease-in-out 0s;
  }
}

strong {
  font-weight: bold;
}

*:focus {
  [data-focus-source='pointer'] & {
    outline: none;
  }
}
