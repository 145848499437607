@use 'common/_shared.scss' as *;

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.75, 0.75, 0.75);
  }

  20% {
    transform: scale3d(1.75, 1.75, 1.75);
  }

  40% {
    transform: scale3d(1, 1, 1);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.5, 1.5, 1.5);
  }

  80% {
    transform: scale3d(1.2, 1.2, 1.2);
  }

  to {
    opacity: 1;
    transform: scale3d(1.25, 1.25, 1.25);
  }
}

.root {
  position: relative;
  display: inline-block;
}

.form-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: rem(12px) rem(15px);

  .loading {
    color: $color-mist-grey;
  }

  @include media('>=desktop') {
    min-width: 120px;
  }
}

.button-cancel {
  order: 2;
  width: 100%;
  border: 1px solid $color-tallow;
  text-align: center;
  text-decoration: none;
  color: $color-tallow;
  transition: background-color 0.2s;

  @include hover() {
    color: #ffffff;
    background-color: $color-tallow;
  }

  @include media('>=desktop') {
    order: 1;
    width: 40%;
    margin: 0 20px 0 0;
  }
}

.button-save {
  padding: rem(12px) rem(34px);

  &.inverted {
    padding: rem(11px) rem(33px);
  }
}

.button-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: rem(12px);
  font-weight: $book;

  &.with-icon {
    margin-left: -20px;
  }
}

.icon-done {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  transform: scale(1.25);
  animation-duration: 1s;
  animation-name: bounceIn;
}

.loader {
  position: absolute;
  top: 50%;
  left: 10px;
  margin-top: -0.5rem;

  .loading & {
    background: linear-gradient(
      to right,
      white 10%,
      rgba(255, 255, 255, 0) 42%
    );

    &:before {
      background: white;
    }

    &:after {
      background: $color-pink-swan;
    }
  }

  .loading &.inverted {
    background: linear-gradient(
      to right,
      $color-pink-swan 10%,
      rgba(white, 0) 42%
    );

    &:before {
      background: $color-pink-swan;
    }

    &:after {
      background: white;
    }
  }
}

.button-description {
  padding: 10px 0;
  font-size: rem(12px);
  line-height: rem(12px);
  color: $color-grey;
}
