@use 'common/_shared.scss' as *;

.root {
  position: relative;
  font-weight: 700;

  @include media('<tablet') {
    margin-bottom: 20px;
  }
}

.highlighted {
  color: black;
}
