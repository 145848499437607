@use 'common/_shared.scss' as *;

.form {
  @include media('>=desktop') {
    padding: 0 50px 42px 112px;
  }
}

.field-wrap {
  position: relative;
  display: flex;
  align-items: center;
}

.nationalities {
  @include media('>=tablet') {
    max-width: 336px;
  }

  @include media('>=desktop') {
    max-width: 316px;
  }
}

.country-of-residence {
  max-width: 242px;

  @include media('>=tablet') {
    max-width: 336px;
  }

  @include media('>=desktop') {
    max-width: 314px;
  }
}

.select-field-wrap {
  margin: 0;
  width: 100%;
  max-width: 195px;

  @include media('>=tablet') {
    max-width: 246px;
  }

  :global {
    .field-error-global {
      bottom: -2px;
    }
  }
}

.modifiers {
  position: relative;
  display: inline-block;
  margin-left: 18px;
  margin-top: 2px;
  min-width: 52px;
}

.form-buttons {
  margin-top: 40px;
}
