@use 'sass:color';
@use 'common/_shared.scss' as *;

.root {
  margin-bottom: 30px;
  padding: 30px 50px;
  text-align: left;
  font-size: rem(14px);
  color: $color-nobel;
  background: white;
  @include white-box-shadow();

  @include media('>=tablet') {
    text-align: center;
  }

  h3,
  p,
  ul,
  ol {
    margin: 10px 0;
  }

  h3 {
    @include main-sans-serif();
    font-size: rem(14px);
    line-height: 1;
  }

  p {
    font-size: rem(12px);
    line-height: 1.2;
    text-align: left;
  }

  a {
    display: inline-block;
    margin-bottom: 10px;
    text-decoration: none;
    font-size: rem(12px);
    font-weight: $bold;
    color: $color-tallow;
    transition: color 0.2s;

    @include hover() {
      color: color.adjust($color-tallow, $lightness: 15%);
    }
  }

  em {
    font-style: italic;
  }

  ul,
  ol {
    padding: 0 40px;
    text-align: left;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }
}
