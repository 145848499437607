@use 'sass:color';
@use 'common/_shared.scss' as *;

%Button {
  padding: em(18px) em(58px);
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  overflow: hidden;
  @include main-sans-serif($bold);

  @include media('<tablet') {
    padding: em(18px) em(30px);
    max-width: 100%;
    width: 288px;
    min-width: fit-content;
  }

  &[disabled] {
    pointer-events: none;
  }
}

%ButtonMain {
  @include color-schema(btn-main-color) using ($color) {
    background-color: $color;
    color: $color-white;
  }
  @include hover-with-theme('.hover-wrapper', btn-main-color) using ($color) {
    background-color: color.adjust($color, $lightness: 8%);
  }
}

%ButtonMainInverted {
  @include color-schema(btn-main-color) using ($color) {
    background-color: inherit;
    border: 1px solid $color;
    color: $color;
  }

  @include hover-with-theme('.hover-wrapper', btn-main-color) using ($color) {
    color: $color-white;
    background-color: $color;
  }
}

.root {
  @extend %Button;
  transition: background-color 0.2s;

  &[disabled] {
    background-color: $color-pink-swan !important;

    &.inverted {
      color: $color-pink-swan;
      border: 1px solid $color-pink-swan;
    }
  }
}

// Button color
.btn-main {
  @extend %ButtonMain;

  &.inverted {
    @extend %ButtonMainInverted;
  }
}

.btn-tallow {
  color: #ffffff;
  background-color: $color-tallow;

  @include hover-with-theme('.hover-wrapper') {
    background-color: $color-yuma;
  }

  &.inverted {
    border: 1px solid $color-tallow;
    color: $color-tallow;
    background-color: inherit;

    @include hover-with-theme('.hover-wrapper') {
      color: #ffffff;
      background-color: $color-tallow;
    }
  }
}

.btn-black-pearl {
  @include color-schema(main-black-bg) using ($bg) {
    color: $color-white;
    background-color: $bg;
  }

  @include hover-with-theme('.hover-wrapper', main-black-hover-bg) using
    ($hover-bg) {
    background-color: $hover-bg;
  }
}

.btn-black {
  &.inverted {
    border: 1px solid black;
    color: black;

    @include hover-with-theme('.hover-wrapper') {
      color: #ffffff;
      background-color: black;
    }

    @include theme-celeb {
      border: 1px solid $color-nero;
      color: $color-nero;

      @include hover-with-theme('.hover-wrapper') {
        color: #ffffff;
        background-color: $color-nero;
      }
    }
  }
}

.btn-white {
  border: 1px solid $color-white;
  background-color: $color-white;
  color: $color-black;

  @include hover-with-theme('.hover-wrapper', btn-main-color) using ($color) {
    color: $color-white;
    background-color: $color;
    border-color: $color;
  }

  &.inverted {
    border: 1px solid $color-white;
    background: inherit;
    color: $color-white;

    @include hover-with-theme('.hover-wrapper', btn-main-color) using ($color) {
      background-color: $color;
      border-color: $color;
    }
  }
}

.btn-full-white {
  border: 1px solid $color-white;
  background-color: $color-white;
  color: $color-black;

  @include hover-with-theme('.hover-wrapper') {
    color: $color-white;
    background-color: $color-white;
    border-color: $color-white;
  }

  &.inverted {
    border: 1px solid $color-white;
    background: inherit;
    color: $color-white;

    @include hover-with-theme('.hover-wrapper') {
      color: $color-black;
      border-color: $color-white;
    }
  }
}

.btn-blue {
  color: $color-cornflower-blue;
  background-color: rgba($color-cornflower-blue, 0.09);
}

.btn-light-blue {
  background-color: $color-light-blue;
  color: $color-dark-blue;

  &:hover {
    color: $color-white;
    background-color: $color-dark-blue;
  }
}

// Button size
.size-m {
  font-size: 1rem;
}

.size-s {
  font-size: 0.8rem;
}
