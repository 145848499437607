@use 'common/_shared.scss' as *;

.root {
  margin: 20px 0;
}

.logs-wrapper {
  position: relative;
}

.message-wrapper {
  & + .message-wrapper {
    margin-top: 30px;
  }

  &:nth-child(51n) {
    margin-top: 30px;
    border-top: 2px solid $color-merino;
    padding-top: 38px;
  }
}

.message-text {
  @include break-word-custom();
  @include hyphens();
  white-space: pre-wrap;
}

.time {
  font-size: rem(12px);
  line-height: rem(12px);
  color: $color-grey;
  margin-bottom: 5px;
}
