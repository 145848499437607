@use 'common/_shared.scss' as *;

.root {
  position: relative;
  padding: 2px 3px 2px 4px;
  min-width: 12px;
  min-height: 12px;
  border-radius: rem(7px);
  background-color: $color-tallow;
  font-size: rem(11px);
  font-weight: bold;
  line-height: rem(11px);
  color: white;
}
