@use 'common/_shared.scss' as *;

.root {
  position: relative;
  padding-top: 20px;

  @include media('>desktop') {
    padding-top: 50px;
  }
}

.content-wrapper {
  position: relative;
  width: 100%;
  background: white;
  @include white-box-shadow();

  @include media('>=tablet') {
    margin: 0;
  }
}

.back-button {
  top: 38px;
  left: 10px;

  @include media('>=tablet') {
    top: 42px;
    left: 50px;
  }
}

.title {
  pointer-events: none;
  padding: 35px 10px 0 44px;

  @include media('>=tablet') {
    padding: 42px 20px 0 112px;
  }
}

.field-wrap {
  padding: 0 20px 30px;

  @include media('>=tablet') {
    padding: 0 50px 42px 112px;
  }
}

.input {
  margin-bottom: 30px;

  input {
    text-transform: none;
  }
}

.error {
  width: 100%;
}
