@use 'common/_shared.scss' as *;

.root {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  min-height: 400px;
  box-shadow: 0 0 25px 0 rgba(37, 110, 144, 0.07);
  border-radius: 20px;
  min-width: 0;
  color: inherit;
  text-decoration: none;

  @include media('<desktop') {
    min-height: 1px;
    flex-direction: column;
  }

  &.long-style.odd {
    .column-1 {
      order: 2;
    }

    .column-2 {
      order: 1;
    }

    @include media('<desktop') {
      .column-1 {
        order: 1;
      }

      .column-2 {
        order: 2;
      }
    }
  }

  &.long-style {
    @include media('<tablet') {
      width: 100%;
    }
  }

  &.short-style {
    flex-direction: column;
    min-height: 1px;
  }
}

.column-1 {
  display: inline-block;
  width: calc(66.66667% + 9px);
  height: auto;

  .short-style & {
    height: auto;
  }
}

.column-2 {
  width: calc(33.33333% - 9px);
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  align-items: center;
  background-color: $color-snow;
}

.column {
  @include media('<desktop') {
    width: 100%;
  }

  .short-style & {
    width: 100%;
    flex-shrink: 0; //IE11 fix of image inside scaling
  }
}
