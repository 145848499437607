.root {
  position: relative;
}

.loader {
  position: absolute;
  top: calc(50% - 0.5rem);
  right: 0.5rem;
  margin: 0 auto;
}
