@use 'common/_shared.scss' as *;

$card-image-margin: 119px;

.root {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  min-height: 450px;

  @include media('<tablet') {
    width: auto;
    margin-left: -20px;
    margin-right: -20px;
  }
}

.inner-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 475px;
  margin: 0 auto 20px;
  padding: 34px 20px 0;
}

.inner {
  position: relative;
  background-color: white;
  margin-top: $card-image-margin - 7px;
  padding: 0 15px 26px;
  text-align: center;
  @include white-box-shadow();
  width: 100%;
}

.card-img {
  position: relative;
  width: 100%;
  margin-bottom: -$card-image-margin - 4px;
  display: flex;
  justify-content: center;

  img {
    position: relative;
    top: -$card-image-margin;
  }
}

.background-image-container {
  position: absolute;
  top: 0;
  height: 100%;
  max-height: 450px;
  width: 100%;
  overflow: hidden;

  img {
    @include object-fit-polyfill();
  }
}

.block-greeting {
  position: relative;
  @include main-serif($bold);
  font-size: rem(22px);
  line-height: rem(31px);

  em {
    color: $color-tallow;
  }
}

.text-wrapper {
  position: relative;
}

.buttons-wrap {
  text-align: center;
  margin-top: 18px;
}

.buttons-item {
  display: block;

  & + .buttons-item {
    margin-top: 30px;
  }
}

.action-button {
  display: inline-block;
  margin: 0 auto;
  vertical-align: top;

  & + .action-button {
    margin-top: 30px;
  }
}

.action-button-text {
  position: relative;
  font-size: rem(10px);
  color: black;
  text-transform: uppercase;
  padding-bottom: 2px;
  border-bottom: 1px solid currentColor;
}
