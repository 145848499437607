@use 'sass:color';
@use 'common/_shared.scss' as *;

.root {
  display: block;
  font-size: rem(12px);
  overflow: auto;
  max-height: 75vh;
}

%Cell {
  padding: 10px;
  vertical-align: middle;
  border: 1px solid $color-merino;
}

.message {
  margin-bottom: 20px;
}

.cell {
  @extend %Cell;
}

.id-link {
  color: $color-black-pearl;
  transition: color 0.2s;

  @include hover() {
    text-decoration: none;
    color: color.adjust($color-tallow, $lightness: 15%);
  }
}

.status {
  padding: 0;
  text-align: center;
}

.row {
  transition: background-color 0.2s;

  @include hover() {
    background: $color-whisper;
  }
}

.table-header {
  &:first-letter {
    text-transform: capitalize;
  }
}
