@use 'common/_shared.scss' as *;

.root {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.menu-item {
  padding: 5px;

  @include media('>=tablet') {
    &:not(:first-child)::before {
      content: '―';
      display: inline;
      margin-right: 10px;
    }
  }
}

.menu-link {
  margin-left: auto;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1.5;
  color: black;
  transition: color 0.1s ease;
  font-size: rem(12px);

  @include hover() {
    text-decoration: none;
    color: $color-tallow;
  }
}
