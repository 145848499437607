@use 'common/_shared.scss' as *;

.root {
  position: relative;
  padding-top: 60px;

  @include media('>desktop') {
    padding-top: 50px;
  }
}
