@use 'common/_shared.scss' as *;

$offset: 30px;

.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background: $color-auth-white-background;
  padding: 30px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $offset;
}

.form-loader {
  padding-top: 160px;
  padding-bottom: 160px;
}

.form-wrapper {
  width: 100%;
  max-width: $auth-sidebar-width;
  margin: auto;
}

.title {
  @include main-serif($bold);
  font-size: rem(35px);
  margin-bottom: 20px;
  position: relative;
  padding: 0 $offset 20px;
  line-height: 1;
  text-align: center;

  @include media('>=desktop') {
    padding: 0 0 20px;
  }
}

.form {
  position: relative;
  padding: 60px $offset;
}

.form-content {
  position: relative;
  padding: 0 $offset;
  font-size: rem(14px);

  @include media('>=desktop') {
    padding: 0 70px;
  }
}

.step-final {
  margin-top: 70px;
  margin-bottom: 120px;
  text-align: center;
}
