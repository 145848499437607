@use 'common/_shared.scss' as *;

.modal-notify-container {
  background-color: white;
  border-radius: 10px;
  text-align: center;

  @include media('<tablet') {
    margin-left: 20px;
    margin-right: 20px;
  }

  @include media('>=tablet') {
    width: 700px;
  }
}

.modal-notify-header {
  position: relative;
  padding: 50px 30px 0;

  @include media('>=tablet') {
    padding: 60px 30px 0;
  }

  .title {
    margin-bottom: 30px;
    @include main-serif();
    font-size: rem(24px);
    line-height: rem(26px);

    @include media('>=desktop') {
      font-size: rem(32px);
      line-height: rem(38px);
    }
  }

  .button-close {
    position: absolute;
    right: 0;
    top: 10px;
    padding: 8px;
    height: 32px;
    width: 33px;

    @include media('>=desktop') {
      right: -20px;
      top: 20px;
      padding: 4px;
      height: 22px;
      width: 22px;
    }
  }
}

.modal-notify-description {
  position: relative;
  padding: 0 10px 40px;

  @include media('>=tablet') {
    padding: 0 30px 50px;
  }
}

.modal-notify-footer {
  position: relative;
  display: flex;
  justify-content: space-around;
  padding: 0 10px 20px;

  @include media('>=tablet') {
    padding: 0 10px 30px;
  }
}

.button-login {
  border: 1px solid $color-tallow;
}
