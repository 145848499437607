@use 'common/_shared.scss' as *;

%Stars {
  display: inline-block;
  vertical-align: top;
  margin-top: rem(3px);
  font-size: rem(12px);
  @include main-sans-serif();
  @include color-schema(star-color) using ($color) {
    color: $color;
  }

  &:before {
    display: inline-block;
    position: relative;
  }
}

.stars-1 {
  @extend %Stars;

  &:before {
    content: '\2605';
  }

  &.with-blank {
    &:before {
      content: '\2605 \2606 \2606 \2606 \2606';
    }
  }
}

.stars-2 {
  @extend %Stars;

  &:before {
    content: '\2605 \2605';
  }

  &.with-blank {
    &:before {
      content: '\2605 \2605 \2606 \2606 \2606';
    }
  }
}

.stars-3 {
  @extend %Stars;

  &:before {
    content: '\2605 \2605 \2605';
  }

  &.with-blank {
    &:before {
      content: '\2605 \2605 \2605 \2606 \2606';
    }
  }
}

.stars-4 {
  @extend %Stars;

  &:before {
    content: '\2605 \2605 \2605 \2605';
  }

  &.with-blank {
    &:before {
      content: '\2605 \2605 \2605 \2605 \2606';
    }
  }
}

.stars-5 {
  @extend %Stars;

  &:before {
    content: '\2605 \2605 \2605 \2605 \2605';
  }
}
