@use 'common/_shared.scss' as *;

.icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 4px;
  border-radius: 50%;
  border: 1px solid black;

  &:last-child {
    margin: 0;
  }
}

.confirmed {
  background: green;
}

.not-confirmed {
  background: blue;
}

.warnings {
  background: yellow;
}

.errors {
  background: red;
}
