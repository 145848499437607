@use 'common/_shared.scss' as *;

.root {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: auto;
  text-align: center;
}

.block {
  fill: white;
  stroke: black;
  stroke-width: 2px;

  &.red {
    fill: rgba($color-fire-brick, 0.5);
  }

  &.green {
    fill: rgba($color-limeade, 0.5);
  }

  &.orange {
    fill: rgba($lightning-yellow, 0.5);
  }

  &.grey {
    fill: rgba($color-suva-grey, 0.5);
  }

  &.other {
    fill: white;
  }
}

.block-label {
  font-size: rem(20px);
  @include main-sans-serif($bold);
}

.line {
  fill: none;
  stroke: black;
  stroke-width: 2px;
}
