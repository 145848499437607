@use 'common/_shared.scss' as *;

.root {
  margin: 20px 0 0;
}

.logs-wrapper {
  position: relative;
}

.message-wrapper {
  & + .message-wrapper {
    margin-top: 30px;
  }

  &:nth-child(51n) {
    margin-top: 30px;
    border-top: 2px solid $color-merino;
    padding-top: 38px;
  }
}

.message-text {
  @include break-word-custom();
  @include hyphens();
  white-space: pre-wrap;
  tab-size: 4;
}

.field-wrapper {
  display: flex;
  flex-wrap: nowrap;
}

.field {
  font-size: rem(12px);
  line-height: rem(12px);
  color: $color-grey;
  margin-bottom: 5px;
  text-transform: uppercase;

  & + .field {
    padding-left: 10px;
  }
}

.field-label {
  color: $color-grey;
  display: inline;
}

.button-loadmore-wrapper {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

.button-loadmore {
  display: flex;
  justify-content: center;
  position: relative;

  &:hover {
    .loader::after {
      background: $color-yuma;
    }
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 20px;
  margin-top: -0.5rem;
  background: linear-gradient(to right, white 10%, rgba(255, 255, 255, 0) 42%);

  &::before {
    background: white;
  }

  &::after {
    background: $color-tallow;
  }
}
