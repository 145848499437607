@use 'common/_shared.scss' as *;

.root {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  text-transform: uppercase;

  @include media('>=tablet') {
    margin-bottom: 0;
  }
}

.input {
  flex-shrink: 0;
  flex-grow: 0;
  width: 20px;
}

.label {
  margin-right: 20px;
  align-self: center;
  font-size: rem(11px);
  @include main-sans-serif();
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
}

.button-view {
  width: 100%;

  .input {
    position: absolute;
    z-index: -1;
    opacity: 0.01;

    &:checked + .label {
      color: white;
      background-color: $color-tallow;
    }
  }

  .label {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: em(16px);
    overflow: hidden;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    @include main-sans-serif();
    font-size: 0.8rem;
    transition: background-color 0.2s;
    border: 1px solid $color-tallow;
    color: $color-tallow;

    @include hover() {
      color: white;
      background-color: $color-tallow;
    }
  }
}

.button-view + .button-view .label {
  border-left: 0;
}

.styled-view {
  margin-bottom: 20px;

  .input {
    position: absolute;
    opacity: 0.01;

    [data-focus-source='key'] &:focus + label {
      outline: -webkit-focus-ring-color auto 5px;
    }

    [data-focus-source='key'] &:-moz-focusring + label {
      outline: 1px dotted;
    }

    &:checked + .label::after {
      content: '';
      display: inline-block;
      left: 3px;
      top: 3px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background: $color-tallow;
    }

    &:checked + .label {
      color: $color-black-pearl;
    }
  }

  .label {
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 2px;
    padding-left: 26px;
    min-height: 16px;
    margin-right: 20px;
    align-self: center;
    font-size: rem(12px);
    font-weight: $book;
    color: $color-grey;

    &::before,
    &::after {
      position: absolute;
    }

    &::before {
      content: '';
      display: inline-block;
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
      margin-right: 10px;
      border: 1px solid $color-tallow;
      border-radius: 50%;
    }
  }
}

.small-styled-view {
  display: flex;
  margin-bottom: 5px;

  .input {
    position: absolute;
    opacity: 0.01;

    [data-focus-source='key'] &:focus + label {
      outline: -webkit-focus-ring-color auto 5px;
    }

    [data-focus-source='key'] &:-moz-focusring + label {
      outline: 1px dotted;
    }

    &:checked + .label::after {
      content: '';
      display: inline-block;
      left: 3px;
      top: 5px;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: $color-tallow;
    }

    &:checked + .label {
      color: $color-black-pearl;
    }
  }

  .label {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 20px;
    min-height: 16px;
    margin-right: 20px;
    align-self: center;
    font-size: rem(10px);
    text-transform: uppercase;
    @include main-sans-serif();

    &::before,
    &::after {
      position: absolute;
    }

    &::before {
      content: '';
      display: inline-block;
      left: 0;
      top: 2px;
      width: 12px;
      height: 12px;
      margin-right: 10px;
      border: 1px solid $color-tallow;
      border-radius: 50%;
    }
  }
}

.disabled {
  opacity: 0.5;

  .label {
    cursor: default;
  }
}
