@use 'sass:color';
@use 'common/_shared.scss' as *;

%WysiwygButton {
  display: inline-block;
  width: auto;
  overflow: hidden;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto;
  padding: em(18px) em(58px);
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  @include main-sans-serif($bold);
  transition: background-color 0.2s;

  @include media('<tablet') {
    padding: em(18px) em(30px);
    max-width: 100%;
    width: 288px;
    min-width: fit-content;
  }

  :global(.no-touchevents) &:hover {
    text-decoration: none;
  }

  p & {
    display: block;
    width: fit-content;
    margin: 10px auto;
  }
}

%ButtonMain {
  @include color-schema(btn-main-color) using ($color) {
    background-color: $color;
    color: $color-white;
  }
  @include hover-with-theme('.hover-wrapper', btn-main-color) using ($color) {
    background-color: color.adjust($color, $lightness: 8%);
  }
}

%ButtonMainInverted {
  @include color-schema(btn-main-color) using ($color) {
    background-color: inherit;
    border: 1px solid $color;
    color: $color;
  }
  @include hover-with-theme('.hover-wrapper', btn-main-color) using ($color) {
    color: $color-white;
    background-color: $color;
  }
}

@mixin wysiwyg-regular() {
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: 2.1;
  font-size: rem(14px);

  h2,
  h3 {
    width: 100%;
    max-width: 290px;
    margin: 0 auto 25px;
    text-align: center;
    font-weight: $bold;
  }

  h3,
  h4 {
    line-height: 1.3;
  }

  h4,
  h5,
  h6 {
    font-weight: $book;
  }

  h4 {
    font-size: rem(18px);
  }

  h5,
  h6 {
    font-size: rem(16px);
  }

  p,
  ul,
  ol {
    text-align: justify;
    margin-bottom: 20px;
    line-height: 2.1;
    font-size: rem(14px);
    letter-spacing: rem(1px);
    color: $color-zambezi;
  }

  ul {
    list-style: none;

    li::before {
      display: inline-block;
      width: em(16);
      content: '\2022';
      font-weight: $bold;
      color: $color-tallow;
    }
  }

  :global(.block-border) {
    align-self: center;
    margin: 0 0 25px;
    border: 1px solid $color-tallow;
    padding: 40px 57px;
    text-align: center;
    color: $color-tallow;
    width: 100%;

    :global(.block-border-title) {
      margin: 0 auto 10px;
      font-size: rem(50px);
      line-height: 1.75;
      font-weight: $bold;
    }

    :global(.block-border-subtitle) {
      margin: 0 auto;
      font-size: rem(14px);
      line-height: rem(24px);
    }

    p,
    ul,
    ol {
      text-align: center;
    }
  }

  & > *:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

@mixin wysiwyg-myacc() {
  font-size: 100%;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;

  a,
  .link {
    color: $color-tallow;
    word-break: break-word;
  }

  a:hover,
  a:focus,
  .link:hover,
  .link:focus {
    text-decoration: none;
    outline: 0;
  }

  a:hover,
  .link:hover {
    color: color.adjust($color-tallow, $lightness: 15%);
  }

  hr {
    height: 1px;
    margin: 1rem 0;
    padding: 0;
    border: none;
    background: rgba(142, 146, 156, 0.5);
  }

  summary {
    font-weight: bold;
  }

  h1,
  .heading-a {
    margin: 1rem 0 0.75rem;
    font-size: 2.027rem;
    font-weight: bold;
    line-height: 1.3;
  }

  h2,
  .heading-b {
    margin: 1rem 0 0.75rem;
    font-size: 1.802rem;
    font-weight: bold;
    line-height: 1.3;
  }

  h3,
  .heading-c {
    margin: 1rem 0 0.75rem;
    font-size: 1.602rem;
    font-weight: bold;
    line-height: 1.3;
  }

  h4,
  .heading-d {
    margin: 1rem 0 0.75rem;
    font-size: 1.424rem;
    font-weight: bold;
    line-height: 1.3;
  }

  h5,
  .heading-e {
    margin: 1rem 0 0.75rem;
    font-size: 1.266rem;
    font-weight: bold;
    line-height: 1.3;
  }

  h6,
  .heading-f {
    margin: 1rem 0 0.75rem;
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.3;
  }

  p {
    margin: 1em 0;
  }

  dl {
    margin: 0 0 20px;
  }

  dl dd,
  dl dl {
    margin-bottom: 10px;
    margin-left: 20px; /* LTR */
  }

  [dir='rtl'] dl dd,
  [dir='rtl'] dl dl {
    margin-right: 20px;
  }

  blockquote {
    margin: 1em 40px;
  }

  address {
    font-style: italic;
  }

  u,
  ins {
    text-decoration: none;
  }

  s,
  strike,
  del {
    text-decoration: line-through;
  }

  big {
    font-size: larger;
  }

  small {
    font-size: smaller;
  }

  sub {
    vertical-align: sub;
    font-size: smaller;
    line-height: normal;
  }

  sup {
    vertical-align: super;
    font-size: smaller;
    line-height: normal;
  }

  abbr,
  acronym {
    border-bottom: dotted 1px;
  }

  ul {
    margin: 0.25em 0 0.25em 1.5em; /* LTR */
    padding-left: 0;
    list-style-type: disc;
    list-style-image: none;
  }

  [dir='rtl'] ul {
    margin-right: 1.5em;
    margin-left: 0;
    padding-right: 0;
  }

  [dir='rtl'] .messages__list {
    margin-right: 0;
  }

  ol {
    margin: 0.25em 0 0.25em 2em; /* LTR */
    padding: 0;
    list-style-type: decimal;
  }

  [dir='rtl'] ol {
    margin-right: 2em;
    margin-left: 0;
  }

  abbr[title] {
    text-decoration: none;
  }

  code {
    margin: 0.5em 0;
  }

  pre {
    margin: 0.5em 0;
    white-space: pre-wrap;
  }

  details {
    line-height: 1.295em;
  }

  details summary {
    padding: 0.95em 1.45em;
  }

  details summary:focus {
    outline: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  i,
  em {
    font-style: italic;
  }

  b,
  strong {
    font-weight: bold;
  }

  // Inverted button
  :global(.cta-layout-1) {
    @extend %WysiwygButton;
    @extend %ButtonMainInverted;
  }

  // Main button
  :global(.cta-layout-2) {
    @extend %WysiwygButton;
    @extend %ButtonMain;
  }
}
