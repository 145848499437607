@use 'sass:color';
@use 'common/_shared.scss' as *;

.root {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin: 20px 10px 20px 0;
}

.checkbox {
  display: none;
}

.text {
  font-size: rem(11px);
  text-transform: uppercase;
  font-weight: 700;
}

.label {
  display: block;
  position: relative;
  height: 18px;
  width: 52px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 18px;
}

.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.15s ease-in 0s;
}

.inner::before,
.inner::after {
  display: block;
  float: left;
  width: 50%;
  height: 18px;
  padding: 0;
  line-height: 18px;
  font-size: 12px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}

.inner::before {
  content: 'ON';
  padding-left: 10px;
  background-color: $color-tallow;
  color: white;
}

.inner::after {
  content: 'OFF';
  padding-right: 5px;
  background-color: $color-narvik;
  color: white;
  text-align: right;
}

.switch {
  display: block;
  width: 18px;
  height: 18px;
  margin: 0;
  background: white;
  position: absolute;
  bottom: 0;
  right: 34px;
  border: 1px solid color.adjust($color-whisper, $lightness: -10%);
  border-radius: 18px;
  transition: all 0.15s ease-in 0s;
}

.checkbox:checked + .label {
  .inner {
    margin-left: 0;
  }

  .switch {
    right: 0;
    border-color: $color-tallow;
  }
}
