@use 'common/_shared.scss' as *;

.root {
  margin-top: 25px;
  margin-bottom: 25px;

  @include media('>=desktop') {
    margin-top: 25px;
    margin-bottom: 25px;
    background-color: transparent;
  }
}

.content-wrapper {
  @include white-box-shadow();
  border-radius: $card-border-radius;
  overflow: hidden;
  position: relative;

  picture {
    display: block;
    height: 100%;
    line-height: 0;
  }

  img {
    width: 100%;
  }
}
