@use 'common/_shared.scss' as *;

// Hover
@mixin hover-button() {
  @include hover {
    @content;
  }

  @at-root {
    :global(.no-touchevents) .hover-wrapper:hover &,
    [data-focus-source='key'] .hover-wrapper:focus & {
      @content;
    }
  }
}

%Button {
  display: inline-block;
  font-size: rem(12px);
  padding: 15px 24px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  overflow: hidden;
  @include main-sans-serif($book);
  background-color: $color-tallow;
  color: white;
  transition: background-color 0.2s;
  height: 100%;

  @include media('>=tablet') {
    width: auto;
    min-width: auto;
    padding: 13px 40px;
  }

  @include hover-button() {
    background-color: $color-yuma;
  }

  &[disabled] {
    pointer-events: none;
  }
}

%ButtonTallow {
  color: #ffffff;
  background-color: $color-tallow;

  @include hover-button() {
    background-color: $color-yuma;
  }
}

%ButtonInvertedTallow {
  border: 1px solid $color-tallow;
  color: $color-tallow;
  background-color: white;

  @include hover-button() {
    color: #ffffff;
    background-color: $color-tallow;
  }
}

%ButtonLignBrown {
  color: white;
  background-color: $color-light-brown;

  @include hover-button() {
    background-color: rgba($color-light-brown, 0.8);
  }
}

.root {
  @extend %Button;
  transition: background-color 0.2s;

  &[disabled] {
    background-color: $color-pink-swan;
  }

  &.btn-tallow {
    @extend %ButtonTallow;

    &.root-inverted {
      @extend %ButtonInvertedTallow;

      &[disabled] {
        color: $color-pink-swan;
        border: 1px solid $color-pink-swan;
      }
    }
  }

  &.btn-light-brown {
    @extend %ButtonLignBrown;
  }

  &.btn-tallow-black {
    color: $color-white;
    background-color: $color-tallow;

    @include hover-button() {
      color: $color-black;
      background-color: $color-white;
    }

    &.root-inverted {
      border: none;
      color: $color-black;
      background-color: $color-white;
      height: auto;

      @include hover-button() {
        background-color: $color-tallow;
        color: $color-white;
      }
    }
  }

  &.btn-tallow-invert {
    color: $color-white;
    border: 1px solid $color-tallow;

    @include hover-button() {
      color: $color-tallow;
      background-color: $color-white;
    }
  }

  &.theme-rounded {
    font-weight: 500;
    border-radius: 5px;
    height: auto;
  }
}

.size-l {
  font-size: rem(20px);
  line-height: rem(20px);
  padding: 20px 40px;
}

.size-m {
  font-size: rem(13px);
  line-height: rem(20px);
}

.size-s {
  font-size: rem(10px);
  line-height: rem(17px);
}
