@use 'common/_shared.scss' as *;

.root {
  display: flex;
  width: 50%;
  border-top: 3px solid $color-white;

  &.is-active {
    border-top: 3px solid $color-tallow;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 10px;
  background: $color-white;
  color: $color-black-pearl;

  &:disabled {
    color: $color-mist-grey;
  }

  &.with-border {
    border-right: 1px solid $color-white-smoke;
  }

  @include media('<desktop') {
    &:first-child {
      padding: 2px 0 2px 4px;
    }

    &:last-child {
      padding: 2px 4px 2px 0;
    }
  }
}

.icon {
  width: 10px;
  height: 10px;
}
