@use 'common/_shared.scss' as *;
@use 'common/_helpers.scss' as *;

.root {
  position: relative;
  padding-top: 20px;

  @include media('>desktop') {
    padding-top: 50px;
  }
}

.form {
  width: 100%;
  max-width: 100%;
}

.select-item {
  @include media('<=tablet') {
    min-width: 68px;

    & + .select-item:nth-child(1) {
      min-width: 70px;
    }

    & + .select-item:nth-child(3) {
      min-width: 64px;
    }

    & + .select-item {
      margin-left: 0;
    }
  }
}

.cta {
  width: 220px;
  max-width: 100%;
  position: relative;
  margin: 30px 0 0 auto;
}

.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 18px;
  font-weight: $medium;
  text-align: center;
  font-size: 12px;

  .loading {
    color: $color-mist-grey;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 30px;
  margin-top: -0.5rem;
}

.radios-wrapper {
  margin-top: 10px;
  margin-bottom: -20px;
}

.phone-input-container {
  .phone-input-wrapper {
    height: 30px;
    margin-bottom: 15px;
    background: transparent;
    border-color: transparent transparent #e6e5e2 transparent;

    :global {
      .selected-flag {
        top: 6px;
      }
    }
  }

  .phone-input {
    padding: 15px 0 0;
    @include main-sans-serif($bold);
    font-size: rem(12px);

    &.error {
      color: red;
    }
  }
}

.group-title {
  margin-top: 30px;
  font-size: rem(12px);
  font-weight: $bold;
  color: $color-grey;
}

.multifield-item {
  margin-bottom: 0;
}

.label {
  text-transform: uppercase;
  font-size: rem(10px);
  @include main-sans-serif($bold);
  color: $color-grey;
}

.date-input {
  margin-bottom: -5px;
}

.form-buttons {
  margin-top: 40px;
}

.checkbox {
  position: relative;
  padding-bottom: 15px;
  margin-top: 30px;

  @include media('>=desktop') {
    margin-top: 10px;
  }
}

.checkbox-text {
  white-space: initial;
  text-transform: none;
  font-size: rem(12px);
  line-height: 1.25;
  font-weight: 700;
}

.checkbox-label {
  align-items: flex-start;
}
