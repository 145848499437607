@use 'common/_shared.scss' as *;

.root {
  margin: 25px 0 30px;
  color: $color-zambezi;
  font-size: rem(11px);
  line-height: 1.5;

  .valid {
    color: $color-tallow;
  }
}

.check-list-point {
  position: relative;
  margin-bottom: 3px;

  &::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    border: 1px solid currentColor;
    border-radius: 100%;
    margin-right: 15px;
  }
}

.icon-check {
  position: absolute;
  top: 1px;
  left: 2px;
}
