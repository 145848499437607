@use 'common/_shared.scss' as *;
@use 'common/components/Button/Button.module.scss' as *;

%WysiwygButton {
  @extend %Button;
  display: inline-block;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  transition: background-color 0.2s;

  :global(.no-touchevents) &:hover {
    text-decoration: none;
  }

  p & {
    display: block;
    width: fit-content;
    margin: 10px auto;
  }
}

.wysiwyg {
  font-size: inherit;
  line-height: inherit;

  h1,
  h2,
  h3 {
    @include main-serif($book);
    color: black;
  }

  h4,
  h5,
  h6 {
    @include main-sans-serif($light);
    color: black;
  }

  h1 {
    font-size: rem(70px);
  }

  h2 {
    font-size: rem(38px);
  }

  h3 {
    font-size: rem(34px);
  }

  h4 {
    font-size: rem(30px);
  }

  h5 {
    font-size: rem(20px);
  }

  h6 {
    font-size: 1.2em;
  }

  ul {
    list-style: disc inside;
  }

  ol {
    list-style: decimal inside;
  }

  b,
  strong {
    font-weight: $bold;
  }

  em,
  i {
    font-style: italic;
  }

  mark {
    background-color: inherit;
    @include color-schema(highlight-color) using ($color) {
      color: $color;
    }
  }

  a {
    text-decoration: none;

    @include color-schema(highlight-color) using ($color) {
      color: $color;
    }

    @include hover() {
      text-decoration: underline;
    }
  }

  blockquote,
  q {
    display: block;
    margin: 1em;
    opacity: 0.8;

    &::before {
      content: '" ';
    }

    &::after {
      content: ' "';
    }
  }

  table {
    width: 100%;
    margin: 1em 0;
    table-layout: fixed;
    word-break: break-word;

    th {
      font-weight: $bold;
    }

    th,
    td {
      border: 1px solid $color-ash;
      padding: 0.6em 0.8em;
    }
  }

  :global(.text-align-center) {
    text-align: center;
  }

  // Inverted button
  :global(.cta-layout-1) {
    @extend %WysiwygButton;
    @extend %ButtonMainInverted;
  }
  // Main button
  :global(.cta-layout-2) {
    @extend %WysiwygButton;
    @extend %ButtonMain;
  }
}
