@use 'common/_shared.scss' as *;

.card-image {
  $top-indent: -70px;

  position: relative;
  top: $top-indent;
  height: 275px;
  margin-bottom: $top-indent + 30px;

  img {
    height: 100%;
  }

  @include media('>=tablet') {
    display: none;
  }

  @include media('>=desktop') {
    display: block;
    margin-top: auto;
    height: 100%;
    position: absolute;
    right: -15px;
    z-index: -1;
    top: 0;
  }
}

.user-card {
  .card-status {
    color: $color-main-membre-card;
    text-transform: uppercase;
    font-size: rem(20px);
    font-weight: bold;
  }
}

.card-statistic {
  display: flex;
  margin-top: 24px;
  max-width: 100%;
  justify-content: center;
  margin-bottom: 24px;

  @include media('>=tablet') {
    justify-content: flex-start;
  }
}

.statistic-wrap {
  text-align: center;
  background: white;
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
  width: 50%;
  max-width: 124px;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  @include media('>=tablet') {
    max-width: 50%;
    width: 124px;
  }
}

.statistic-title {
  font-weight: bold;
  font-size: rem(11px);
}

.statistic-info {
  color: $color-main-membre-card;
  font-weight: bold;
  font-size: rem(22.5px);
}
