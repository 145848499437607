@use 'common/_shared.scss' as *;

$brcolor: $color-narvik;

%DDHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  padding: 20px 40px;
  text-align: center;
  text-transform: uppercase;
  font-size: rem(11px);
  transition: background-color 0.15s ease;
}

@mixin ddheader() {
  @extend %DDHeader;

  &:hover {
    background-color: $color-whisper;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 28px;
    border: 4px solid transparent;
  }
}

.root {
  position: relative;
}

.dropdown-single-item {
  @extend %DDHeader;
}

.dropdown-opener {
  @include ddheader();

  &::after {
    border-top-color: $color-black-pearl;
    top: calc(50% - 5px);
  }
}

.dropdown-content {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  background-color: $color-white-smoke;
}

.dropdown-content-header {
  @include ddheader();

  &::after {
    border-bottom-color: $color-black-pearl;
    top: calc(50% - 9px);
  }
}

.dropdown-list {
  border-bottom: 1px solid $brcolor;
  max-height: 300px;
  overflow: auto;
}

.list-item-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75px;
  width: 100%;
  transition: background-color 0.15s ease;

  &.active,
  &:hover {
    background-color: $color-whisper;
  }
}

.property-title {
  text-transform: uppercase;
  font-size: rem(13px);
  font-weight: 500;
}

.hotel-stars {
  font-size: rem(15px);
}

.dropdown-transition-enter {
  opacity: 0.01;
}

.dropdown-transition-enter-active {
  opacity: 1;
  transition: all 300ms ease-out;
}

.dropdown-transition-done-enter {
  opacity: 1;
}

.dropdown-transition-exit {
  opacity: 1;
}

.dropdown-transition-exit-active {
  opacity: 0.01;
  transition: all 300ms ease-out;
}
