@use 'common/_shared.scss' as *;

.root {
  position: relative;
}

.message-wrapper {
  margin: 0 0 20px;
}

.message-error {
  color: $color-fire-brick;
}

.message-ok {
  color: $color-limeade;
}

.buttons-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 40px -10px 30px;
}

.button-item {
  padding: 10px 10px 0;
  max-width: 260px;
}

.logs-wrapper {
  margin-top: 30px;
}

.logs-title {
  position: relative;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-right: 20px;
  @include main-serif();
  font-size: rem(26px);
  line-height: 1;
}

.transition-enter {
  opacity: 0.01;
  max-height: 5vh;
}

.transition-enter-active {
  opacity: 0.5;
  max-height: 25vh;
  transition: max-height 200ms ease-in-out;
}

.transition-done-enter {
  opacity: 1;
  max-height: 75vh;
}

.transition-exit {
  opacity: 0.5;
  max-height: 25vh;
}

.transition-exit-active {
  opacity: 0.01;
  max-height: 0;
  transition: max-height 200ms ease-in-out;
}
