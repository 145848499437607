@use 'common/_shared.scss' as *;

.red {
  color: $color-fire-brick;
}

.green {
  color: $color-limeade;
}

.orange {
  color: $lightning-yellow;
}

.grey {
  color: $color-suva-grey;
}

.other {
  color: $color-limeade;
}
