@use 'common/_shared.scss' as *;

.root {
  display: flex;
  position: relative;
  margin: 0 0 20px;

  @include media('>=desktop') {
    margin: 15px 0 20px;
  }
}

.date-buttons-wrapper {
  display: flex;
}

.date-label {
  margin-top: 15px;
  text-transform: uppercase;
  font-size: rem(11px);
  font-weight: $bold;
  color: $color-grey;
}

.date-button {
  margin: 0 10px;
  padding: 15px 0 0;
  font-size: rem(12px);
  @include main-sans-serif($bold);
  color: $color-grey;
  border-bottom: 1px solid $color-narvik;
  min-width: rem(82px);

  &.active {
    color: $color-tallow;
  }
}

.button-spacer {
  padding: 15px 0 0;
  font-size: rem(12px);
  font-weight: $bold;
  color: $color-grey;
}

.calendar-wrapper {
  position: relative;
}

.calendar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.daypicker {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  @include white-box-shadow();
}

.transition-enter {
  opacity: 0.01;
  transform: scale(0.5) translateY(-30%);
}

.transition-enter-active {
  opacity: 1;
  transform: scale(1) translateY(0%);
  transition: all 150ms ease-out;
}

.transition-done-enter {
  opacity: 1;

  .icon-close {
    display: block;
  }
}

.transition-exit {
  opacity: 1;
}

.transition-exit-active {
  opacity: 0.01;
  transform: scale(0.5) translateY(-30%);
  transition: all 150ms ease-out;
}

.input-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.input-label {
  margin-top: 15px;
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: rem(11px);
  @include main-sans-serif($bold);
  color: $color-grey;
}

.input-container {
  min-width: 150px;
  padding: 0 10px;
}

.input {
  display: block;
  width: 100%;
  border-width: 0 0 1px 0;
  border-radius: 0;
  border-color: $color-narvik;
  padding: 15px 0 0;
  font-size: rem(12px);
  height: rem(31px);
  @include main-sans-serif($bold);
  background-color: transparent;

  &:-webkit-autofill {
    :global {
      animation-name: onAutoFillStart;
    }
  }
}

.reset-button-wrapper {
  display: flex;
  align-items: flex-end;
  margin-left: 20px;
}
