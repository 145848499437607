@use 'common/_shared.scss' as *;

$container-width: 1050px;

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  flex: 1 0 auto;
  margin: 0 auto;
  padding-top: $header-height-with-border;
  background-color: $color-snow;

  @include media('>=desktop') {
    padding-top: $header-height-desktop-with-border;
  }
}

.top {
  flex: 1 0 auto;
  margin-bottom: auto;
}

.bottom {
  margin-top: auto;
}
