@use 'common/_shared.scss' as *;
@use 'common/_wysiwyg.scss' as *;

.items-wrapper {
  padding-left: 5px;
}

.item-wrapper {
  position: relative;
  margin-bottom: 20px;
  padding: 10px 40px 10px 10px;
  opacity: 1;
  will-change: opacity, transform, height;
  transition: opacity 0.3s ease, transform 0.3s ease, height 0.3s ease,
    margin-bottom 0.3s ease;

  &.status-seen {
    color: $color-nobel;
  }

  &.status-deleted {
    transform: translateY(-100%);
    height: 0 !important;
    margin-bottom: 0;
    padding: 0;
  }
}

.text {
  margin-bottom: 10px;
  @include wysiwyg-myacc();
  font-size: 12px;
}

.date {
  color: $color-nobel;
  font-size: 12px;
}

.button-close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px;
  height: 32px;
  width: 33px;

  @include media('>=desktop') {
    right: 1px;
    top: 10px;
    padding: 4px;
    height: 22px;
    width: 22px;
  }
}

.button-seen {
  position: absolute;
  right: 0;
  top: 37px;
  padding: 8px;
  height: 32px;
  width: 33px;

  @include media('>=desktop') {
    padding: 4px;
    height: 24px;
    width: 24px;
  }
}
