@use 'common/_shared.scss' as *;

.root {
  position: absolute;
  left: 0;
  bottom: -24px;
  font-size: rem(12px);
  color: $color-carissma;
  line-height: 10px;
  height: 20px;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
