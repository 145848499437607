@use 'common/_shared.scss' as *;

$color-header-border-on-light: rgba(black, 0.3);
$color-header-border-on-dark: rgba(white, 0.3);

.root {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  min-height: $header-height;
  border-bottom: 1px solid $color-header-border-on-light;
  background-color: white;

  @include media('>=desktop') {
    display: flex;
    min-height: $header-height-desktop;
    background-color: $color-black-pearl;
  }
}

.logo-wrapper {
  height: $header-height;

  @include media('>=desktop') {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: $header-height-desktop;
  }
}

.logo-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 5px 20px;

  @include media('>=desktop') {
    padding: 0 30px;
  }
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 5px 20px;
  cursor: pointer;

  @include media('>=desktop') {
    padding: 0 30px;
  }
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader {
  position: absolute;
  top: calc(50% - 0.5rem);
  margin: 0 auto;
}

.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.top-bar-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: $header-height;
  border-left: 1px solid $color-header-border-on-light;

  @include media('>=desktop') {
    color: white;
    border-left: 1px solid $color-header-border-on-dark;
  }
}

.button {
  display: flex;
  align-items: center;
}

.icon {
  height: rem(16px);
  width: 18px;
}

.label {
  margin-left: 20px;
  text-decoration: none;
  color: black;
  text-transform: uppercase;
  font-size: rem(12px);
  font-weight: 700;
  letter-spacing: rem(0.5px);
}
