@use 'common/_shared.scss' as *;

.info-panel {
  margin-bottom: 20px;

  @include media('>desktop') {
    margin-left: 50px;
    margin-bottom: 30px;
  }
}
