@use 'common/_shared.scss' as *;

.root {
  color: black;
  padding: 20px;
  display: block;
  position: relative;
  text-decoration: none;
  @include main-sans-serif($book);
  letter-spacing: rem(1.1px);

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  @include media('>=desktop') {
    padding: 11px 20px;
    text-align: left;
    letter-spacing: rem(1.6px);

    &::before {
      display: block;
      position: absolute;
      content: '';
      background: currentColor;
      height: 1px;
      width: 0;
      bottom: 10px;
      transition: width 0.15s;
      will-change: width;
    }

    @include hover {
      &::before {
        width: 14px;
      }
    }
  }
}

.label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
