@use 'common/_shared.scss' as *;

.legend {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  text-align: left;
}

.legend-item {
  padding: 2px 4px;
  font-size: 12px;
  font-weight: bold;
  background: green;
  color: white;
}

.confirmed {
  background: green;
}

.not-confirmed {
  background: blue;
}

.warnings {
  background: yellow;
}

.errors {
  background: red;
}

.font-black {
  color: black;
}
