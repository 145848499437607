@use 'sass:math';

@use 'common/_shared.scss' as *;

$card-radius: 20px;
$elements-margin: 12px;

$line-height: math.div(18, 12);
$lines: 4;

.root {
  @include white-box-shadow();
  border-radius: 20px;
  min-width: 0;
  display: flex;
  flex-direction: column;
  color: inherit;
  text-decoration: none;
}

.footer-wrapper {
  text-align: center;
  padding: 0 16px 22px;
}

.thumbnail-wrapper {
  position: relative;
  padding-bottom: 50%;
  border-radius: $card-radius $card-radius 0 0;
  overflow: hidden;
}

.content-wrapper {
  padding: 15px;
  flex-grow: 2;
}

.dates {
  margin-bottom: $elements-margin;
  color: $color-tallow;
}

.intro {
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-height: $lines * $line-height * 1em;
  line-height: $line-height;

  a {
    text-decoration: none;
    color: $color-tallow;

    &:hover {
      text-decoration: underline;
    }
  }

  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }
}

.title {
  font-family: inherit;
  font-weight: bold;
  font-size: rem(21px);
  line-height: rem(23px);
  margin-bottom: 5px;
}

.thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.tag-list {
  margin-bottom: $elements-margin;
}
