@use 'common/_shared.scss' as *;

$color-header-border-on-light: rgba(black, 0.3);
$color-header-border-on-dark: rgba(white, 0.3);

.root {
  position: relative;
  height: 100%;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  min-height: $header-height;
  border-bottom: 1px solid $color-header-border-on-light;
  background-color: white;

  @include media('>=desktop') {
    justify-content: flex-end;
    min-height: $header-height-desktop;
    background-color: $color-black-pearl;
  }
}

.burger {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: $header-height;
  padding: 11px;
  text-align: center;

  @include media('>=tablet') {
    display: none;
  }
}

.line-container {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  color: currentColor;
  vertical-align: top;
  margin-bottom: 6px;
}

.line {
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  background: currentColor;
  margin-top: -1px;
  top: 50%;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: currentColor;
    left: 0;
    transition: transform 0.15s;
  }

  &:before {
    top: 5px;
  }

  &:after {
    bottom: 5px;
  }
}

.burger-text {
  text-transform: uppercase;
  font-size: rem(8px);
  font-weight: $book;
  position: relative;
}

.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 50%;
  width: 157px;
  height: $header-height;
  background: no-repeat center/80% url('../../media/logo/logo-black.png?inline');
  transform: translateX(-50%);

  @include media('>=tablet') {
    width: 220px;
  }

  @include media('>=desktop') {
    width: 180px;
    height: $header-height-desktop;
    border-left: 1px solid $color-header-border-on-dark;
    border-right: 1px solid $color-header-border-on-dark;
    background: no-repeat center/68% url('../../media/logo/logo.png?inline')
      $color-black-pearl;
  }
}

.main-grid {
  display: flex;
  flex: 1 0 auto;
  height: 100vh;
  padding-top: $header-height;
  background-color: $color-snow;

  @include media('>=desktop') {
    padding-top: $header-height-desktop;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
