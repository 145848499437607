@use 'common/_shared.scss' as *;

.root {
  position: relative;
  padding-top: 20px;

  @include media('>desktop') {
    padding-top: 50px;
  }
}

.content-wrapper {
  position: relative;
  width: 100%;
  padding: 30px 20px 30px;
  background: white;
  @include white-box-shadow();
}

.title {
  pointer-events: none;
  padding: 0 0 10px 34px;

  @include media('>=tablet') {
    padding: 0 0 10px 60px;
  }

  @include media('>=desktop') {
    padding: 10px 50px 10px 112px;
  }
}

.birth-date-error {
  bottom: 8px;
}
