@use 'common/_shared.scss' as *;

.root {
  position: relative;
  padding-top: 20px;

  @include media('>desktop') {
    padding-top: 50px;
  }
}

.password-header {
  @include media('>=tablet') {
    display: flex;
  }
}

.ident-name {
  margin-bottom: 20px;
}

.ident-fields {
  display: flex;
  flex-wrap: wrap;
}

.password-placeholder {
  display: inline-block;
  border: 1px solid $color-whisper;
  border-radius: 5px;
  padding: 10px 15px;
  letter-spacing: 5px;
}

.message-wraper {
  margin-bottom: 40px;
}
