@use 'common/_shared.scss' as *;
@use 'common/_helpers.scss' as *;

.root {
  position: relative;
  height: 100%;
  padding-top: 20px;

  @include media('>desktop') {
    padding-top: 50px;
  }
}

.content-wrapper {
  position: relative;
  width: 100%;
  padding: 30px 20px 30px;
  background: white;
  @include white-box-shadow();
}

.title {
  pointer-events: none;
  padding: 3px 0 10px 34px;

  @include media('>=tablet') {
    padding: 3px 0 10px 60px;
  }

  @include media('>=desktop') {
    padding: 15px 50px 10px 112px;
  }
}

.form {
  @include media('>=desktop') {
    padding: 0 50px 42px 112px;
  }
}

.form-buttons {
  margin-top: 40px;
}

.multifield-item {
  margin-bottom: 0;
}
