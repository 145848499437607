@use 'common/_shared.scss' as *;

.root {
  position: relative;
  display: block;
  height: 80px;
  width: 80px;
  margin: 0 auto;

  path:nth-child(1) {
    fill: $color-whisper;
  }

  path:nth-child(n + 2) {
    fill: transparent;
  }
}
