@use 'sass:color';
@use 'common/_shared.scss' as *;

.root {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin: 0 10px 20px;
  text-decoration: none;
  font-size: rem(12px);
  font-weight: $bold;
  color: $color-tallow;
  transition: color 0.2s;

  @include hover() {
    color: color.adjust($color-tallow, $lightness: 15%);
  }

  &.disabled {
    color: rgba($color-tallow, 0.5);
    pointer-events: none;
  }
}

.data-cta-text {
  padding-top: 4px;
  padding-left: 30px;
  text-align: left;
}

.icon-download {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.loader {
  position: absolute;
  left: 0;
  margin: 0;
  background: $color-tallow;
  background: linear-gradient(
    to right,
    $color-tallow 10%,
    rgba($color-tallow, 0) 42%
  );

  &::before {
    background: $color-tallow;
  }

  &::after {
    background: white;
    transition: background-color 0.2s;
  }
}
