@use 'common/_shared.scss' as *;

.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background: $color-auth-white-background;
}

.form-wrapper {
  width: 100%;
  padding: 30px;
}

.button-link {
  display: block;
  margin: 20px auto 10px;
  padding: 5px;
  font-size: rem(14px);
  text-decoration: underline;
  color: $color-dove-grey;
}
