@use 'common/_shared.scss' as *;

.root {
  position: relative;
  margin-bottom: 30px;
  padding: 30px;

  @include media('>=desktop') {
    padding: 42px 50px;
  }
}
