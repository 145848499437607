@use 'common/_shared.scss' as *;

%Button {
  min-width: 280px;
  padding: 12px 20px;

  @include media('>=tablet') {
    width: auto;
    min-width: auto;
  }
}

.buttons-wrap {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  @include media('>=tablet') {
    flex-direction: row;
  }
}

.button-add {
  @extend %Button;
  margin: 0 0 20px 0;

  @include media('>=tablet') {
    margin: 0 30px 0 0;
  }

  @include media('>=desktop') {
    padding: 12px 40px;
  }
}

.button-subscribe {
  @extend %Button;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  background-color: $color-tallow;
  color: white;
  transition: background-color 0.2s;

  @include hover() {
    background-color: $color-yuma;
  }

  @include media('>=tablet') {
    padding: 13px 40px;
  }
}

.spacer {
  height: 1px;
  background-color: $color-narvik;
  margin: 21px -15px 0;
}

.add-mymcnumber {
  width: 100%;
}

.input {
  margin-top: 21px;
  margin-bottom: 15px;
}

.add-mymcnumber-hint {
  margin-bottom: 24px;
  @include main-sans-serif($light);
  font-size: rem(10px);
  line-height: (15 * 0.1);
  color: $color-zambezi;
  text-align: left;
}

.add-mymcnumber-buttons-wrap {
  display: flex;
  flex-wrap: wrap;

  @include media('>=tablet') {
    flex-wrap: nowrap;
    max-width: none;
  }
}

.button-cancel {
  width: 100%;
  margin-bottom: 10px;

  @include media('>=tablet') {
    width: auto;
    margin: 0 30px 0 0;
  }

  @include media('>=desktop') {
    padding: 12px 40px;
  }
}

.button-save {
  position: relative;
  width: 100%;
  flex: 1 0 auto;

  @include media('>=tablet') {
    width: auto;
  }
}

.loader {
  margin: 0px;
  position: absolute;
  left: 20px;
}

.transition-enter {
  opacity: 0.01;
  transform: translateY(-20%);
}

.transition-enter-active {
  opacity: 1;
  transform: translateY(0%);
  transition: all 200ms ease-in-out;
}

.transition-done-enter {
  opacity: 1;
}

.transition-exit {
  opacity: 1;
}

.transition-exit-active {
  opacity: 0.01;
  transform: translateY(-20%);
  transition: all 200ms ease-in-out;
}
