@use 'common/_shared.scss' as *;

.card-membre {
  color: $color-main-membre-card;
}

.card-silver {
  color: $color-main-silver-card;
}

.card-gold {
  color: $color-main-gold-card;
}

.card-platinum {
  color: $color-main-platinum-card;
}

.card-shadow-membre {
  color: $color-shadow-membre-card;
}

.card-shadow-silver {
  color: $color-shadow-silver-card;
}

.card-shadow-gold {
  color: $color-shadow-gold-card;
}

.card-shadow-platinum {
  color: $color-shadow-platinum-card;
}
