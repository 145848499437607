@use 'common/_shared.scss' as *;

.root {
  position: relative;
}

.content-wrapper {
  max-height: 75vh;
  overflow: auto;
  overflow-anchor: none;
}

.transition-enter {
  opacity: 0.01;
  max-height: 10vh;
}

.transition-enter-active {
  opacity: 0.5;
  max-height: 25vh;
  transition: max-height 200ms ease-in-out;
}

.transition-done-enter {
  opacity: 1;
  max-height: 75vh;
}

.transition-exit {
  opacity: 0.5;
  max-height: 25vh;
}

.transition-exit-active {
  opacity: 0.01;
  max-height: 1px;
  transition: max-height 200ms ease-in-out, opacity 200ms ease-in-out;
}
