@use 'common/_shared.scss' as *;

.root {
  display: flex;
  justify-content: center;
  position: relative;

  &:hover {
    .loader::after {
      background: $color-yuma;
    }
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 20px;
  margin-top: -0.5rem;
  background: linear-gradient(to right, white 10%, rgba(255, 255, 255, 0) 42%);

  &::before {
    background: white;
  }

  &::after {
    background: $color-tallow;
  }
}
