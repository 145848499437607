@use 'common/_shared.scss' as *;

.root {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto 20px;
  padding: 18px 30px 18px 72px;
  font-weight: $medium;
  text-align: left;
  font-size: 12px;

  .loading {
    color: $color-mist-grey;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 30px;
  margin-top: -0.5rem;
}

.icon-cta {
  display: flex;
  width: 27px;
  height: 9px;
  margin-left: auto;
}
