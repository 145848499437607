@use 'common/_shared.scss' as *;

.user-card {
  .card-title {
    margin-bottom: 20px;
  }
}

.message-root {
  padding: 0;
  background: none;
  box-shadow: none;
  color: inherit;

  p {
    text-align: center;

    @include media('>=tablet') {
      text-align: left;
    }
  }

  p,
  a {
    font-size: rem(18px);
  }
}
