@use 'common/_shared.scss' as *;

.root {
  margin-top: 20px;
  border: 2px solid $color-tallow;
  padding: 25px 20px 30px;
  background-color: white;

  strong {
    @include main-sans-serif($bold);
  }

  @include media('>=tablet') {
    padding: 30px;
  }
}

.title {
  margin-bottom: 35px;
  text-align: center;
  @include main-sans-serif();
  font-size: rem(24px);
  line-height: rem(36px);

  strong {
    color: $color-tallow;
  }

  @include media('>=tablet') {
    font-size: rem(26px);
    line-height: rem(38px);
  }
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: -35px -10px 0;

  @include media('>=desktop') {
    margin: -10px;
  }
}

.item {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  padding: 35px 10px 0;

  @include media('>=desktop') {
    padding: 10px;
    width: 25%;
  }
}

.icon-wrap {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50px;
  margin-bottom: 10px;
}

.icon {
  fill: $color-tallow;
  height: auto;
}

.icon-star {
  width: 44px;
  height: 44px;
}

.icon-dumbbell {
  width: 51px;
  height: 38px;
}

.icon-wifi {
  width: 47px;
  height: 36px;
}

.icon-card {
  width: 27px;
  height: 36px;
}

.label {
  font-size: rem(14px);
  line-height: rem(20px);
  display: inline-block;
  vertical-align: top;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
