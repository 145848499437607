@use 'common/_shared.scss' as *;

.root {
  margin-top: 25px;
  margin-bottom: 25px;

  @include media('>=desktop') {
    margin-top: 25px;
    margin-bottom: 25px;
    background-color: transparent;
  }
}

.content-wrapper {
  position: relative;
  width: 100%;
  @include white-box-shadow();
  border-radius: $card-border-radius;
  overflow: hidden;
  text-decoration: none;
  display: inline-block;
  color: inherit;

  @include media('>=tablet') {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
}

.button {
  height: auto;
}

.image-content,
.content {
  @include media('>=tablet') {
    width: 50%;
  }
}

.link {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: $card-padding-v;
  line-height: $button-height;
  min-width: max-content;
  max-width: 100%;
  z-index: 3;

  background: white;
  border-radius: 5px;
  padding: 0 24px;
  color: black;
  font-size: rem(14px);
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;

  @include media('>=tablet') {
    left: $card-padding-h;
    transform: translateX(0);
    padding: 0 28px;
  }

  &.reverse {
    background: #aba177;
    color: white;
  }
}

.content {
  margin: $card-padding-v $card-padding-h;
  margin-bottom: $card-padding-v;
  padding-bottom: $button-height;
  text-align: left;

  @include media('<tablet') {
    text-align: center;
    margin: $mobile-card-padding-v $mobile-card-padding-h;
    margin-bottom: $mobile-card-padding-v;
  }

  h3 {
    font-size: rem(19.5px);
    font-family: inherit;
    font-weight: 600;
    text-align: center;

    @include media('>=tablet') {
      font-size: rem(28px);
      text-align: left;
    }
  }

  p {
    max-width: 100%;
    font-size: rem(10px);
    line-height: 1.75;
    margin-bottom: 30px;
    color: $color-zambezi;

    @include media('>=tablet') {
      font-size: rem(13px);
    }
  }
}
