@use 'common/_shared.scss' as *;

.offers-wrapper {
  @include main-sans-serif();
  margin-bottom: 40px;
}

.show-more-wrap {
  text-align: center;
  margin-bottom: 30px;
}
