@use 'sass:color';
@use 'common/_shared.scss' as *;

.root {
  position: relative;
  padding: 20px 0 0;
}

.content-wrapper {
  position: relative;
  width: 100%;
  padding: 35px 0;
  background: white;
  @include white-box-shadow();

  @include media('>=tablet') {
    padding: 42px 0;
    margin: 0;
  }
}

.form-wrapper {
  @include media('>=tablet') {
  }
}

.title {
  pointer-events: none;
  margin-bottom: 20px;
  padding: 0 20px;

  @include media('>=tablet') {
    padding: 0 50px;
  }

  @include media('>=desktop') {
    padding: 0 20px 0 50px;
  }
}

.text {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 20px;
  font-size: rem(14px);

  @include media('>=tablet') {
    padding: 0 50px;
  }

  @include media('>=desktop') {
    padding: 0 20px 0 50px;
  }
}

.method-source {
  font-weight: 700;
}

.field-wrap {
  padding: 0 20px 20px;

  @include media('>=tablet') {
    padding: 0 50px 20px;
  }

  @include media('>=desktop') {
    padding: 0 50px 30px;
  }
}

.legend {
  margin-bottom: 10px;
  font-size: rem(12px);
}

.radio {
  margin-bottom: 10px;
}

.cta {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 20px;

  @include media('>=tablet') {
    flex-wrap: nowrap;
    padding: 0 50px;
  }
}

.cta-button {
  position: relative;
  font-size: rem(14px);
  width: 100%;
  padding: 17px 40px;

  @include media('>=tablet') {
    flex-wrap: nowrap;
    width: auto;
  }
}

.button-cancel {
  width: 100%;
  margin-bottom: 10px;
  font-size: rem(14px);

  @include media('>=tablet') {
    width: auto;
    margin: 0 30px 0 0;
  }

  @include media('>=desktop') {
    padding: 12px 40px;
  }
}

.loader {
  margin: 0px;
  position: absolute;
  left: 20px;
}

.code-description {
  margin-bottom: 20px;
  padding: 0 20px;
  font-size: rem(14px);

  @include media('>=tablet') {
    padding: 0 50px;
  }

  @include media('>=desktop') {
    padding: 0 20px 0 50px;
  }
}

.resend-btn {
  font-size: rem(11px);
  transition: color 0.2s;

  @include hover() {
    color: color.adjust($color-tallow, $lightness: 15%);
  }
}

.resend-text {
  line-height: rem(16px);
  margin-right: 10px;
}

.resend-loader {
  margin: 0;
}

.change-method {
  margin-bottom: 20px;
  padding: 0 20px;
  font-size: rem(14px);

  @include media('>=tablet') {
    padding: 0 50px;
  }

  @include media('>=desktop') {
    padding: 0 20px 0 50px;
  }
}

.change-method-btn {
  color: $color-tallow;
  transition: color 0.2s;

  @include hover() {
    color: color.adjust($color-tallow, $lightness: 15%);
  }
}

.icon-back {
  display: inline-block;
  height: 19px;
  width: 19px;
  margin-right: 15px;
}
