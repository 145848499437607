@use 'sass:math';

@use 'common/_shared.scss' as *;

.root {
  position: relative;
  background-color: white;
  margin-top: 25px;
  margin-bottom: 25px;

  @include media('<tablet') {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.content-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 390px;
  @include white-box-shadow();
  border-radius: $card-border-radius;
  padding: $card-padding-v $card-padding-h;
  padding-bottom: $card-padding-v;
  height: 100%;
  display: inline-block;
  text-decoration: none;
  text-decoration: none;

  color: inherit;

  @include media('<desktop') {
    min-height: 254px;
  }

  @include media('<tablet') {
    min-height: 490px;
    padding: $mobile-card-padding-v $mobile-card-padding-h;
    padding-bottom: $mobile-card-padding-v;
  }

  img {
    @include object-fit-polyfill();
  }
}

.link {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: $card-padding-v;
  line-height: $button-height;
  min-width: max-content;
  max-width: 100%;
  z-index: 3;

  background: white;
  border-radius: 5px;
  padding: 0 30px;
  color: black;
  font-size: rem(14px);
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;

  @include media('>=tablet') {
    left: $card-padding-h;
    transform: translateX(0);
    padding: 0 35px;
  }

  &.reverse {
    background: #aba177;
    color: white;
  }
}

.content-inside {
  position: relative;
  max-width: 100%;
  width: 535px;
  color: white;
  height: 100%;
  padding-bottom: $button-height;
}

.title {
  font-size: rem(19.5px);
  font-family: inherit;
  font-weight: 600;
  text-align: center;

  @include media('>=tablet') {
    font-size: rem(28px);
    text-align: left;
  }
}

.description {
  margin-top: 30px;
  font-size: rem(13px);
  line-height: math.div(19, 11);

  @include media('<tablet') {
    font-size: rem(12px);
    margin-top: 65px;
  }
}
