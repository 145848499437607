@use 'common/_shared' as *;

.root {
  padding-top: 20px;

  @include media('>desktop') {
    padding-top: 50px;
  }
}

.container {
  height: 100%;
}

.field {
  padding: 5px;
}

.tabs-list {
  display: flex;
  margin: 0 -5px;
}

.tab {
  width: 50%;
  margin: 0 5px;
  padding: 15px 30px;
  cursor: pointer;
  @include white-box-shadow();
  font-size: rem(12px);
  text-transform: uppercase;
  @include main-sans-serif($bold);
  position: relative;
  background-color: $color-narvik;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    background-color: $color-narvik;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
}

.tab-label {
  position: relative;
  z-index: 10;
  text-align: center;
}

.active-tab {
  background-color: white;

  &::before {
    background-color: white;
    z-index: 5;
  }
}

.active-tab-panel {
  background: white;
}
