@use 'common/_shared.scss' as *;

.loader-holder {
  height: 50px;
  position: relative;
  border: 1px solid $color-narvik;
}

.loader {
  position: absolute;
  top: 50%;
  right: calc(50% - 0.25rem);
  margin-top: -0.5rem;
}
