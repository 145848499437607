@use 'common/_shared.scss' as *;

.root {
  width: 100%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  fill: currentColor;

  svg {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
}
