.root {
  transition: opacity 150ms;
  opacity: 0;
}
.enter {
  opacity: 0;
}
.enter-active {
  opacity: 0;
}
.enter-done {
  opacity: 1;
}
