@use 'common/_shared.scss' as *;

@use 'common/_wysiwyg.scss' as *;

$border-color: $color-narvik;

.root {
  width: 100%;
  padding: 20px;
  background: white;

  @include media('>=tablet') {
    padding: 30px;
  }
}

.content {
  @include media('>=tablet') {
    border: 1px solid $border-color;
  }
}

.text-container {
  padding: 20px;
}

.title {
  position: relative;
  margin-bottom: 32px;
  padding: 20px 0 25px;
  @include main-serif();
  font-size: rem(29px);
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(50% - 13px);
    display: block;
    width: 26px;
    height: 3px;
    background-color: $color-black-pearl;
  }

  @include media('>=tablet') {
    margin-bottom: 28px;
    padding: 0 0 25px;
    font-size: rem(34px);
  }

  @include media('>=desktop') {
    font-size: rem(38px);
  }
}

.text {
  padding: 20px 30px;
  @include wysiwyg-myacc();
}

.image {
  display: flex;
  justify-content: center;
  padding: 50px 20px 30px;
}

.icon-gear {
  fill: $color-tallow;
}

.gear-1 {
  width: 32px;
  height: 32px;
  animation: spin-1 4s infinite linear forwards;
}

.gear-2 {
  width: 64px;
  height: 64px;
  animation: spin-2 4s infinite linear forwards;
}

.gear-3 {
  width: 48px;
  height: 48px;
  animation: spin-3 4s infinite linear forwards;
}

@keyframes spin-1 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-2 {
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes spin-3 {
  100% {
    transform: rotate(360deg);
  }
}
