@use 'common/_shared.scss' as *;

@mixin separator {
  content: '';
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  width: 25px;
  height: 3px;
  background-color: $color-zambezi;

  @include media('>=tablet') {
    width: 22px;
    height: 2px;
  }
}

.root {
  position: relative;
  @include color-schema(review-text-color) using ($color) {
    color: $color;
  }
}

.title {
  display: block;
  margin-bottom: 35px;
  font-size: rem(25px);
  line-height: 1.5;
  @include featured-serif();

  @include theme-mice {
    font-size: $intro-title-mob;
    font-family: $mice-secondary-sans-serif;
    font-weight: $bold;

    @include media('>=tablet') {
      font-size: $intro-title-desc;
    }
  }

  @include theme-celeb {
    @include main-serif($bold);
  }

  @include media('>=tablet') {
    margin-bottom: 40px;
    font-size: rem(32px);
  }

  &.with-additional-info {
    margin-bottom: 10px;
  }
}

.title-break {
  :global {
    .is-highlighted {
      display: block;
    }
  }
}

.period {
  margin-bottom: 35px;
  font-size: rem(14px);

  @include media('>=tablet') {
    margin-bottom: 40px;
    font-size: rem(16px);
  }
}

.attributes {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;

  .attributes-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include theme-sbm {
      @include media('>=tablet') {
        justify-content: flex-start;
      }
    }
  }

  .list-item {
    display: inline-block;
    width: 33%;
    margin: 0 15px 15px;
    text-align: center;
    text-transform: uppercase;

    @include media('>=tablet') {
      width: 100px;
      margin: 0 15px 15px 0;
    }
  }
}

.description {
  font-size: rem(12px);
  line-height: 1.75;

  @include media('>=tablet') {
    font-size: rem(15px);
  }
}

.attributes-separated,
.bottom-separated {
  padding-bottom: 25px;
  margin-bottom: 25px;

  &::after {
    @include separator();
  }

  @include media('>=tablet') {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}
