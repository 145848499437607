@use 'common/_shared.scss' as *;

.root {
  position: relative;
  background: white;
  @include white-box-shadow();
}

.header {
  display: flex;
  justify-content: flex-start;
}

.header-content {
  flex: 1;

  @include media('>=tablet') {
    flex: auto;
  }

  @include media('>=desktop') {
    display: flex;
  }
}

.title {
  position: relative;
  margin-bottom: 20px;
  padding-right: 20px;
  @include main-serif();
  font-size: rem(26px);
  line-height: 1;
}

.link {
  margin-left: auto;
  text-decoration: none;
  text-transform: uppercase;
  font-size: rem(12px);
  line-height: 1.5;
  color: black;
  transition: color 0.1s ease;

  &:hover {
    color: $color-tallow;
  }
}

.content {
  flex-grow: 1;
}

.icon {
  height: rem(16px);
  width: rem(16px);
  margin-right: 8px;
}
