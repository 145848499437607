@use 'common/_shared.scss' as *;

.root {
  position: relative;
  @include white-box-shadow();
  background: white;

  // .block-title top margin fix
  &::before {
    content: '';
    display: block;
    height: 1px;
  }
}

.block-title {
  margin: 20px 30px 5px;

  @include media('>=desktop') {
    margin: 42px 50px 30px;
  }
}

.title {
  margin-bottom: 0;
}

.table {
  width: 100%;
  margin-bottom: 20px;
}

.member-row {
  border-bottom: 1px solid $color-narvik;

  &:last-child {
    border-bottom: none;
  }
}

.member-row-dimmed {
  opacity: 0.2;
}

%MemberCell {
  font-size: rem(14px);
  font-weight: $medium;
  letter-spacing: rem(0.5px);
}

.member-cell {
  padding: 16px 24px 19px;
  @extend %MemberCell;
  font-weight: $bold;
  vertical-align: middle;
}

.member-cell-mobile {
  display: flex;
  align-items: center;
  padding: 20px 15px;
  @extend %MemberCell;
}

.member-cell-card {
  width: 7%;
  padding: 12px 19px;
  vertical-align: middle;

  img {
    display: block;
    width: 26px;
    height: auto;
  }
}

.member-cell-card-mobile {
  margin-right: 20px;
}

.member-cell-label,
.label {
  font-size: rem(10px);
  color: $color-suva-grey;
  font-weight: $book;
}

.text-line-mobile {
  margin-bottom: 8px;
  font-weight: $bold;

  &:last-child {
    margin-bottom: 0;
  }
}

.label-mobile {
  margin-right: 2px;
  font-weight: $book;
}
