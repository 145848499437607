@use 'common/_shared.scss' as *;

.root {
  margin-top: 15px;
  font-size: rem(13px);
  text-transform: uppercase;

  &.short-style.only-attributes {
    margin: 0;
  }

  @include media('<desktop') {
    text-align: center;
    margin-top: 15px;

    &.only-attributes {
      margin: 0;
    }
  }
}

.parent-title {
  color: $color-tallow;
}

.parent-stars {
  margin-top: -1px;
  margin-left: 5px;
}

.parent-stars-michelin {
  margin-left: 5px;
  padding-top: 0.15rem;
}

.attribute-text {
  padding-right: 21px;
  vertical-align: middle;
  line-height: 1;

  .colored-attributes & {
    color: $color-tallow;

    &::after {
      color: $color-dove-grey;
    }
  }

  &::after {
    content: '';
    position: relative;
    display: inline-block;
    vertical-align: middle;
    background-color: currentColor;
    width: 1px;
    right: -10px;
    height: 0.7em;
  }
}

.attribute-item {
  position: relative;
  display: inline-block;
  color: inherit;
  vertical-align: middle;

  &:last-child {
    .attribute-text {
      padding-right: 0;

      &::after {
        display: none;
      }
    }
  }
}

.parent-property-wrapper {
  margin-top: 15px;
}
