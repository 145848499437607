@use 'common/_shared.scss' as *;

.root {
  position: relative;
  margin: 15px 0 20px;
  font-size: rem(11px);

  select::-ms-expand {
    display: none;
  }
}

.label {
  position: absolute;
  left: 0px;
  top: -5px;
  pointer-events: none;
  text-transform: uppercase;
  font-size: rem(10px);
  @include main-sans-serif($bold);
  color: $color-grey;
}

.select {
  display: block;
  width: 100%;
  height: rem(31px);
  margin: 15px 0 20px;
  border-width: 0 0 1px 0;
  border-color: $color-narvik;
  border-radius: 0;
  padding: 15px 14px 0 0;
  background-color: transparent;
  font-weight: $bold;
  text-transform: uppercase;
  color: $color-grey;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: rem(12px);
  height: rem(31px);
  @include main-sans-serif($bold);
  cursor: pointer;

  &[disabled] {
    color: $color-matterhorn;
  }

  option[disabled] {
    display: none;
  }
}

.touched {
  color: $color-black-pearl;
}

.arrow {
  display: block;
  position: absolute;
  right: 4px;
  top: calc(50% - 2px);
  width: 6px;
  height: 6px;
  border: 3px solid;
  border-color: transparent $color-black-pearl $color-black-pearl transparent;
  transform: rotate(45deg);
  pointer-events: none;
}

.arrow-disabled {
  border-color: transparent $color-dark-gray $color-dark-gray transparent;
}

.block-theme {
  background: $block-theme-input-background;
  padding-right: 0;

  .select {
    border: 1px solid $block-theme-input-border;
    border-radius: 10px;
    margin: 0;
    padding: $block-theme-input-padding;
    padding-left: 20px;
    height: auto;
    font-size: rem(11px);
  }

  .touched {
    font-size: rem(14px);
  }

  .label {
    top: $block-theme-input-label-active-indent;
    left: $block-theme-input-label-active-indent;
    transform: translate(0, 0);
  }

  .arrow {
    top: calc(50% - 4px);
    right: 10px;
  }
}

.select-error {
  color: $color-carissma;
}
