@use 'sass:math';

@use 'common/_shared.scss' as *;

.root {
  margin-bottom: 30px;
}

.title {
  @include main-serif($bold);
  font-size: rem(35px);
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;

  @include media('>=tablet') {
    font-size: rem(30px);
  }
}

.subtitle {
  font-size: rem(12px);
}
