@use 'common/_shared.scss' as *;

.root {
  position: relative;
  padding-top: 20px;

  @include media('>desktop') {
    padding-top: 50px;
  }
}

.title {
  position: relative;
  margin-bottom: 30px;
  padding-right: 20px;
  @include main-serif();
  font-size: rem(24px);
  line-height: 1;

  @include media('>=tablet') {
    font-size: rem(26px);
  }
}

.data-buttons-container {
  padding-top: 0;
  padding-bottom: 0;
}
